import React from 'react';
import { connect } from 'react-redux';
import qs from 'query-string';
import { navigationActions } from '../../../actions/navigationActions';
import { siteIDActions } from '../../../actions/siteIDActions';
import HeaderPanel from './shared/HeaderPanel';
import MapPanel from './maps/MapPanel';
import FilterPanel from './list/FilterPanel';
import Table from './list/Table';
import Flags from './detail/Flags';
import Fields from './detail/Fields';
import Comments from './detail/Comments';
import TableDetail from './detail/TableDetail';
import { specialActions } from '../../../actions/specialActions';
import SaveBulkCommentModal from './modals/SaveBulkCommentModal';
import SaveBulkStatusModal from './modals/SaveBulkStatusModal';
import SaveDocumentModal from './modals/SaveDocumentModal';

class SiteIDApplications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageDetail: 1,
      filter: '',
      sortOrder: 'created_desc',
      mode: '',
      statusIds: [],
      countyIds: [],
      companyIds: [],
      hasFlags: undefined,
      expiringSoon: undefined,
      inactiveDaysBack: undefined,
      displayEquity: true,
      displayPriorityAreas: false,
      displayCompanyTerritory: true,
      driveThrough: true,
      displayOpen: true,
      selectedRecords: [],
      selectedRecordsDetail: [],
      radius: 2,
      applicationId: undefined,
      newAddress: undefined,

      filterString: '',
      goToApplicationId: '',

      fullScreenMode: false,

      editModalExpanded: false,
      bulkCommentModalExpanded: false,
      bulkProgressModalExpanded: false,
      showDocumentModal: false,

      singleLicenseeMode: false,

      testFit: '',
      plannedSchematic: 0,
    };

    this.doPaging = this.doPaging.bind(this);
    this.doPagingDetail = this.doPagingDetail.bind(this);
    this.doSearch = this.doSearch.bind(this);
    this.doFilter = this.doFilter.bind(this);
    this.doSort = this.doSort.bind(this);
    this.doToggle = this.doToggle.bind(this);
    this.doToggleMulti = this.doToggleMulti.bind(this);
    this.doFullScreenMode = this.doFullScreenMode.bind(this);
    this.clearFilter = this.clearFilter.bind(this);

    this.clearToggleApplication = this.clearToggleApplication.bind(this);
    this.toggleApplication = this.toggleApplication.bind(this);
    this.toggleApplicationDetail = this.toggleApplicationDetail.bind(this);

    this.navigateItemDetails = this.navigateItemDetails.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    this.handleFieldUpdate = this.handleFieldUpdate.bind(this);

    this.handleModalToggle = this.handleModalToggle.bind(this);

    this.handlePageChange = this.handlePageChange.bind(this);

    this.handleUpdateRadius = this.handleUpdateRadius.bind(this);
    this.handleLocationSet = this.handleLocationSet.bind(this);
    this.handleMapPan = this.handleMapPan.bind(this);
    this.handleSaveBulkRelate = this.handleSaveBulkRelate.bind(this);
    this.handleSaveBulkUnrelate = this.handleSaveBulkUnrelate.bind(this);
    this.saveComment = this.saveComment.bind(this);
    this.handleSaveBulkComment = this.handleSaveBulkComment.bind(this);
    this.deleteComment = this.deleteComment.bind(this);
    this.downloadDocument = this.downloadDocument.bind(this);

    this.saveDocument = this.saveDocument.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);

    this.handleSave = this.handleSave.bind(this);
    this.handleReloadRecord = this.handleReloadRecord.bind(this);
    this.handleSaveStatus = this.handleSaveStatus.bind(this);
    this.handleBulkSaveStatus = this.handleBulkSaveStatus.bind(this);
    this.handleSaveExtension = this.handleSaveExtension.bind(this);
  }

  componentDidMount() {
    const {
      match,
      getApplications,
      getInactiveApplications,
      getEquityStores,
      getOpenStores,
      getDriveThroughPlan,
      getApplicationDetails,
    } = this.props;

    const { applicationId } = match.params;

    const mode = applicationId ? 'detail' : 'list';

    let newState = { mode };

    if (mode === 'list') {
      newState = { ...newState, ...this.initialiseListState() };

      getApplications();

      if (newState.inactiveDaysBack) {
        getInactiveApplications(newState.inactiveDaysBack);
      }

      if (newState.displayEquity) {
        getEquityStores();
      }

      if (newState.displayOpen) {
        getOpenStores();
      }

      if (newState.driveThrough) {
        getDriveThroughPlan();
      }
    } else {
      newState = {
        ...newState,
        ...this.initialiseListState(),
        applicationId: parseInt(applicationId, 10),
      };

      getApplicationDetails(applicationId);
    }

    this.setState(newState);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match,
      getApplications,
      getInactiveApplications,
      getEquityStores,
      getOpenStores,
      getDriveThroughPlan,
      getApplicationDetails,
      applicationDetail,
      isBulkChanged,
      isChanged,
      location,
      replace,
      statuses,
      companies,
      counties,
    } = this.props;

    const {
      statusIds,
      companyIds,
      countyIds,
    } = this.state;

    const { applicationId } = match.params;
    const mode = applicationId ? 'detail' : 'list';

    let stateChanged = false;
    let pageChanged = false;
    let newState = { ...this.state, mode };

    if (
      applicationDetail
      && applicationDetail.applicationId
      && prevProps.applicationDetail.applicationId
      !== applicationDetail.applicationId
      && applicationId !== applicationDetail.applicationId.toString()
    ) {
      const internalApplicationId = applicationDetail.applicationId.toString();

      stateChanged = true;
      newState = {
        ...newState,
        mode: 'detail',
        applicationId: internalApplicationId,
        pageDetail: 1,
      };

      replace(
        `${location.pathname.replace(
          `s/${applicationId}`,
          `s/${internalApplicationId}`,
        )}`,
      );
    } else if (prevProps.match.params.applicationId !== applicationId) {

      if (mode === 'list') {
        stateChanged = true;
        newState = {
          ...newState,
          ...this.initialiseListState(),
          newAddress: undefined,
          center: undefined,
          radius: 2,
        };

        getApplications();

        if (newState.inactiveDaysBack
            && newState.inactiveDaysBack !== prevState.inactiveDaysBack) {
          getInactiveApplications(newState.inactiveDaysBack);
        }

        if (newState.displayEquity
            && !prevState.displayEquity) {
          getEquityStores();
        }

        if (newState.displayOpen
            && !prevState.displayOpen) {
          getOpenStores();
        }

        if (newState.driveThrough
            && !prevState.driveThrough) {
          getDriveThroughPlan();
        }
      } else {
        stateChanged = true;
        newState = {
          ...newState,
          applicationId: parseInt(applicationId, 10),
          pageDetail: 1,
        };

        getApplicationDetails(applicationId);
      }
    } else if (isBulkChanged && !prevProps.isBulkChanged) {
      stateChanged = true;
      newState = {
        ...newState,
        selectedRecords: [],
        selectedRecordsDetail: [],
      };

      getApplicationDetails(applicationId);

    } else if (isChanged && !prevProps.isChanged) {
      stateChanged = true;
      newState = {
        ...newState,
        showDocumentModal: false,
      };
    }

    if (prevProps.statuses.length !== statuses.length
        && statusIds.length === prevProps.statuses.length
    ) {
      stateChanged = true;

      newState = {
        ...newState,
        statusIds: statuses.map((s) => s.key),
      };

      pageChanged = true;
    }

    if (prevProps.companies.length !== companies.length
        && companyIds.length === prevProps.companies.length
    ) {
      stateChanged = true;

      newState = {
        ...newState,
        companyIds: companies.map((s) => s.key),
      };

      pageChanged = true;
    }

    if (prevProps.counties.length !== counties.length
        && countyIds.length === prevProps.counties.length
    ) {
      stateChanged = true;

      newState = {
        ...newState,
        countyIds: counties.map((s) => s.key),
      };

      pageChanged = true;
    }

    if(stateChanged){
      this.setState(newState);
      if(pageChanged){
        this.handlePageChange({
          ...this.state,
          ...newState,
          page: 1,
        });
      }
    }
  }

  handleFieldUpdate(e, fieldName) {
    this.setState({
      [fieldName]: e.target.value,
    });
    e.preventDefault();
  }

  handleUpdateRadius(e) {
    this.setState({
      radius: parseInt(e.target.value, 10) / 1609,
    });
  }

  handleLocationSet(latitude, longitude, address) {
    const { updateMap } = this.props;
    const { applicationId } = this.state;

    updateMap(latitude, longitude, applicationId || '');

    address.geometry.location = new window.google.maps.LatLng(latitude, longitude);

    this.setState({ newAddress: address, center: { latitude, longitude } });
  }

  handleMapPan(latitude, longitude) {
    this.setState({ center: { latitude, longitude } });
  }

  handleModalToggle(modalStatusName, show, modalStatus) {
    const {
      startBulkComment,
      startBulkStatus,
    } = this.props;

    if (show && modalStatusName === 'bulkCommentModalExpanded') {
      startBulkComment();
    }

    if (show && modalStatusName === 'bulkProgressModalExpanded') {
      startBulkStatus();
    }

    if (show) {
      this.setState({ [modalStatusName]: show, bulkModalStatus: modalStatus });
    } else {
      this.setState({
        [modalStatusName]: show,
      });
    }
  }

  handleSaveBulkRelate(currentApplicationId, selectedRecords){
    const {
      saveBulkRelate,
    } = this.props;
    saveBulkRelate(  
      selectedRecords.map((s) => ({ currentApplicationId: currentApplicationId , applicationId: s }) ),
    );
  }

  handleSaveBulkUnrelate(currentApplicationId, selectedRecords){
    const {
      saveBulkUnrelate,
    } = this.props;
    saveBulkUnrelate(  
      selectedRecords.map((s) => ({ currentApplicationId: currentApplicationId , applicationId: s }) ),
    );
  }

  handlePageChange(currentState) {
    const {
      replace,
      getInactiveApplications,
      getEquityStores,
      getOpenStores,
      getDriveThroughPlan,
      location,
    } = this.props;

    const {
      inactiveDaysBack: currentInactiveDaysBack,
      displayOpen: currentDisplayOpen,
      displayEquity: currentDisplayEquity,
      driveThrough: currentDriveThrough,
    } = this.state;

    const {
      pathname,
    } = location;

    const {
      filter,
      page,
      statusIds,
      countyIds,
      companyIds,
      hasFlags,
      expiringSoon,
      inactiveDaysBack,
      displayEquity,
      displayCompanyTerritory,
      displayPriorityAreas,
      driveThrough,
      displayOpen,
      sortOrder,
      testFit,
      plannedSchematic,
    } = currentState;

    const filterString = `filter=${filter}&statusIds=${statusIds}&countyIds=${countyIds}`
      + `&companyIds=${companyIds}&hasFlags=${hasFlags}&expiringSoon=${expiringSoon}`
      + `&inactiveDaysBack=${inactiveDaysBack}&displayOpen=${displayOpen}&displayEquity=${displayEquity}`
      + `&displayCompanyTerritory=${displayCompanyTerritory}&displayPriorityAreas=${displayPriorityAreas}&driveThrough=${driveThrough}`
      + `&sortOrder=${sortOrder}`+ `&testFit=${testFit}&plannedSchematic=${plannedSchematic}`;
      
    replace(`${pathname}?page=${page}&${filterString}`);

    if (inactiveDaysBack !== currentInactiveDaysBack) {
      getInactiveApplications(inactiveDaysBack);
    }

    if (displayOpen && !currentDisplayOpen) {
      getOpenStores();
    }

    if (displayEquity && !currentDisplayEquity) {
      getEquityStores();
    }

    if (driveThrough && !currentDriveThrough) {
      getDriveThroughPlan();
    }

    this.setState({ page, filterString });
  }

  handleSaveBulkComment(comment, selectedRecords) {
    const { saveBulkComment } = this.props;

    saveBulkComment(
      selectedRecords.map((s) => ({ ...comment, applicationId: s })),
    );
  }

  handleSave(application) {
    const { saveApplicationDetails } = this.props;
    const { applicationId } = this.state;

    saveApplicationDetails({ ...application, applicationId });
  }

  handleReloadRecord() {
    const { getApplicationDetails } = this.props;
    const { applicationId } = this.state;

    getApplicationDetails(applicationId);
  }

  handleSaveStatus(status) {
    const { saveStatus } = this.props;
    const { applicationId } = this.state;

    saveStatus({ ...status, applicationId });
  }

  handleBulkSaveStatus(status, selectedRecords, records, nextStatus) {
    const { saveBulkStatus } = this.props;

    saveBulkStatus(
      selectedRecords
        .map((s) => {
          const app = records.find((r) => r.applicationId === s);

          if (app) {
            return {
              ...status,
              applicationId: s,
              fromStatusId: app.statusId,
              toStatusId:
                nextStatus || (app.statusId < 8 ? app.statusId + 1 : -1),
            };
          }
          return null;
        })
        .filter((s) => s !== null),
    );
  }

  handleSaveExtension(extension) {
    const { saveExtension } = this.props;
    const { applicationId } = this.state;

    saveExtension({ ...extension, applicationId });
  }

  deleteComment(commentId) {
    const { deleteComment } = this.props;
    const { applicationId } = this.state;

    deleteComment(applicationId, commentId);
  }

  downloadDocument(documentId) {
    const { downloadFile } = this.props;
    const { applicationId } = this.state;

    downloadFile(
      `/api/siteid/document/v1/download/${applicationId}/${documentId}`,
    );
  }

  saveDocument(document) {
    const { saveDocument } = this.props;
    const { applicationId } = this.state;

    saveDocument({ ...document, applicationId });
  }

  deleteDocument(documentId) {
    const { deleteDocument } = this.props;
    const { applicationId } = this.state;

    deleteDocument(applicationId, documentId);
  }

  saveComment(comment) {
    const { saveComment } = this.props;
    const { applicationId } = this.state;

    saveComment({ ...comment, applicationId });
  }

  doPaging(page) {
    this.setState({ page });

    this.handlePageChange({ ...this.state, page });
  }

  doPagingDetail(pageDetail) {
    this.setState({ pageDetail });

    this.handlePageChange({ ...this.state, pageDetail });
  }

  doSearch() {
    this.setState({ page: 1 });

    this.handlePageChange({ ...this.state, page: 1 });
  }

  clearToggleApplication() {
    this.setState({ selectedRecords: [] });
  }

  toggleApplication(applicationId, newState) {
    const { selectedRecords } = this.state;

    if (newState === undefined) {
      if (selectedRecords.indexOf(applicationId) > -1) {
        selectedRecords.splice(selectedRecords.indexOf(applicationId), 1);
      } else {
        selectedRecords.push(applicationId);
      }
    } else {
      const exists = selectedRecords.indexOf(applicationId) > -1;

      if (exists && newState === false) {
        selectedRecords.splice(selectedRecords.indexOf(applicationId), 1);
      }

      if (!exists && newState === true) {
        selectedRecords.push(applicationId);
      }
    }

    this.setState({ selectedRecords });
  }

  toggleApplicationDetail(applicationId) {
    const { selectedRecordsDetail } = this.state;

    if (selectedRecordsDetail.indexOf(applicationId) > -1) {
      selectedRecordsDetail.splice(
        selectedRecordsDetail.indexOf(applicationId),
        1,
      );
    } else {
      selectedRecordsDetail.push(applicationId);
    }

    this.setState({ selectedRecordsDetail });
  }

  doFilter(e, fieldName) {
    this.setState({
      [fieldName]:
        fieldName === 'filter' || Number.isNaN(parseInt(e.target.value, 10))
          ? e.target.value
          : parseInt(e.target.value, 10),
    });
    e.preventDefault();

    this.handlePageChange({
      ...this.state,
      [fieldName]: e.target.value,
      page: 1,
    });
  }

  doSort(e) {
    this.setState({ sortOrder: e.target.value });

    e.preventDefault();

    this.handlePageChange({
      ...this.state,
      sortOrder: e.target.value,
      page: 1,
    });
  }

  doToggle(e, fieldName) {
    this.setState({
      [fieldName]: e.target.value !== 'false',
    });

    e.preventDefault();

    this.handlePageChange({
      ...this.state,
      [fieldName]: e.target.value !== 'false',
      page: 1,
    });
  }

  doToggleMulti(selectedValues, fieldName) {
    this.setState({
      [fieldName]: selectedValues,
    });
    this.handlePageChange({
      ...this.state,
      [fieldName]: selectedValues,
      page: 1,
    });
  }

  doFullScreenMode(enabled) {
    this.setState({ fullScreenMode: enabled });
  }

  clearFilter(e) {
    const {
      statuses,
      companies,
      counties,
    } = this.props;

    this.setState({
      page: 1,
      filter: '',
      sortOrder: 'created_desc',
      statusIds: statuses.map((s) => s.key),
      countyIds: counties.map((s) => s.key),
      companyIds: companies.map((s) => s.key),
      displayEquity: true,
      displayPriorityAreas: false,
      displayCompanyTerritory: true,
      driveThrough: true,
      displayOpen: true,
      hasFlags: '',
      expiringSoon: 0,
      inactiveDaysBack: 0,
      selectedRecords: [],
      testFit: '',
      plannedSchematic: 0,
    });

    e.preventDefault();

    this.handlePageChange({
      ...this.state,
      page: 1,
      filter: '',
      sortOrder: 'created_desc',
      statusIds: statuses.map((s) => s.key),
      countyIds: counties.map((s) => s.key),
      companyIds: companies.map((s) => s.key),
      displayEquity: true,
      displayPriorityAreas: false,
      displayCompanyTerritory: true,
      driveThrough: true,
      displayOpen: true,
      hasFlags: '',
      expiringSoon: 0,
      inactiveDaysBack: 0,
      selectedRecords: [],
      testFit: '',
      plannedSchematic: 0,
    });
  }

  navigateItemDetails(applicationId, openInNewTab) {
    const { push, location } = this.props;
    if (openInNewTab) {
      const { href } = window.location;
      const length = href.lastIndexOf('siteidapplications') + 'siteidapplications'.length;
      window.open(`${href.substring(0, length)}/${applicationId}`, '_blank');
    } else {
      const { pathname } = location;
      const length = pathname.lastIndexOf('siteidapplications') + 'siteidapplications'.length;
      this.setState({ mode: 'detail', applicationId, goToApplicationId: '' });
      push(`${pathname.substring(0, length)}/${applicationId}`);
    }
  }

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.navigateItemDetails(event.target.value);
    }
  }

  initialiseListState() {
    const {
      location,
      statuses,
      companies,
      counties,
    } = this.props;
    const { search } = location;
    let newState = {
      pageSize: 25,
      page: 1,
      pageDetail: 1,
      filter: '',
      displayEquity: true,
      displayOpen: true,
      displayPriorityAreas: false,
      displayCompanyTerritory: true,
      driveThrough: true,
      sortOrder: 'created_desc',
      statusIds: statuses.map((s) => s.key),
      countyIds: counties.map((s) => s.key),
      companyIds: companies.map((s) => s.key),
      hasFlags: '',
      expiringSoon: 0,
      inactiveDaysBack: 0,
      applicationId: undefined,
      testFit: '',
      plannedSchematic: 0,
    };

    if (search) {
      const parsed = qs.parse(search.toLowerCase());

      // Take note all parsed keys are lower case.
      newState = {
        ...newState,
        page: parsed.page ? parseInt(parsed.page, 10) : 1,
        filter: parsed.filter ? parsed.filter : '',
        sortOrder: parsed.sortOrder ? parsed.sortOrder : 'created_desc',
        statusIds: parsed.statusids ? parsed.statusids.split(',').map((p) => parseInt(p, 10)) : [],
        countyIds: parsed.countyids ? parsed.countyids.split(',').map((p) => parseInt(p, 10)) : [],
        companyIds: parsed.companyids ? parsed.companyids.split(',').map((p) => parseInt(p, 10)) : [],
        hasFlags: parsed.hasflags,
        expiringSoon: parsed.expiringsoon ? parseInt(parsed.expiringsoon, 10) : 0,
        displayEquity: parsed.displayequity === 'true',
        displayOpen: parsed.displayopen === 'true',
        driveThrough: parsed.drivethrough === 'true',
        displayPriorityAreas: parsed.displaypriorityareas === 'true',
        displayCompanyTerritory: parsed.displaycompanyterritory === 'true',
        testFit: parsed.testfit,
        plannedSchematic: parsed.plannedschematic ? parseInt(parsed.plannedschematic, 10) : 0,

        inactiveDaysBack: parsed.inactivedaysback
          ? parseInt(parsed.inactivedaysback, 10)
          : 0,
      };
    }

    return newState;
  }

  render() {
    const {
      rowCount,
      applicationList,
      priorityAreas,
      equityStores,
      marketPlan,
      driveThroughDetail,
      companies,
      counties,
      statuses,
      isLicensee,
      isReadOnly,
      applicationDetail,
      priorityAreasDetail,
      equityStoresDetail,
      nearApplicationsDetail,
      isChanged,
      isChanging,
      isBulkChanging,
      isBulkChanged,
      bulkFeedback,
      isSaved,
      assigneeTypesDetail,
      companiesDetail,
      companyUsersDetail,
      countiesDetail,
      formatsDetail,
      leaseDetail,
      planningGrantTypesDetail,
      primaryBusinessGeneratorsDetail,
      sitePositionsDetail,
      siteTypesDetail,
      statusesDetail,
      tenureTypesDetail,
      projectTypesDetail,
      isLicenseeDetail,
      isReadOnlyDetail,
      documentCategories,
      extensionOutcomes,
      maxExtensions,
      companyTerritoryDetail,
      apiKey,
      driveThroughEnabled,
      loading,
      detailLoaded,
      detailErrorMessage,
      defaultResponses,
      currentUser,
      sentrkUrl,
    } = this.props;
    const {
      filter,
      page,
      pageDetail,
      statusIds,
      countyIds,
      companyIds,
      hasFlags,
      expiringSoon,
      inactiveDaysBack,
      selectedRecords,
      selectedRecordsDetail,
      mode,
      applicationId,
      radius,
      newAddress,
      center,
      editModalExpanded,
      bulkCommentModalExpanded,
      bulkProgressModalExpanded,
      showDocumentModal,
      bulkModalStatus,
      filterString,
      goToApplicationId,
      displayEquity,
      displayOpen,
      displayPriorityAreas,
      displayCompanyTerritory,
      driveThrough,
      sortOrder,
      fullScreenMode,
      singleLicenseeMode,
      testFit,
      plannedSchematic,
    } = this.state;
    const {
      doPaging,
      doPagingDetail,
      doSearch,
      navigateItemDetails,
      clearToggleApplication,
      doFilter,
      doSort,
      doToggle,
      doToggleMulti,
      doFullScreenMode,
      toggleApplication,
      toggleApplicationDetail,
      deleteComment,
      saveComment,
      handleSaveBulkComment,
      deleteDocument,
      saveDocument,
      handleUpdateRadius,
      handleSave,
      handleLocationSet,
      downloadDocument,
      handleReloadRecord,
      handleSaveExtension,
      handleSaveStatus,
      handleBulkSaveStatus,
      handleModalToggle,
      clearFilter,
      handleKeyDown,
      handleMapPan,
    } = this;

    if (!loading && !detailLoaded && detailErrorMessage
        && applicationId !== applicationDetail.applicationId
        && applicationId !== 0) {
      return (
        <div className="site-id-application-page page">
          <HeaderPanel
            pageTitle={`${
              mode === 'detail'
                ? 'S.I.T.T. Application Detail'
                : 'S.I.T.T. Applications'
            }`}
            pageSubtitle={mode === 'detail' ? applicationDetail.siteName : ''}
            pageSubtitle2={mode === 'detail' ? applicationDetail.status : ''}
            iconName="icon-globe"
          />

          <h3 className="error">This application could not be found.</h3>
        </div>
      );
    }

    let rawApplications = [...applicationList];
    let filteredData = [...applicationList];
    let filteredData2 = [];
    let filterString2 = filterString;
    let paginationData = [];
    let selectedRecords2 = [...selectedRecords];
    let currentPaginationRecord = 0;

    if (!displayOpen) {
      filteredData = applicationList.filter((a) => a.statusId !== 8);
      rawApplications = applicationList.filter((a) => a.statusId !== 8);
      selectedRecords2 = selectedRecords2.filter((a) => filteredData.findIndex((b) => b.applicationId === a) > -1);
    }

    if (mode === 'list') {

      filteredData = filteredData.filter(
        (a) => (!filter
            || (a.siteName
              && a.siteName.toLowerCase().indexOf(filter.toLowerCase()) > -1))
          && (statusIds.length === statuses.length ||

          //Status matches (but not 5)
          (statusIds.indexOf(a.statusId) > -1 && a.statusId !== 5) ||
          //Status is 5 but not requested or Created
          (statusIds.indexOf(5) > -1 && a.statusId === 5 && !a.projectRequested && !a.projectCreated) ||
          //Status is 52 and requested and not created
          (statusIds.indexOf(52) > -1 && a.statusId === 5 && a.projectRequested && !a.projectCreated) ||
          //Status is 55 and requested and created
          (statusIds.indexOf(55) > -1 && a.statusId === 5 && a.projectRequested && a.projectCreated) ||

          (a.statusId === 8 && displayOpen))
          && (countyIds.length === counties.length || countyIds.indexOf(a.countyId) > -1)
          && (companyIds.length === companies.length || companyIds.indexOf(a.companyId) > -1)
          && (hasFlags === ''
            || (a.hasFlags && hasFlags === 'true')
            || (!a.hasFlags && hasFlags === 'false'))
          && (!expiringSoon || expiringSoon === 999
            || (a.daysUntilExpiry >= 0 && a.daysUntilExpiry <= expiringSoon)
            || (expiringSoon === -1 && a.extensionRequested))
          && (testFit === ''
            || (a.testFit && testFit === 'true')
            || (!a.testFit && testFit === 'false'))
          && (plannedSchematic === 0 || (a.plannedSchematic <= plannedSchematic && a.plannedSchematic > 0)),
      );

      filteredData2 = filteredData;

      if (applicationId) {
        filteredData2 = filteredData.filter(
          (a) => applicationId === a.applicationId,
        );
      } else if (selectedRecords2.length > 0) {
        filteredData2 = filteredData.filter(
          (a) => selectedRecords2.indexOf(a.applicationId) > -1,
        );
        filterString2 += selectedRecords2.join('');
      }
    } else if (nearApplicationsDetail) {
      paginationData = filteredData.filter(
        (a) => (!filter
            || (a.siteName
              && a.siteName.toLowerCase().indexOf(filter.toLowerCase()) > -1))
          && (statusIds.length === statuses.length || statusIds.indexOf(a.statusId) > -1 || (a.statusId === 8 && displayOpen))
          && (countyIds.length === counties.length || countyIds.indexOf(a.countyId) > -1)
          && (companyIds.length === companies.length || companyIds.indexOf(a.companyId) > -1)
          && (hasFlags === ''
            || (a.hasFlags && hasFlags === 'true')
            || (!a.hasFlags && hasFlags === 'false'))
          && (!expiringSoon || expiringSoon === 999
            || (a.daysUntilExpiry >= 0 && a.daysUntilExpiry <= expiringSoon)
            || (expiringSoon === -1 && a.extensionRequested))
          && (testFit === ''
            || (a.testFit && testFit === 'true')
            || (!a.testFit && testFit === 'false'))
          && ( plannedSchematic === 0 || (a.plannedSchematic <= plannedSchematic && a.plannedSchematic > 0)),
      );

      currentPaginationRecord = paginationData.findIndex(
        (f) => f.applicationId === applicationDetail.applicationId,
      );

      filteredData = nearApplicationsDetail.filter(
        (a) => a.distanceUnits === 'miles' && a.distanceFromSource <= radius,
      );

      filteredData2 = filteredData;
      filterString2 += radius;
    }

    const flags = (applicationDetail.comment
        && applicationDetail.comment.filter((c) => c.isFlag))
      || [];

    const showDriveThrough = driveThroughEnabled
                             || ((mode === 'list' ? !isLicensee : !isLicenseeDetail)
                                  && !singleLicenseeMode);

    let equityStoresFiltered = [];

    if(mode === 'list'){
      equityStoresFiltered = equityStores || [];
    }else{
      equityStoresFiltered = equityStoresDetail || [];
    }

    if(!displayEquity){
      equityStoresFiltered = [];
    }

    if(singleLicenseeMode || (mode === 'list' && isLicensee) || (mode === 'detail' && isLicenseeDetail)){
      equityStoresFiltered = equityStoresFiltered.filter((c) => c.lifecycleStatus.toLowerCase() === 'open');
    }

    let displayStatus = applicationDetail && applicationDetail.status;

    if (applicationDetail && applicationDetail.statusId === 5/*Registered*/) {

      if (applicationDetail.projectCreated) {
        displayStatus += ' (Project requested by admin)';
      } else if (applicationDetail.projectRequested) {
        displayStatus += ' (Project requested by licensee)';
      }
    }

    return (
      <div className="site-id-application-page page">
        <HeaderPanel
          pageTitle={`${
            mode === 'detail'
              ? 'S.I.T.T. Application Detail'
              : 'S.I.T.T. Applications'
          }`}
          pageSubtitle={mode === 'detail' ? applicationDetail.siteName : ''}
          pageSubtitle2={mode === 'detail' ? displayStatus : ''}
          iconName="icon-globe"
          pagingControl={mode === 'detail' && paginationData.length && currentPaginationRecord >= 0 && (
            <div className="pagination application">
              <button
                type="button"
                disabled={currentPaginationRecord === 0}
                onClick={() => navigateItemDetails(
                  paginationData[currentPaginationRecord - 1].applicationId,
                )}
              >
                &lt;
              </button>
              {`Application (SREF-${paginationData[currentPaginationRecord].applicationId}) ${currentPaginationRecord + 1}/${paginationData.length}`}
              <button
                type="button"
                disabled={currentPaginationRecord === paginationData.length - 1}
                onClick={() => navigateItemDetails(
                  paginationData[currentPaginationRecord + 1].applicationId,
                )}
              >
                &gt;
              </button>
            </div>
          )}
        >
          <div className="top-right-controls">
            {(!isLicenseeDetail && mode === 'detail') || (!isLicensee && mode === 'list') ? (
              <div className="licensee-view-wrapper">
                <div>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={singleLicenseeMode}
                      onChange={() => this.setState({ singleLicenseeMode: !singleLicenseeMode })}
                    />
                    <span className="slider round" />
                  </label>
                </div>
                <div className="option-text">Licensee View</div>
              </div>
            ) : <div />}
            <div className="jump-control">
              <span className="sref-prefix">SREF-</span>
              <input
                type="text"
                title="Type the numeric portion of an application's site ref and click the go button to be directed to the specified application."
                aria-label="Navigate to Site reference number"
                placeholder="00000"
                value={goToApplicationId}
                onChange={(e) => this.setState({ goToApplicationId: e.target.value })}
                onKeyDown={handleKeyDown}
              />
              <button
                className="search-button"
                title="Type the numeric portion of an application's site ref and click the go button to be directed to the specified application."
                type="button"
                disabled={!goToApplicationId || Number.isNaN(Number.parseInt(goToApplicationId, 10))}
                onClick={() => navigateItemDetails(goToApplicationId)}
              >
                Go
              </button>
            </div>
          </div>
          <div id="siteidheadercontrols">
            {mode === 'detail' && (
              <label>
                Search Radius (
                {radius.toFixed(1)}
                {' '}
                miles)
                <input
                  type="range"
                  min="0"
                  max="16100"
                  step="805"
                  value={radius * 1609}
                  onChange={(e) => handleUpdateRadius(e)}
                />
              </label>
            )}
          </div>
        </HeaderPanel>
        <div className={`${mode === 'detail' ? 'detail-splitter' : ''} `}>
          <div className={`${mode === 'detail' ? 'detail-stacker' : ''} `}>
            {mode === 'detail'
              && applicationId > 0
              && ((flags && flags.length > 0)
                || applicationDetail.expiringSoon) && (
              <Flags
                flags={flags}
                expiringSoon={applicationDetail.expiringSoon}
                expirationDate={applicationDetail.expirationDate}
                extensionRequested={applicationDetail.extensionRequested}
                statusId={applicationDetail.statusId}
                fullScreenMode={fullScreenMode}
                isLicenseeDetail={isLicenseeDetail || singleLicenseeMode}
              />
            )}
            {apiKey && (
              <MapPanel
                ref="map"
                allData={
                  (mode === 'list'
                    ? rawApplications || []
                    : nearApplicationsDetail || []) || []
                }
                companyTerritory={
                  displayCompanyTerritory ? companyTerritoryDetail : []
                }
                filteredData={filteredData2}
                priorityDetail={
                  displayPriorityAreas ?
                    (mode === 'detail' && !isLicenseeDetail ? priorityAreasDetail :
                      (mode === 'list' && !isLicensee ? priorityAreas : [])
                    )
                    : []
                }
                equityStores={equityStoresFiltered}
                driveThroughDetail={(driveThrough && showDriveThrough) ? (mode === 'list' ? marketPlan : driveThroughDetail) : []}
                center={
                  mode === 'detail' && center
                    ? center
                    : applicationDetail && applicationDetail.latitude
                      ? {
                        latitude: applicationDetail.latitude,
                        longitude: applicationDetail.longitude,
                      }
                      : center
                }
                radius={radius}
                mode={mode}
                editable={
                  applicationId === 0 || applicationDetail.statusId === 1
                }
                locationSet={handleLocationSet}
                apiKey={apiKey}
                loading={loading}
                filter={filterString2}
                toggleApplication={toggleApplication}
                navigateItemDetails={navigateItemDetails}
                applicationId={applicationId}
                statuses={statuses}
                canViewPriorityAreas={(mode === 'detail' && !isLicenseeDetail) || (mode === 'list' && !isLicensee) }
                displayOpen={displayOpen}
                displayEquity={displayEquity}
                displayPriorityAreas={displayPriorityAreas}
                displayCompanyTerritory={displayCompanyTerritory}
                driveThrough={driveThrough}
                statusIds={statusIds}
                doToggle={doToggle}
                doToggleMulti={doToggleMulti}
                driveThroughEnabled={driveThroughEnabled
                                  || ((mode === 'list' ? !isLicensee : !isLicenseeDetail)
                                    && !singleLicenseeMode)}
                doFullScreenMode={doFullScreenMode}
                fullScreenMode={fullScreenMode}
              />
            )}
            {mode === 'detail'
              && applicationId > 0
              && nearApplicationsDetail.length > 0 && (
              <TableDetail
                data={
                  singleLicenseeMode
                    ? filteredData.filter((f) => f.companyId === applicationDetail.companyId)
                    : filteredData
                }
                applicationDetail={applicationDetail}
                selectedRecords={selectedRecordsDetail}
                equityStores={equityStoresFiltered}
                storeId = {applicationId}
                toggleApplication={toggleApplicationDetail}
                bulkComment={() => handleModalToggle('bulkCommentModalExpanded', true)}
                bulkStatus={(status) => handleModalToggle(
                  'bulkProgressModalExpanded',
                  true,
                  status,
                )}
                bulkRelate={this.handleSaveBulkRelate}
                bulkUnrelate={this.handleSaveBulkUnrelate}
                isLicensee={mode === 'list' ? isLicensee : (isLicenseeDetail || singleLicenseeMode)}
                isReadOnly={mode === 'list' ? isReadOnly : isReadOnlyDetail}
                doPaging={doPagingDetail}
                page={pageDetail}
                fullScreenMode={fullScreenMode}
              />
            )}
          </div>

          {mode === 'detail' && (
            <Fields
              applicationDetail={applicationDetail}
              assigneeTypesDetail={assigneeTypesDetail || []}
              companiesDetail={companiesDetail || []}
              companyUsersDetail={companyUsersDetail || []}
              countiesDetail={countiesDetail || []}
              formatsDetail={formatsDetail || []}
              leaseDetail={leaseDetail || []}
              planningGrantTypesDetail={planningGrantTypesDetail || []}
              primaryBusinessGeneratorsDetail={
                primaryBusinessGeneratorsDetail || []
              }
              projectTypesDetail={projectTypesDetail || []}
              sitePositionsDetail={sitePositionsDetail || []}
              siteTypesDetail={siteTypesDetail || []}
              statusesDetail={statusesDetail || []}
              tenureTypesDetail={tenureTypesDetail || []}
              isLicenseeDetail={isLicenseeDetail || singleLicenseeMode}
              isReadOnlyDetail={isReadOnlyDetail}
              singleLicenseeMode={singleLicenseeMode}
              nearApplicationsDetail={nearApplicationsDetail || []}
              newAddress={newAddress}
              center={center}
              save={handleSave}
              isChanged={isChanged}
              isChanging={isChanging}
              reloadRecord={handleReloadRecord}
              saveStatus={handleSaveStatus}
              saveExtension={handleSaveExtension}
              extensionOutcomes={extensionOutcomes || []}
              maxExtensions={maxExtensions}
              isSaved={isSaved}
              handleModalToggleParent={handleModalToggle}
              defaultResponses={defaultResponses}
              errorMessage={detailErrorMessage}
              sentrkUrl={sentrkUrl}
            />
          )}
        </div>
        {mode === 'detail' && applicationId > 0 && (
          <Comments
            comments={applicationDetail.comment || []}
            auditEntries={applicationDetail.auditEntry || []}
            extensions={applicationDetail.extension || []}
            documents={applicationDetail.document || []}
            statusChanges={applicationDetail.statusChange || []}
            isLicensee={isLicenseeDetail || singleLicenseeMode}
            isReadOnly={mode === 'list' ? isReadOnly : isReadOnlyDetail}
            saveComment={saveComment}
            deleteComment={deleteComment}
            saveDocument={saveDocument}
            deleteDocument={deleteDocument}
            documentCategories={documentCategories}
            downloadFile={downloadDocument}
            handleModalToggleParent={handleModalToggle}
            isChanged={isChanged}
            isChanging={isChanging}
            currentUser={currentUser}
          />
        )}
        {mode === 'list' && (
          <>
            {(filter !== ''
              || statusIds.length !== statuses.length
              || countyIds.length !== counties.length
              || companyIds.length !== companies.length
              || !displayEquity
              || !displayPriorityAreas
              || !displayCompanyTerritory
              || !driveThrough
              || !displayOpen
              || hasFlags !== ''
              || (expiringSoon !== 999 && expiringSoon !== 0 && expiringSoon !== false)
              || inactiveDaysBack !== 0
              || selectedRecords2.length > 0
              || testFit !== ''
              || plannedSchematic != 0) && (
              <div className="clear-filter">
                <button
                  className="action bad negative"
                  type="button"
                  onClick={(e) => clearFilter(e)}
                >
                    Clear Filters
                </button>
              </div>
            )}
            <FilterPanel
              companies={companies}
              companyIds={companyIds}
              counties={counties}
              countyIds={countyIds}
              statuses={statuses}
              statusIds={statusIds}
              hasFlags={hasFlags}
              expiringSoon={expiringSoon}
              inactiveDaysBack={inactiveDaysBack}
              displayEquity={displayEquity}
              displayOpen={displayOpen}
              showEquityFilter={!isLicensee}
              sortOrder={sortOrder}
              doFilter={doFilter}
              doSort={doSort}
              doToggle={doToggle}
              doToggleMulti={doToggleMulti}
              fullScreenMode={fullScreenMode}
              testFit={testFit}
              plannedSchematic={plannedSchematic}
            />
            <div className="edit-buttons">
              {!editModalExpanded ? (
                <button
                  className="action bulkedit"
                  disabled={!selectedRecords2 || !selectedRecords2.length}
                  type="button"
                  onClick={() => handleModalToggle('editModalExpanded', true)}
                >
                  Bulk Edit
                  {' '}
                  {selectedRecords2 && selectedRecords2.length > 0
                    ? `(${selectedRecords2.length})`
                    : ''}
                </button>
              ) : (
                <div
                  className="bulkedit-menu"
                  onClick={() => handleModalToggle('editModalExpanded', false)}
                >
                  <div className="background" />
                  <div className="bulkedit-button-holder">
                    Bulk Edit
                    
                    {!isReadOnly && (<>
                    <button
                      className="action"
                      disabled={filteredData2 && filteredData2.length && filteredData2.filter(x => x.statusId >= 5).length > 0 }
                      onClick={() => handleModalToggle('bulkProgressModalExpanded', true)}
                      type="button"
                    >
                      Progress Applications
                    </button>
                    {!isLicensee ? (
                      <button
                        className="action"
                        type="button"
                        onClick={() => handleModalToggle(
                          'bulkProgressModalExpanded',
                          true,
                          9,
                        )}
                      >
                        Reject Applications
                      </button>
                    ) : (
                      <button
                        className="action"
                        type="button"
                        onClick={() => handleModalToggle(
                          'bulkProgressModalExpanded',
                          true,
                          11,
                        )}
                      >
                        Withdraw Applications
                      </button>
                    )}</>)}
                    <button
                      className="action"
                      type="button"
                      onClick={() => handleModalToggle('bulkCommentModalExpanded', true)}
                    >
                      Comment Applications
                    </button>
                    <button
                      className="action bad negative"
                      type="button"
                      onClick={() => clearToggleApplication()}
                    >
                      Clear Selection
                    </button>
                  </div>
                </div>
              )}

              {isLicensee && !isReadOnly && (
                <button
                  className="action"
                  type="button"
                  onClick={() => navigateItemDetails(0)}
                >
                  Create Application
                </button>
              )}
            </div>

            {!fullScreenMode ?
            <Table
              rowCount={rowCount}
              doPaging={doPaging}
              page={page}
              doSearch={doSearch}
              sortOrder={sortOrder}
              navigateItemDetails={navigateItemDetails}
              panMap={handleMapPan}
              data={filteredData}
              filter={filter}
              isReadOnly={isReadOnly}
              selectedRecords={selectedRecords2}
              toggleApplication={toggleApplication}
              handleFieldUpdate={doFilter}
            />: null}
          </>
        )}
        {bulkCommentModalExpanded && (
          <SaveBulkCommentModal
            cancel={() => handleModalToggle('bulkCommentModalExpanded', false)}
            inProgress={isBulkChanging}
            complete={isBulkChanged}
            summary={bulkFeedback}
            continue={handleSaveBulkComment}
            isLicensee={mode === 'list' ? isLicensee : isLicenseeDetail}
            selectedRecords={
              mode === 'list' ? selectedRecords2 : selectedRecordsDetail
            }
            records={filteredData2}
          />
        )}
        {bulkProgressModalExpanded && (
          <SaveBulkStatusModal
            cancel={() => handleModalToggle('bulkProgressModalExpanded', false)}
            inProgress={isBulkChanging}
            complete={isBulkChanged}
            summary={bulkFeedback}
            continue={handleBulkSaveStatus}
            isLicensee={mode === 'list' ? isLicensee : isLicenseeDetail}
            title="Bulk Progress Applications"
            nextStatus={bulkModalStatus}
            nearApplications={
              mode === 'list' ? [] : [applicationDetail, ...filteredData]
            }
            selectedRecords={
              mode === 'list' ? selectedRecords2 : selectedRecordsDetail
            }
            records={filteredData2}
          />
        )}
        {showDocumentModal && (
          <SaveDocumentModal
            cancel={() => handleModalToggle('showDocumentModal', false)}
            inProgress={isChanging}
            continue={saveDocument}
            isLicensee={mode === 'list' ? isLicensee : isLicenseeDetail}
            document={document}
            documentCategories={documentCategories}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    siteIDApplications: apps,
    siteIDInactiveApplications: inactive,
    siteIDOpenStores: open,
    siteIDEquityStores: equity,
    siteIDApplicationDetail: detail,
    siteIDDriveThrough: drive,
  } = state;

  const companies = (apps.companies || [])
    .concat(inactive.companies || [])
    .concat(open.companies || [])
    .sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    })
    .reduce((acc, curr) => {
      // if first we always add it because
      // it's not a dupe
      if (!acc.length) {
        acc.push(curr);
        return acc;
      }
      // if the id of the current doesn't
      // equal id of the previous we also add it
      if (curr.key !== acc[acc.length - 1].key) {
        acc.push(curr);
      }
      return acc;
    }, []);

  const counties = (apps.counties || [])
    .concat(inactive.counties || [])
    .concat(open.counties || [])
    .sort((a, b) => {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    })
    .reduce((acc, curr) => {
      // if first we always add it because
      // it's not a dupe
      if (!acc.length) {
        acc.push(curr);
        return acc;
      }
      // if the id of the current doesn't
      // equal id of the previous we also add it
      if (curr.key !== acc[acc.length - 1].key) {
        acc.push(curr);
      }
      return acc;
    }, []);

  let statuses = (apps.statuses || [])
    .concat(inactive.statuses || [])
    .concat(detail.statuses || [])
    .sort((a, b) => a.key - b.key)
    .reduce((acc, curr) => {
      // if first we always add it because
      // it's not a dupe
      if (!acc.length) {
        acc.push(curr);
        return acc;
      }
      // if the id of the current doesn't
      // equal id of the previous we also add it
      if (curr.key !== acc[acc.length - 1].key) {
        acc.push(curr);
      }
      return acc;
    }, []);

  statuses.push({ key: 52, value: 'Registered - Project Requested' });
  statuses.push({ key: 55, value: 'Registered - Project Created' });

  return {
    // List
    loading: apps.isFetching || inactive.isFetching || open.isFetching || detail.isFetching,
    loaded: apps.isLoaded || inactive.isLoaded,
    applicationList: apps.data.concat(inactive.data.concat(open.data)),
    companies,
    counties,
    statuses,
    equityStores: equity.equityStores,
    priorityAreas: apps.priorityAreas,
    marketPlan: drive.marketPlan,
    isLicensee: apps.isLicensee !== false,
    isReadOnly: apps.isReadOnly !== false,
    isBulkChanging: apps.isBulkChanging,
    isBulkChanged: apps.isBulkChanged,
    bulkFeedback: apps.bulkFeedback,
    errorMessage:
      apps.errorMessage || inactive.errorMessage || detail.errorMessage,
    // Detail props
    applicationDetail: detail.data || {},
    priorityAreasDetail: detail.priorityAreas,
    equityStoresDetail: detail.equityStores,
    defaultResponses: detail.defaultResponses,
    nearApplicationsDetail: detail.nearApplications || [],
    companyTerritoryDetail: detail.companyTerritory || [],
    driveThroughDetail: detail.dtmps || [],
    assigneeTypesDetail: detail.assigneeTypes,
    companiesDetail: detail.companies,
    companyUsersDetail: detail.companyUsers,
    countiesDetail: detail.counties,
    formatsDetail: detail.formats,
    leaseDetail: detail.leases,
    planningGrantTypesDetail: detail.planningGrantTypes,
    primaryBusinessGeneratorsDetail: detail.primaryBusinessGenerators,
    sitePositionsDetail: detail.sitePositions,
    siteTypesDetail: detail.siteTypes,
    statusesDetail: detail.statuses,
    tenureTypesDetail: detail.tenureTypes,
    projectTypesDetail: detail.projectTypes,
    documentCategories: detail.documentCategories,
    extensionOutcomes: detail.extensionOutcomes,
    isLicenseeDetail: detail.isLicensee !== false,
    isReadOnlyDetail: detail.isReadOnly !== false,
    isChanged: detail.isChanged,
    isChanging: detail.isChanging,
    isSaved: detail.isSaved,
    detailLoaded: detail.loaded,
    detailErrorMessage: detail.errorMessage,
    sentrkUrl: detail.sentrkUrl,
    // Server settings
    maxExtensions: detail.maxExtensions || 999,
    driveThroughEnabled: apps.driveThroughEnabled || detail.driveThroughEnabled,
    // API
    apiKey: apps.apiKey || detail.apiKey,
    //Auth
    currentUser: state.auth.username,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getApplications: () => dispatch(siteIDActions.getApplications()),
  getInactiveApplications: (daysBack) => dispatch(siteIDActions.getInactiveApplications(daysBack)),
  getEquityStores: () => dispatch(siteIDActions.getEquityStores()),
  getOpenStores: () => dispatch(siteIDActions.getOpenStores()),
  getDriveThroughPlan: () => dispatch(siteIDActions.getDriveThroughPlan()),

  getApplicationDetails: (applicationId) => dispatch(
    siteIDActions.getApplicationDetail(applicationId),
  ),
  saveApplicationDetails: (detail) => dispatch(siteIDActions.saveApplicationDetail(detail)),

  updateMap: (latitude, longitude, applicationId) => dispatch(
    siteIDActions.updateMap(latitude, longitude, applicationId),
  ),

  saveComment: (comment) => dispatch(siteIDActions.saveComment(comment)),
  startBulkComment: () => dispatch(siteIDActions.startBulkComment()),
  saveBulkRelate: (linkedSites) => dispatch(siteIDActions.saveBulkRelate(linkedSites)),
  saveBulkUnrelate: (linkedSites) => dispatch(siteIDActions.saveBulkUnrelate(linkedSites)),  
  saveBulkComment: (comments) => dispatch(siteIDActions.saveBulkComment(comments)),
  deleteComment: (applicationId, commentId) => dispatch(
    siteIDActions.deleteComment(applicationId, commentId),
  ),

  saveDocument: (document) => dispatch(siteIDActions.saveDocument(document)),
  deleteDocument: (applicationId, documentId) => dispatch(
    siteIDActions.deleteDocument(applicationId, documentId),
  ),

  saveExtension: (extension) => dispatch(siteIDActions.saveExtension(extension)),

  saveStatus: (status) => dispatch(siteIDActions.saveStatusChange(status)),
  startBulkStatus: () => dispatch(siteIDActions.startBulkStatusChange()),
  saveBulkStatus: (statuses) => dispatch(siteIDActions.saveBulkStatusChange(statuses)),

  downloadFile: (url) => dispatch(specialActions.downloadFile(url)),

  push: (path) => dispatch(navigationActions.pushNavigation(path)),
  replace: (path) => dispatch(navigationActions.replaceNavigation(path)),
  reset: () => dispatch(navigationActions.resetNavigation()),
  goBack: () => dispatch(navigationActions.backNavigation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteIDApplications);
