import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { connectConfigurationActions } from '../../../actions/connectConfigurationActions';

class ConnectConfiguration extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      configurationItems: [],
    };

    this.handleSaveConfiguration = this.handleSaveConfiguration.bind(this);

    this.handleCheckInputListUpdate = this.handleCheckInputListUpdate.bind(
      this,
    );
  }

  componentDidMount() {
    const { getConfiguration } = this.props;
    
    getConfiguration();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isLoaded, isSaved, configurationItems } = this.props;

    if (isLoaded && !prevProps.isLoaded) {
      this.setState({
        configurationItems,
      });
    } else if (isSaved && !prevProps.isSaved){
      this.props.goBack();
    }
  }

  handleSaveConfiguration(e) {
    let { saveConfiguration } = this.props;
    let {
      configurationItems,
    } = this.state;

    
    saveConfiguration({
      configurationItems: configurationItems,
    });

    e.preventDefault();
  }

  handleCheckInputListUpdate(e, id) {
    const { configurationItems } = this.state;

    let selected = configurationItems.map(x => {
      if(x.configurationId === id){
        return { ...x, userEnabled:e.target.checked };
      }else{
        return x;
      }
    });
  
    this.setState({
      configurationItems: selected,
    });
  }

  render() {
    const { configurationItems } = this.state;
    const {
      userSaving,
      userSaved,
      errorMessage,
    } = this.props;
    return (
      <div className="edit-user-page page">
        <div className="floating-panel">
          <form noValidate
            onSubmit={e => this.handleSaveConfiguration(e)}>
            <div className="floating-header">
              <div className="header-title">
                <div className="icon-form"></div>
                <h2>Connect Configuration</h2>
              </div>
            </div>

            {configurationItems && configurationItems.map(x =>
              <label key={x.configurationId}
                className="wrapping">
                <div className="toggle-wrapper">
                  <input
                    type="checkbox"
                    className="toggle"
                    checked={x.userEnabled}
                    onChange={e =>
                      this.handleCheckInputListUpdate(
                        e,
                        x.configurationId,
                      )
                    }
                  />
                  <div className="toggle-icon"></div>
                </div>
                <span>{x.name}</span>
              </label>,
            )}

                 

            <p>{errorMessage}</p>
            <div className="button-container">
              <button type="submit"
                className="action"
                disabled={userSaving}>
                {userSaving
                  ? 'Saving...'
                  : userSaved && !this.state.userId
                    ? 'Saved'
                    : 'Save'}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let { connectConfiguration } = state;
  return {
    isLoading: connectConfiguration.isLoading,
    isLoaded: connectConfiguration.isLoaded,
    isSaved: connectConfiguration.isSaved,
    configurationItems: connectConfiguration.configurationItems,
    errorMessage: connectConfiguration.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getConfiguration: () => dispatch(connectConfigurationActions.getConfiguration()),
    saveConfiguration: configuration => dispatch(connectConfigurationActions.saveConfiguration(configuration)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectConfiguration);
