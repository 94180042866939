import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { userManagementActions } from '../../../actions/userManagementActions';
import DeleteModal from '../../shared/DeleteModal';

class EditUser extends React.Component {
  

  constructor(props) {
    super(props);
    this.state = {
      userId: null,
      userName: '',
      tenantId: 1,
      familyName: '',
      givenName: '',

      jobTitle: '',
      businessPhone: '',
      mobilePhone: '',
      street1: '',
      street2: '',
      street3: '',
      city: '',
      stateOrProvince: '',
      zipOrPostalCode: '',
      countryOrRegion: '',
      personalNotes: '',
      preferredContactMethodId: 1,
      contactMethods: [],
      allowEmail: false,
      allowPhone: false,

      moduleGroupIds: [],
      moduleGroups: [],
      storeGroupIds: [],
      storeGroups: [],
      storeIds: [],
      stores: [],
      companiesIds: [],
      companies: [],
      accountLocked: false,
      sendWelcomeEmail: false,

      isSiteIdUser: false,
      isPortalUser: false,

      detailsExpanded: true,
      modulesExpanded: false,
      customerExpanded: false,
      rightsExpanded: false,
      companiesExpanded: false,

      companyFilter: '',
      storeFilter: '',

      showDeleteModal: false,

      showValidation: false,

      retailStorePartner: false,

      tenantKey: '',
      isEMEA: '',
      isAPAC: '',

      userTypes: [],
      userTypeId: '',
    };

    this.handleSaveUser = this.handleSaveUser.bind(this);
    this.handleDeleteUser = this.handleDeleteUser.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);
    this.handleCheckInputListUpdate = this.handleCheckInputListUpdate.bind(
      this,
    );
    this.handleCheckInputListUpdateMultiple = this.handleCheckInputListUpdateMultiple.bind(
      this,
    );
    this.handleCheckInputListCompanyUpdateMultiple = this.handleCheckInputListCompanyUpdateMultiple.bind(
      this,
    );
    this.handleToggleExpander = this.handleToggleExpander.bind(this);

    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);
  }

  componentDidMount() {
    let userId = this.props.match.params.userId;
    if (parseInt(userId)) {
      this.props.getUser(userId);
    }
  }

  componentDidUpdate(prevProps) {
    const { pathname } = this.props.location;
    if (this.props.userLoaded && !prevProps.userLoaded) {
      this.setState({
        userId: this.props.userDetails.user.userId,
        userName: this.props.userDetails.user.userName,
        tenantId: this.props.userDetails.user.tenantId,
        familyName: this.props.userDetails.user.familyName,
        givenName: this.props.userDetails.user.givenName,
        moduleGroupIds: this.props.userDetails.user.moduleGroupIds,
        storeGroupIds: this.props.userDetails.user.storeGroupIds,
        moduleGroups: this.props.userDetails.moduleGroups,
        storeGroups: this.props.userDetails.storeGroups,
        storeIds: this.props.userDetails.user.storeIds,
        stores: this.props.userDetails.stores,
        companiesIds: this.props.userDetails.user.companyIds,
        companies: this.props.userDetails.companies,
        accountLocked: this.props.userDetails.user.locked,
        isPortalUser: this.props.userDetails.user.isPortalUser,
        isSiteIdUser: this.props.userDetails.user.isSiteIdUser,


        jobTitle: this.props.userDetails.user.jobTitle,
        businessPhone: this.props.userDetails.user.businessPhone,
        mobilePhone: this.props.userDetails.user.mobilePhone,
        street1: this.props.userDetails.user.street1,
        street2: this.props.userDetails.user.street2,
        street3: this.props.userDetails.user.street3,
        city: this.props.userDetails.user.city,
        stateOrProvince: this.props.userDetails.user.stateOrProvince,
        zipOrPostalCode: this.props.userDetails.user.zipOrPostalCode,
        countryOrRegion: this.props.userDetails.user.countryOrRegion,
        personalNotes: this.props.userDetails.user.personalNotes,
        preferredContactMethodId: this.props.userDetails.user
          .preferredContactMethodId,
        userType: this.props.userDetails.user.userType,
        contactMethods: this.props.userDetails.contactMethods,
        allowEmail: this.props.userDetails.user.allowEmail,
        allowPhone: this.props.userDetails.user.allowPhone,
        retailStorePartner: this.props.userDetails.user.retailStorePartner,
        tenantKey: this.props.tenantKey,
        isEMEA: this.props.tenantKey === 'EMEA',
        isAPAC: this.props.tenantKey === 'APAC',
        userTypes: this.props.userDetails.userTypes,
        userTypeId: this.props.userDetails.user.userTypeId,
      });
    } else if (this.props.userSaved && !prevProps.userSaved) {
      if (
        (this.props.userDetails.user.userId && !prevProps.userDetails.user) ||
        this.props.userDetails.user.userId !== prevProps.userDetails.user.userId
      ) {
        //New user saved for the first time.
        this.props.replace(`${pathname}${this.props.userDetails.user.userId}`);
        this.props.getUser(this.props.userDetails.user.userId);

        this.setState({ sendWelcomeEmail: true });
      } else {
        this.props.goBack();
      }
    } else if (this.props.userDeleted && !prevProps.userDeleted) {
      this.props.goBack();
    }
  }

  emailRegex() {
    return new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}$');
  }

  handleSaveUser(e) {
    let { saveUser, userDetails } = this.props;
    const {
      isSuperUser,
    } = userDetails;
    let {
      userId,
      userName,
      tenantId,
      familyName,
      givenName,
      storeGroupIds,
      moduleGroupIds,
      companiesIds,
      storeIds,
      accountLocked,
      sendWelcomeEmail,

      isPortalUser,
      isSiteIdUser,

      jobTitle,
      businessPhone,
      mobilePhone,
      street1,
      street2,
      street3,
      city,
      stateOrProvince,
      zipOrPostalCode,
      countryOrRegion,
      personalNotes,
      preferredContactMethodId,
      allowEmail,
      allowPhone,
      retailStorePartner,
      userTypeId,
    } = this.state;

    if (
      !givenName ||
      !familyName ||
      !userName ||
      !this.emailRegex().test(userName) ||
      (isSuperUser && !(isPortalUser || isSiteIdUser))
    ) {
      this.setState({ showValidation: true });
    } else {
      saveUser({
        PlatformSaveUser: {
          userId,
          userName,
          tenantId,
          familyName,
          givenName,
          storeGroupList: storeGroupIds,
          moduleGroupList: moduleGroupIds,
          StoreList: storeIds,
          accountLocked,
          sendWelcomeEmail,
          isSiteIdUser,
          isPortalUser,
          jobTitle,
          businessPhone,
          mobilePhone,
          street1,
          street2,
          street3,
          city,
          stateOrProvince,
          zipOrPostalCode,
          countryOrRegion,
          personalNotes,
          preferredContactMethodId,
          allowEmail,
          allowPhone,
          retailStorePartner,
          userTypeId,
        },
        SiteIDSaveUser: {
          CompanyList: companiesIds,
        },
      });
    }

    e.preventDefault();
  }

  handleDeleteModalShow() {
    this.setState({ showDeleteModal: true });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false });
  }

  handleToggleExpander(expanderName) {
    let state = this.state;
    this.setState({ [expanderName]: !state[expanderName] });
  }

  handleDeleteUser() {
    this.props.deleteUser(this.state.userId);
    this.handleDeleteModalHide();
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  handleCheckInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = true && e.target.checked;

    this.setState(update);
  }

  handleCheckInputListUpdate(e, name, id) {
    let ids = this.state[name];

    if (e.target.checked) {
      if (ids.indexOf(id) === -1) {
        ids.push(id);
      }
    } else {
      let pos = ids.indexOf(id);
      if (pos !== -1) {
        ids.splice(pos, 1);
      }
    }

    this.setState({
      [name]: ids,
    });
  }

  handleCheckInputListUpdateMultiple() {
    let { storeIds, stores, storeFilter } = this.state;

    let listValues = stores
      .filter(
        s =>
          (s.storeName.toLowerCase().indexOf(storeFilter.toLowerCase()) > -1 ||
            s.storeNumber.toLowerCase().indexOf(storeFilter.toLowerCase()) > -1) &&
          storeIds.indexOf(s.storeId) === -1,
      )
      .map(s => s.storeId);

    this.setState({ storeIds: [...storeIds, ...listValues] });
  }

  handleCheckInputListCompanyUpdateMultiple() {
    let { companiesIds, companies, companyFilter } = this.state;

    let listValues = companies
      .filter(
        s =>
          (s.name.toLowerCase().indexOf(companyFilter.toLowerCase()) > -1) &&
          companiesIds.indexOf(s.companyId) === -1,
      )
      .map(s => s.companyId);

    this.setState({ companiesIds: [...companiesIds, ...listValues] });
  }

  generateMenuHierarchy(modules) {
    //TODO: make tree structure
    let topLevel = modules.filter(m => m.parentModuleId === null);

    return topLevel.map(m => {
      let otherLevel = modules.filter(o => o.parentModuleId === m.moduleId);
      return (
        <ul key={m.moduleId}>
          {m.moduleName} Module
          {otherLevel.map(p => (
            <li key={p.moduleId}>p.moduleName</li>
          ))}
        </ul>
      );
    });
  }

  getUserPanel() {
    let {
      givenName,
      familyName,
      userName,
      detailsExpanded,
      accountLocked,
      sendWelcomeEmail,
      isSiteIdUser,
      isPortalUser,
      showValidation,
      jobTitle,
      businessPhone,
      mobilePhone,
      street1,
      street2,
      street3,
      city,
      stateOrProvince,
      zipOrPostalCode,
      countryOrRegion,
      personalNotes,
      preferredContactMethodId,
      contactMethods,
      allowEmail,
      allowPhone,
      retailStorePartner,
      isAPAC,
      userTypes,
      userTypeId,
    } = this.state;

    const {
      isSuperUser,
    } = this.props.userDetails;


    return (
      <div className="group-details accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('detailsExpanded')}
        >
          <h3>User Details</h3>
          <div
            className={`icon ${
              detailsExpanded ? 'icon-expand' : 'icon-collapse'
            }`}
          ></div>
        </div>
        <div
          className={`accordion-body ${
            detailsExpanded ? '' : 'collapsed'
          } horizontal-flex user-fields`}
        >
          <div className="user-field-block">
            <div>
              <label>
                Username/Email
                <input
                  autoFocus
                  placeholder="Email"
                  type="email"
                  required
                  className={
                    showValidation &&
                      (!userName || !this.emailRegex().test(userName))
                      ? 'invalid'
                      : ''
                  }
                  onChange={e => this.handleTextInputUpdate(e, 'userName')}
                  value={userName}
                  autoComplete="off"
                />
              </label>
              <label>
                Retail Store Partner
                <div className="toggle-wrapper">
                  <input
                    type="checkbox"
                    className="toggle"
                    onChange={e =>
                      this.handleCheckInputUpdate(e, 'retailStorePartner')
                    }
                    checked={retailStorePartner}
                  />
                  <div className="toggle-icon"></div>
                </div>
              </label>
            </div>
            <div>
              <label>
                First Name
                <input
                  placeholder="..."
                  required
                  className={showValidation && !givenName ? 'invalid' : ''}
                  onChange={e => this.handleTextInputUpdate(e, 'givenName')}
                  value={givenName}
                />
              </label>
              <label>
                Last Name
                <input
                  placeholder="..."
                  required
                  className={showValidation && !familyName ? 'invalid' : ''}
                  onChange={e => this.handleTextInputUpdate(e, 'familyName')}
                  value={familyName}
                />
              </label>
            </div>

            {this.state.userId && (
              <React.Fragment>
                <div>
                  <label>
                    Account Locked
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        onChange={e =>
                          this.handleCheckInputUpdate(e, 'accountLocked')
                        }
                        checked={accountLocked}
                      />
                      <div className="toggle-icon"></div>
                    </div>
                  </label>
                  <label>
                    Send Welcome Email
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        onChange={e =>
                          this.handleCheckInputUpdate(e, 'sendWelcomeEmail')
                        }
                        checked={sendWelcomeEmail}
                      />
                      <div className="toggle-icon"></div>
                    </div>
                  </label>
                </div>
              </React.Fragment>
            )}
            {isSuperUser && (
              <React.Fragment>
                <div>
                  <label>
                    Is S.I.T.T. User
                    <div className={
                      showValidation &&
                            (!isPortalUser && !isSiteIdUser)
                        ? 'invalid toggle-wrapper'
                        : 'toggle-wrapper'
                    }>
                      <input
                        type="checkbox"
                        className="toggle"
                        onChange={e =>
                          this.handleCheckInputUpdate(e, 'isSiteIdUser')
                        }
                        checked={isSiteIdUser}
                      />
                      <div className="toggle-icon"></div>
                    </div>
                  </label>
                  <label>
                    Is Portal User
                    <div className={
                      showValidation &&
                            (!isPortalUser && !isSiteIdUser)
                        ? 'invalid toggle-wrapper'
                        : 'toggle-wrapper'
                    }>
                      <input
                        type="checkbox"
                        className="toggle"
                        onChange={e =>
                          this.handleCheckInputUpdate(e, 'isPortalUser')
                        }
                        checked={isPortalUser}
                      />
                      <div className="toggle-icon"></div>
                    </div>
                  </label>
                </div>
              </React.Fragment>
            )}
          </div>
          {this.state.userId && (
            <React.Fragment>
              <div>
                <h3>Contact Details</h3>
                <div className="user-field-block">
                  <label>
                    Job Title
                    <input
                      placeholder="..."
                      onChange={e => this.handleTextInputUpdate(e, 'jobTitle')}
                      value={jobTitle}
                    />
                  </label>
                  <label>
                    Business Phone
                    <input
                      type="tel"
                      placeholder="..."
                      onChange={e =>
                        this.handleTextInputUpdate(e, 'businessPhone')
                      }
                      value={businessPhone}
                    />
                  </label>
                  <label>
                    Mobile Phone
                    <input
                      type="tel"
                      placeholder="..."
                      onChange={e =>
                        this.handleTextInputUpdate(e, 'mobilePhone')
                      }
                      value={mobilePhone}
                    />
                  </label>
                </div>
                <div className="user-field-block">
                  <label>
                    Preferred Contact Method
                    <select
                      name="Preferred Contact Method"
                      placeholder="..."
                      onChange={e =>
                        this.handleTextInputUpdate(
                          e,
                          'preferredContactMethodId',
                        )
                      }
                      value={preferredContactMethodId}
                    >
                      {contactMethods.map(c => (
                        <option
                          key={c.preferredContactMethodId}
                          value={c.preferredContactMethodId}
                        >
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </label>                
                  <label>
                    Allow Email
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        onChange={e =>
                          this.handleCheckInputUpdate(e, 'allowEmail')
                        }
                        checked={allowEmail}
                      />
                      <div className="toggle-icon"></div>
                    </div>
                  </label>
                  <label>
                    Allow Phone
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        onChange={e =>
                          this.handleCheckInputUpdate(e, 'allowPhone')
                        }
                        checked={allowPhone}
                      />
                      <div className="toggle-icon"></div>
                    </div>
                  </label>
                </div>
                <h3>Address</h3>
                <div className="user-field-block">
                  <label>
                    Street 1
                    <input
                      placeholder="..."
                      onChange={e => this.handleTextInputUpdate(e, 'street1')}
                      value={street1}
                    />
                  </label>
                  <label>
                    Street 2
                    <input
                      placeholder="..."
                      onChange={e => this.handleTextInputUpdate(e, 'street2')}
                      value={street2}
                    />
                  </label>
                  <label>
                    Street 3
                    <input
                      placeholder="..."
                      onChange={e => this.handleTextInputUpdate(e, 'street3')}
                      value={street3}
                    />
                  </label>
                  <label>
                    City
                    <input
                      placeholder="..."
                      onChange={e => this.handleTextInputUpdate(e, 'city')}
                      value={city}
                    />
                  </label>
                  <label>
                    County
                    <input
                      placeholder="..."
                      onChange={e =>
                        this.handleTextInputUpdate(e, 'stateOrProvince')
                      }
                      value={stateOrProvince}
                    />
                  </label>
                  <label>
                    Postal Code
                    <input
                      placeholder="..."
                      onChange={e =>
                        this.handleTextInputUpdate(e, 'zipOrPostalCode')
                      }
                      value={zipOrPostalCode}
                    />
                  </label>
                  <label>
                    Country
                    <input
                      placeholder="..."
                      onChange={e =>
                        this.handleTextInputUpdate(e, 'countryOrRegion')
                      }
                      value={countryOrRegion}
                    />
                  </label>
                  {isAPAC && 
                  <label>
                    User Type
                    <select
                      name="User Type"
                      placeholder="..."
                      onChange={e =>
                        this.handleTextInputUpdate(
                          e,
                          'userTypeId',
                        )
                      }
                      value={userTypeId}
                    >
                      <option value=''>None</option>
                      {userTypes.map(c => (
                        <option
                          key={c.userTypeId}
                          value={c.userTypeId}
                        >
                          {c.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  }
                </div>
                <h3>Personal Notes</h3>
                <div className="user-field-block">
                  <label className="personal-notes">
                    &nbsp;
                    <textarea
                      rows="4"
                      cols="50"
                      placeholder="Personal Notes"
                      onChange={e =>
                        this.handleTextInputUpdate(e, 'personalNotes')
                      }
                      value={personalNotes}
                    ></textarea>
                  </label>
                </div>              
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  getStoreGroupsPanel() {
    let {
      storeGroups,
      storeGroupIds,
      customerExpanded,
      storeFilter,
      storeIds,
      stores,
    } = this.state;

    let filteredStores = stores.filter(
      s =>
        s.storeName.toLowerCase().indexOf(storeFilter.toLowerCase()) > -1 ||
        s.storeNumber.toLowerCase().indexOf(storeFilter.toLowerCase()) > -1,
    );

    let selectedStores = stores.filter(g => storeIds.indexOf(g.storeId) >= 0);
    let { tenantKey } = this.props;
    const isEMEA = tenantKey === 'EMEA';
    return (
      <div className="group-stores accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('customerExpanded')}
        >
          <h3>Customer Access Configuration </h3>
          <div
            className={`icon ${
              customerExpanded ? 'icon-expand' : 'icon-collapse'
            }`}
          ></div>
        </div>
        <div
          className={`accordion-body ${customerExpanded ? '' : 'collapsed'}`}
        >
          <div>
            <h4>Customer Groups</h4>
            {storeGroups.map(g => (
              <label key={g.storeGroupId}
                className="wrapping">
                <div className="toggle-wrapper">
                  <input
                    type="checkbox"
                    className="toggle"
                    checked={storeGroupIds.indexOf(g.storeGroupId) >= 0}
                    onChange={e =>
                      this.handleCheckInputListUpdate(
                        e,
                        'storeGroupIds',
                        g.storeGroupId,
                      )
                    }
                  />
                  <div className="toggle-icon"></div>
                </div>
                <span>{g.storeGroupName}</span>
              </label>
            ))}
          </div>
          <div>
            {isEMEA ? (
              <h4>Customer Stores</h4>
            ) : 
              <h4>Customers</h4>
            }
            <div className="horizontal-flex-even">
              <div>
                <h5>
                  All Customers (Filtering {filteredStores.length} of{' '}
                  {stores.length})
                </h5>
                <div className="floating-header">
                  <input
                    placeholder="Filter..."
                    value={storeFilter}
                    onChange={e => this.handleTextInputUpdate(e, 'storeFilter')}
                  />
                  <button
                    type="button"
                    className="action"
                    onClick={e => this.handleCheckInputListUpdateMultiple(e)}
                  >
                    Select All
                  </button>
                </div>
                {filteredStores.length > 250 && <h4>Too many results</h4>}
                {filteredStores.length <= 250 &&
                  filteredStores.map(g => (
                    <label key={g.storeId}
                      className="wrapping">
                      <div className="toggle-wrapper">
                        <input
                          type="checkbox"
                          className="toggle"
                          checked={storeIds.indexOf(g.storeId) >= 0}
                          onChange={e =>
                            this.handleCheckInputListUpdate(
                              e,
                              'storeIds',
                              g.storeId,
                            )
                          }
                        />
                        <div className="toggle-icon"></div>
                      </div>
                      <span>{g.storeName}</span>
                    </label>
                  ))}
              </div>
              <div>
                <h5>Selected Customers ({selectedStores.length})</h5>
                {selectedStores.map(g => (
                  <label key={g.storeId}
                    className="wrapping">
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={true}
                        onChange={e =>
                          this.handleCheckInputListUpdate(
                            e,
                            'storeIds',
                            g.storeId,
                          )
                        }
                      />
                      <div className="toggle-icon"></div>
                    </div>
                    <span>{g.storeName}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getCompaniesPanel() {
    let {
      companies,
      companiesIds,
      companiesExpanded,
      companyFilter,
    } = this.state;

    let filteredCompanies = companies.filter(
      s => s.name.toLowerCase().indexOf(companyFilter.toLowerCase()) > -1,
    );

    let selectedCompanies = companies.filter(
      g => companiesIds.indexOf(g.companyId) >= 0,
    );

    return (
      <div className="group-users accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('companiesExpanded')}
        >
          <h3>Company Access Configuration</h3>
          <div
            className={`icon ${
              companiesExpanded ? 'icon-expand' : 'icon-collapse'
            }`}
          ></div>
        </div>
        <div
          className={`accordion-body ${companiesExpanded ? '' : 'collapsed'}`}
        >
          <div className="horizontal-flex-even">
            <div>
              <h3>
                All Companies (Filtering {filteredCompanies.length} of{' '}
                {companies.length})
              </h3>
              <div className="floating-header">
                <input
                  placeholder="Filter..."
                  value={companyFilter}
                  onChange={e => this.handleTextInputUpdate(e, 'companyFilter')}
                />
                <button
                  type="button"
                  className="action"
                  onClick={e => this.handleCheckInputListCompanyUpdateMultiple(e)}
                >
                  Select All
                </button>
              </div>
              {filteredCompanies.length > 50 && <h4>Too many results</h4>}
              {filteredCompanies.length <= 50 &&
                filteredCompanies.map(g => (
                  <label key={g.companyId}
                    className="wrapping">
                    <div className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={companiesIds.indexOf(g.companyId) >= 0}
                        onChange={e =>
                          this.handleCheckInputListUpdate(
                            e,
                            'companiesIds',
                            g.companyId,
                          )
                        }
                      />
                      <div className="toggle-icon"></div>
                    </div>
                    <span>{g.name}</span>
                  </label>
                ))}
            </div>
            <div>
              <h3>Selected Companies ({selectedCompanies.length})</h3>
              {selectedCompanies.map(g => (
                <label key={g.companyId}
                  className="wrapping">
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      checked={true}
                      onChange={e =>
                        this.handleCheckInputListUpdate(
                          e,
                          'companiesIds',
                          g.companyId,
                        )
                      }
                    />
                    <div className="toggle-icon"></div>
                  </div>
                  <span>{g.name}</span>
                </label>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  getModuleGroupsPanel() {
    let { moduleGroups, moduleGroupIds, modulesExpanded } = this.state;

    return (
      <div className="group-stores accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('modulesExpanded')}
        >
          <h3>Instance Module Configuration</h3>
          <div
            className={`icon ${
              modulesExpanded ? 'icon-expand' : 'icon-collapse'
            }`}
          ></div>
        </div>
        <div className={`accordion-body ${modulesExpanded ? '' : 'collapsed'}`}>
          {moduleGroups.map(g => (
            <label key={g.moduleGroupId}
              className="wrapping">
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  checked={moduleGroupIds.indexOf(g.moduleGroupId) >= 0}
                  onChange={e =>
                    this.handleCheckInputListUpdate(
                      e,
                      'moduleGroupIds',
                      g.moduleGroupId,
                    )
                  }
                />
                <div className="toggle-icon"></div>
              </div>
              <span>{g.moduleGroupName}</span>
            </label>
          ))}
        </div>
      </div>
    );
  }

  getAccessRightsPanel() {
    let {
      storeGroups,
      storeGroupIds,
      moduleGroups,
      moduleGroupIds,
      rightsExpanded,
    } = this.state;

    return (
      <div className="group-stores accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('rightsExpanded')}
        >
          <h3>Access Rights</h3>
          <div
            className={`icon ${
              rightsExpanded ? 'icon-expand' : 'icon-collapse'
            }`}
          ></div>
        </div>
        <div className={`accordion-body ${rightsExpanded ? '' : 'collapsed'}`}>
          <p>A summary of the modules and stores linked to this user.</p>
          {moduleGroups
            .filter(g => moduleGroupIds.indexOf(g.moduleGroupId) > -1)
            .map(g => {
              return (
                <div key={g.moduleGroupId}>
                  <h4>From Access Group {g.moduleGroupName}</h4>
                  <h5>Modules</h5>
                  {g.moduleList.map(s => (
                    <div
                      key={s.moduleId}
                      className={`module ${
                        !s.parentModuleId ? 'top' : 'nested'
                      }`}
                    >
                      {s.moduleName}
                    </div>
                  ))}
                </div>
              );
            })}
          {storeGroups //TODO display accessible customers (singular)
            .filter(g => storeGroupIds.indexOf(g.storeGroupId) > -1)
            .map(g => {
              return (
                <div key={g.storeGroupId}>
                  <h4>From Customer Group {g.storeGroupName}</h4>
                  <h5>Customers</h5>
                  {g.storeList.map(s => (
                    <div key={s.storeId}>{s.storeName}</div>
                  ))}
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  render() {
    const { showDeleteModal, givenName, familyName } = this.state;
    const {
      userDeleting,
      userDeleted,
      userSaving,
      userSaved,
      errorMessage,
    } = this.props;
    return (
      <div className="edit-user-page page">
        <div className="floating-panel">
          <form noValidate
            onSubmit={e => this.handleSaveUser(e)}>
            <div className="floating-header">
              <div className="header-title">
                <div className="initials">{`${givenName &&
                  givenName[0]}${familyName && familyName[0]}`}</div>
                {this.state.userId ? (
                  <h2>Edit Customer User</h2>
                ) : (
                  <h2>Create Customer User</h2>
                )}
              </div>
            </div>

            {this.getUserPanel()}

            {this.state.userId && this.getModuleGroupsPanel()}
            {this.state.userId &&
              this.state.companies.length > 0 &&
              this.getCompaniesPanel()}
            {this.state.userId &&
              (this.state.storeGroups.length > 0 ||
                this.state.stores.length > 0) &&
              this.getStoreGroupsPanel()}
            {this.state.userId &&
              (this.state.storeGroups.length > 0 ||
                this.state.stores.length > 0) &&
              this.getAccessRightsPanel()}

            <p>{errorMessage}</p>
            <div className="button-container">
              <button
                className="action"
                disabled={userSaving}>
                {userSaving
                  ? 'Saving...'
                  : userSaved && !this.state.userId
                    ? 'Saved'
                    : 'Save'}
              </button>
              {this.state.userId && (
                <button
                  type="button"
                  className="action"
                  onClick={() => this.handleDeleteModalShow()}
                  disabled={userDeleting}
                >
                  {userDeleting
                    ? 'Deleting...'
                    : userDeleted
                      ? 'Deleted'
                      : 'Delete'}
                </button>
              )}
            </div>
          </form>
          {showDeleteModal && (
            <DeleteModal
              cancel={this.handleDeleteModalHide}
              continue={this.handleDeleteUser}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let { currentUser /*, groups*/ } = state.userManagement;
  return {
    userLoading: currentUser.isFetching,
    userLoaded: currentUser.isLoaded,
    userDetails: currentUser.userDetails,
    userSaving: currentUser.isSaving,
    userSaved: currentUser.isSaved,
    userDeleting: currentUser.isDeleted,
    userDeleted: currentUser.isDeleted,
    errorMessage: currentUser.errorMessage,
    tenantKey: state.auth.tenant,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUser: userId => dispatch(userManagementActions.requestUser(userId)),
    saveUser: userData => dispatch(userManagementActions.saveUser(userData)),
    deleteUser: userId => dispatch(userManagementActions.deleteUser(userId)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
