import React, { useState, useEffect, useRef } from 'react';
import CsrFilters from './csrFilters/csrFilters';
import CsrResultsTable from './csrResultsTable/csrResultsTable';
import CsrSummaryMetricsPanel from './csrSummaryMetricsPanel/csrSummaryMetricsPanel';

function CsrDashboard(){
  const googleMapRef = useRef(null);
  const [googleMap, setGoogleMap] = useState(null);
  const [destinatonPorts, setDestinationPorts] = useState(null);
  const [currentPort, setCurrentPort] = useState(null);
  const [filterOrders, setFilterOrders] = useState({
    orderDate : '',
    mbu: '',
    region: '',
    portOfDischarge: '',
    portOfLoading: '',
    rts: '',
    promiseDate: '',
    formRecieveDate: '',
    placeOfDelivery: '',
    etd: '',
    cob: '',
    eta: '',
    shipMode: '',
    source: '',
    promotion: 0,
    soNumber: '',
    poNumber: '',
    supplier: '',
    category: 0,
    eventCode: 0,
  });
  const [orders, setOrders] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [displayMode, setDisplay] = useState(1);
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    //get filters
    getFilters();

    let googleMapScript = document.getElementById('googleMapScript');

    const oldState = {
      filterOrders,
      page,
      pageSize,
    };

    if (!googleMapScript) {
      googleMapScript = document.createElement('script');
      googleMapScript.id = 'googleMapScript';
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${'AIzaSyABhW4kbWko-Gdz5FijPKR158kBAVGJ3uQ'}&libraries=visualization`;
      window.document.body.appendChild(googleMapScript);
    
      googleMapScript.addEventListener('load', () => {
        createGoogleMap(5.428857, -238.607194, 3, oldState);
      });
    } else {
      createGoogleMap(5.428857, -238.607194, 3, oldState);
    }
  },[googleMapRef]);

  const createGoogleMap = async(latitude, longitude, zoom, oldState) => {
    var map = new window.google.maps.Map(googleMapRef.current, {
      zoom,
      center: {
        lat: latitude,
        lng: longitude,
      },
      gestureHandling: 'greedy',
      disableDefaultUI: true,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
      },
    });
    setGoogleMap(map);

    //get destination ports
    let data = await handleFilter(oldState);

    setMarkers(data,map);
  };

  const setMarkers = (data, map) => {
    if (data && data.markers){
      data = data.markers;
      setDestinationPorts(data);

      const heatMapData = data.map(d => {
        return {
          location: new window.google.maps.LatLng(d.latitude, d.longitude),
          weight: d.orders?.totalRecords,
        };
      });

      var heatmap = new window.google.maps.visualization.HeatmapLayer({
        data: heatMapData,
        radius: 50,
      });

      heatmap.setMap(map);

      data.forEach(a => {
        var location = { lat: a.latitude, lng: a.longitude };
        var marker = new window.google.maps.Marker({
          position: location,
          icon: '/assets/map-icons/marker5.png',
          map : map,
        });

        marker.addListener('click', () => {
          map.setZoom(8);
          map.setCenter(marker.getPosition());

          setCurrentPort(a);
          setOrders(a.orders);
          setFilterOrders({  ...filterOrders, placeOfDelivery: a.name });
        });

        marker.setMap(map);
      });
    }
  };

  const getFilters = async() => {
    const response = await fetch('/api/csrshipment/v1/getfilters', {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    let data = await response.json();

    if (data && data.filters){
      data = data.filters;
      setFilters(data);
    }
  };

  const setDisplayMode = async(mode) =>{
    setDisplay(mode);
  };

  const handleFilterInputUpdate = async(e, fieldName) => {
    setFilterOrders({  ...filterOrders, [fieldName]: e.target.value });
  };

  const doFilter = async() =>{
    const oldState = {
      filterOrders,
      page,
      pageSize,
    };

    createGoogleMap(5.428857, -238.607194, 3, oldState);
  };

  const handleFilter = async(newState) => {    
    const filterString = `orderDate=${newState.filterOrders.orderDate}&mbu=${newState.filterOrders.mbu}&region=${newState.filterOrders.region}&portOfDischarge=${newState.filterOrders.portOfDischarge}
    &portOfLoading=${newState.filterOrders.portOfLoading}&placeOfDelivery=${newState.filterOrders.placeOfDelivery}&rts=${newState.filterOrders.rts}&promiseDate=${newState.filterOrders.promiseDate}
    &formRecieveDate=${newState.filterOrders.formRecieveDate}&etd=${newState.filterOrders.etd}&cob=${newState.filterOrders.cob}&eta=${newState.filterOrders.eta}
    &shipMode=${newState.filterOrders.shipMode}&source=${newState.filterOrders.source}&promotion=${newState.filterOrders.promotion}&soNumber=${newState.filterOrders.soNumber}&poNumber=${newState.filterOrders.poNumber}
    &supplier=${newState.filterOrders.supplier}&category=${newState.filterOrders.category}&eventCode=${newState.filterOrders.eventCode}`;
      
    window.history.pushState({}, '', `page=${newState.page}&${filterString}`);

    //get orders/pins and pass in filterOrders, page and pageSize
    const response = await fetch(`/api/csrshipment/v1/getCsrShipmentDetail?${filterString}&page=${newState.page}&pageSize=${newState.pageSize}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    });

    let data = await response.json();

    setFilterOrders(newState.filterOrders);
    setPageSize(newState.pageSize);
    setPage (newState.page);

    return data;
  };

  const handleFilterClear = async() => {
    const clearedState = {
      orderDate : '',
      mbu: '',
      region: '',
      portOfDischarge: '',
      portOfLoading: '',
      placeOfDelivery: '',
      rts: '',
      promiseDate: '',
      formRecieveDate: '',
      etd: '',
      cob: '',
      eta: '',
      shipMode: '',
      source: '',
      promotion: 0,
      soNumber: '',
      poNumber: '',
      supplier: '',
      category: 0,
      eventCode: 0,
    };

    setFilterOrders(clearedState);

    const newState = {
      filterOrders : clearedState,
      page: 1,
      pageSize,
    };

    setOrders([]);
    setCurrentPort(null);

    createGoogleMap(5.428857, -238.607194, 3, newState);
  };

  const doPaging = async(page) => {
    const newState = {
      filterOrders,
      pageSize,
      page : page,
    };

    setPage(page);

    let data = await handleFilter(newState);

    if (data && data.markers){
      data = data.markers;
      setOrders(data[0].orders);
    }
  };

  return <div className="csr-dashboard-page page">
    <div className="floating-panel">
      <div className="floating-header">
        <div className="header-title">
          <div className="icon icon-globe-pin"></div>
          <div>
            <h2>CSR Shipment Dashboard</h2>
          </div>
        </div>
      </div>
    </div>
    <div className='filter-map-row'>
      <CsrSummaryMetricsPanel
        currentPort={currentPort} />
      <div className='map-wrapper'>
        <button
          onClick={() => handleFilterClear()}
          type="button"
          className="action negative"
        >
            Clear Filter
        </button>
        <div
          id="google-map-csr"
          ref={googleMapRef} /> 
      </div>
    </div>
    <CsrFilters
      filters={filters}
      handleClear={handleFilterClear}
      doFilter={doFilter}
      handleFilterInputUpdate={handleFilterInputUpdate}
      filterOrders={filterOrders} />
    <CsrResultsTable
      doPaging={doPaging}
      setDisplayMode={setDisplayMode}
      displayMode={displayMode}
      orders={orders}
      page={page}
      pageSize={pageSize} />
  </div>;
}

export default CsrDashboard;
