/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

function DhlHandlingTab(props) {
  const formDetail = props.formDetail;

  useEffect(() => {
    calculateTotalHandling();
  }, [formDetail]);

  const calculateTotalHandling = () => {
    let calculation = 0;

    calculation = (Number(formDetail.dthc) + Number(formDetail.othc) + Number(formDetail.other) + Number(formDetail._3tonCrossBorderHk) + Number(formDetail._3tonShekouChiwan)
    + Number(formDetail._3tonYantian) + Number(formDetail._8tonCrossBorderHk) + Number(formDetail._8tonShekonChiwan) + Number(formDetail._8tonYantian) + Number(formDetail._10tonCrossBorderHk)
    + Number(formDetail._10tonShekouChiwan) + Number(formDetail._10tonYantian) + Number(formDetail._20crossBorderHk) + Number(formDetail._20shekouChiwan) + Number(formDetail._20shekouChiwanIndonesiaOnly) + Number(formDetail._20yantian)
    + Number(formDetail._40shekouChiwan) + Number(formDetail._40shekouChiwanIndonesiaOnly) + Number(formDetail._40yantian) + Number(formDetail._40yantian) + Number(formDetail._40gpHqCrossBorderHk) + Number(formDetail.cancellationOfEdi)
    + Number(formDetail.certificateOfOrigin) + Number(formDetail.containerInspectionFee) + Number(formDetail.customCleranceDeclaration) + Number(formDetail.customsTransfer) + Number(formDetail.documentFee) + Number(formDetail.ediDeclaration)
    + Number(formDetail.eoOrderHandlingCharges) + Number(formDetail.exportDeclarations) + Number(formDetail.exportLicense) + Number(formDetail.fumigationHandling) + Number(formDetail.gateChargesHongKong) + Number(formDetail.governmentPremium)
    + Number(formDetail.handlingOfCertificateOfOrigin) + Number(formDetail.importDeclarations) + Number(formDetail.importLicense) + Number(formDetail.ippcStamp) + Number(formDetail.lclCertificateOfOriginPreparation)
    + Number(formDetail.loadingUnloadingChargesHongKong) + Number(formDetail.othersFumigation) + Number(formDetail.overtimeCharges) + Number(formDetail.palletCharges) + Number(formDetail.portSecurity) + Number(formDetail.quarantineInspectionHandlingFee)
    + Number(formDetail.roadTollTsingMaBridge) + Number(formDetail.rocarFee) + Number(formDetail.securityFeeYtnShkPort20Gp) + + Number(formDetail.securityFeeYtnShkPort40Gp) + Number(formDetail.shenzhenRdcGateCharge) + Number(formDetail.tradeDeclarationServiceChargeTdec)
    + Number(formDetail.tunnelToll)
    ); 

    props.handleSetTotalHandling(calculation > 0 ? calculation.toFixed(2) : 0);
  };
  return (
    <>
      <div className="form-body">
        <label>
            Total Handling Cost (USD)
          <input
            disabled
            type='number'
            value={props.totalHandling}
          />
        </label>
      </div>
      <div className='form-body'>
        <label>
              DTHC (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'dthc')}
            type='number'
            value={formDetail.dthc}
          />
        </label>
        <label>
            OTHC (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othc')}
            type='number'
            value={formDetail.othc}
          />
        </label>
        <label>
             Other (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'other')}
            type='number'
            value={formDetail.other}
          />
        </label>
        <label>
             3-Ton (Cross Border HK) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_3tonCrossBorderHk')}
            type='number'
            value={formDetail._3tonCrossBorderHk}
          />
        </label>
        <label>
             3-Ton (Shekou/Chiwan) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_3tonShekouChiwan')}
            type='number'
            value={formDetail._3tonShekouChiwan}
          />
        </label>
        <label>
             3-Ton (Yantian) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_3tonYantian')}
            type='number'
            value={formDetail._3tonYantian}
          />
        </label>
        <label>
             8-Ton (Cross Border HK) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_8tonCrossBorderHk')}
            type='number'
            value={formDetail._8tonCrossBorderHk}
          />
        </label>
        <label>
             8-Ton (Shekou/Chiwan) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_8tonShekonChiwan')}
            type='number'
            value={formDetail._8tonShekonChiwan}
          />
        </label>
        <label>
             8-Ton (Yantian) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_8tonYantian')}
            type='number'
            value={formDetail._8tonYantian}
          />
        </label>
        <label>
            10-Ton (Cross Border HK) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_10tonCrossBorderHk')}
            type='number'
            value={formDetail._10tonCrossBorderHk}
          />
        </label>
        <label> 
            10-Ton (Shekou/Chiwan) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_10tonShekouChiwan')}
            type='number'
            value={formDetail._10tonShekouChiwan}
          />
        </label>
        <label>
             10-Ton (Yantian) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_10tonYantian')}
            type='number'
            value={formDetail._10tonYantian}
          />
        </label>
        <label>
             20&aspos; (Cross Border HK) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_20crossBorderHk')}
            type='number'
            value={formDetail._20crossBorderHk}
          />
        </label>
        <label>
             20&aspos; (Shekou/Chiwan) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_20shekouChiwan')}
            type='number'
            value={formDetail._20shekouChiwan}
          />
        </label>
        <label>
             20&aspos; (Shekou/Chiwan) 
          For Indonesia Only; Inclusive Additional Drop Off Point for Fumagation (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_20shekouChiwanIndonesiaOnly')}
            type='number'
            value={formDetail._20shekouChiwanIndonesiaOnly}
          />
        </label>
        <label>
             20&aspos; (Yantian) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_20yantian')}
            type='number'
            value={formDetail._20yantian}
          />
        </label>
        <label>
             40&aspos; (Shekou/Chiwan) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_40shekouChiwan')}
            type='number'
            value={formDetail._40shekouChiwan}
          />
        </label>
        <label>
             40&aspos; (Shekou/Chiwan) 
          For Indonesia Only; Inclusive Additional Drop Off Point for Fumagation (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_40shekouChiwanIndonesiaOnly')}
            type='number'
            value={formDetail._40shekouChiwanIndonesiaOnly}
          />
        </label>
        <label>
             40&aspos; (Yantian) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_40yantian')}
            type='number'
            value={formDetail._40yantian}
          />
        </label>
        <label>
             40&aspos; GP / HQ (Cross Border HK) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, '_40gpHqCrossBorderHk')}
            type='number'
            value={formDetail._40gpHqCrossBorderHk}
          />
        </label>
        <label>
             Cancellation of EDI (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'cancellationOfEdi')}
            type='number'
            value={formDetail.cancellationOfEdi}
          />
        </label>
        <label>
             Certificate of Origin (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'certificateOfOrigin')}
            type='number'
            value={formDetail.certificateOfOrigin}
          />
        </label>
        <label>
             Container Inspection Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'containerInspectionFee')}
            type='number'
            value={formDetail.containerInspectionFee}
          />
        </label>
        <label>
             Custom Clerance/Declaration (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'customCleranceDeclaration')}
            type='number'
            value={formDetail.customCleranceDeclaration}
          />
        </label>
        <label>
             Customs Transfer (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'customsTransfer')}
            type='number'
            value={formDetail.customsTransfer}
          />
        </label>
        <label>
             Document Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'documentFee')}
            type='number'
            value={formDetail.documentFee}
          />
        </label>
        <label>
             EDI Declaration (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'ediDeclaration')}
            type='number'
            value={formDetail.ediDeclaration}
          />
        </label>
        <label>
             EO Order Handling Charges (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'eoOrderHandlingCharges')}
            type='number'
            value={formDetail.eoOrderHandlingCharges}
          />
        </label>
        <label>
             Export Declarations (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'exportDeclarations')}
            type='number'
            value={formDetail.exportDeclarations}
          />
        </label>
        <label>
             Export License (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'exportLicense')}
            type='number'
            value={formDetail.exportLicense}
          />
        </label>
        <label>
             Fumigation Handling (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'fumigationHandling')}
            type='number'
            value={formDetail.fumigationHandling}
          />
        </label>
        <label>
             Gate Charges in Hong Kong (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'gateChargesHongKong')}
            type='number'
            value={formDetail.gateChargesHongKong}
          />
        </label>
        <label>
             Government Premium (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'governmentPremium')}
            type='number'
            value={formDetail.governmentPremium}
          />
        </label>
        <label>
             Handling of Certificate of Origin (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'handlingOfCertificateOfOrigin')}
            type='number'
            value={formDetail.handlingOfCertificateOfOrigin}
          />
        </label>
        <label>
             Import Declarations (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'importDeclarations')}
            type='number'
            value={formDetail.importDeclarations}
          />
        </label>
        <label>
             Import License (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'importLicense')}
            type='number'
            value={formDetail.importLicense}
          />
        </label>
        <label>
             IPPC Stamp (USD)
          <input 
            onChange={e => props.handleTextInputUpdate(e, 'ippcStamp')}
            type='number'
            value={formDetail.ippcStamp}
          />
        </label>
        <label>
             LCL: Certificate of Origin Preparation (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'lclCertificateOfOriginPreparation')}
            type='number'
            value={formDetail.lclCertificateOfOriginPreparation}
          />
        </label>
        <label>
             Loading/ Unloading Charges in Hong Kong (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'loadingUnloadingChargesHongKong')}
            type='number'
            value={formDetail.loadingUnloadingChargesHongKong}
          />
        </label>
        <label>
             Others - Fumigation (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'othersFumigation')}
            type='number'
            value={formDetail.othersFumigation}
          />
        </label>
        <label>
             Overtime Charges (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'bafOceanCost')}
            type='number'
            value={formDetail.overtimeCharges}
          />
        </label>
        <label>
             Pallet Charges (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'palletCharges')}
            type='number'
            value={formDetail.palletCharges}
          />
        </label>
        <label>
             Port Security (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'portSecurity')}
            type='number'
            value={formDetail.portSecurity}
          />
        </label>
        <label>
             Quarantine Inspection Handling Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'quarantineInspectionHandlingFee')}
            type='number'
            value={formDetail.quarantineInspectionHandlingFee}
          />
        </label>
        <label>
             Road Toll (Tsing Ma Bridge) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'roadTollTsingMaBridge')}
            type='number'
            value={formDetail.roadTollTsingMaBridge}
          />
        </label>
        <label>
             ROCAR Fee (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'rocarFee')}
            type='number'
            value={formDetail.rocarFee}
          />
        </label>
        <label>
             Security Fee - YTN/ SHK port 20GP (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'securityFeeYtnShkPort20Gp')}
            type='number'
            value={formDetail.securityFeeYtnShkPort20Gp}
          />
        </label>
        <label>
            Security Fee -YTN/ SHK port 40GP/HQ (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'securityFeeYtnShkPort40Gp')}
            type='number'
            value={formDetail.securityFeeYtnShkPort40Gp}
          />
        </label>
        <label>
             Shenzhen RDC Gate Charge 
            (Into OR Out of Warehouse) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'shenzhenRdcGateCharge')}
            type='number'
            value={formDetail.shenzhenRdcGateCharge}
          />
        </label>
        <label>
             Trade Declaration service charge (TDEC) (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'tradeDeclarationServiceChargeTdec')}
            type='number'
            value={formDetail.tradeDeclarationServiceChargeTdec}
          />
        </label>
        <label>
             Tunnel Toll  (USD)
          <input
            onChange={e => props.handleTextInputUpdate(e, 'tunnelToll')}
            type='number'
            value={formDetail.tunnelToll}
          />
        </label>
      </div>
    </>
  );
}

export default DhlHandlingTab;
