import {
  applyMiddleware, combineReducers, compose, createStore,
} from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import authReducer from '../reducers/authReducer';
import moduleReducer from '../reducers/moduleReducer';
import userManagementReducer from '../reducers/userManagementReducer';
import httpMiddleware from '../middlewares/httpMiddleware';
import tokenMiddleware from '../middlewares/tokenMiddleware';

import navigationReducer from '../reducers/navigationReducer';
import navigationMiddleware from '../middlewares/navigationMiddleware';
import notificationReducer from '../reducers/notificationReducer';
import notificationMiddleware from '../middlewares/notificationMiddleware';
import feedbackReducer from '../reducers/feedbackReducer';
import myDocumentsReducer from '../reducers/myDocumentsReducer';
import documentManagementReducer from '../reducers/documentsManagementReducer';
import overdueInvoiceReducer from '../reducers/overdueInvoiceReducer';
import rslPerformanceReducer from '../reducers/rslPerformanceReducer';
import itemCatalogueReducer from '../reducers/itemCatalogueReducer';
import itemPricesReducer from '../reducers/itemPricesReducer';
import bookingFormReducer from '../reducers/bookingFormReducer';
import vasReducer from '../reducers/vasReducer';
import masterFileReducer from '../reducers/masterFileReducer';
import customerRequirementsReducer from '../reducers/customerRequirementsReducer';
import customerRequirementsOdsReducer from '../reducers/customerRequirementsOdsReducer';
import forecastChangeRequestReducer from '../reducers/forecastChangeRequestReducer';
import sideBarReducer from '../reducers/sideBarReducer';
import { marketingReducer } from '../reducers/marketingReducer';
import platformUsageReducer from '../reducers/platformUsageReducer';
import { signalRInvokeMiddleware } from '../middlewares/signalRMiddleware';
import messageReducer from '../reducers/messageReducer';
import messageArchiveReducer from '../reducers/messageArchiveReducer';
import siteIDApplicationDetailReducer from '../reducers/siteIDApplicationDetailReducer';
import siteIDApplicationsReducer from '../reducers/siteIDApplicationsReducer';
import siteIDInactiveApplicationsReducer from '../reducers/siteIDInactiveApplicationsReducer';
import SiteIDEquitytoreReducer from '../reducers/siteIDEquityStoreReducer';
import SiteIDOpenStoreReducer from '../reducers/siteIDOpenStoreReducer';
import siteIDCompanyReducer from '../reducers/siteIDCompanyReducer';
import SiteIDReportingReducer from '../reducers/siteIDReportingReducer';
import SiteIDDriveThroughReducer from '../reducers/siteIDDriveThroughReducer';
import OrderTrackerReducer from '../reducers/orderTrackerReducer';
import ApacOrderTrackerReducer from '../reducers/apacOrderTrackerReducer';
import ApacOrderDocumentReducer from '../reducers/apacOrderDocumentReducer';
import ConnectConfigurationRouter from '../reducers/connectConfigurationReducer';
import ConnectContentManagementReducer from '../reducers/connectContentManagementReducer';
import ConnectAssetManagementReducer from '../reducers/connectAssetManagementReducer';
import ConnectContentReducer from '../reducers/connectContentReducer';
import ConnectLabelReducer from '../reducers/connectLabelReducer';
import ConnectSideBarReducer from '../reducers/connectSideBarReducer';
import { connectTaskReducer } from '../reducers/connectTaskReducer';
import connectTaskMiddleware from '../middlewares/connectTaskMiddleware';
import ConnectWeatherReducer from '../reducers/connectWeatherReducer';
import ConnectEventsReducer from '../reducers/connectEventsReducer';
import ConnectReportingReducer from '../reducers/connectReportingReducer';
import kpiDashboardReducer from '../reducers/kpiDashboardReducer';
import siteIDDtmpFileReducer from '../reducers/siteIDDtmpFileReducer';
import SiteIDDefaultResponseRouter from '../reducers/siteIDDefaultResponseReducer';
import PosItemNumberRequestReducer from '../reducers/posItemNumberRequestReducer';
import PosItemCatalogueReducer from '../reducers/posItemCatalogueReducer';
import PosItemNumberRequestAdminReducer from '../reducers/posItemNumberRequestAdminReducer';
import PosMdioReducer from '../reducers/posMdioReducer';
import PosItemEmailConfigReducer from '../reducers/posItemEmailConfigReducer';
import bulkApplicationReducer from '../reducers/bulkApplicationReducer';
import emailDistributionListReducer from '../reducers/emailDistributionListReducer';
import ChatbotReducer from '../reducers/chatbotReducer';
import emailDocumentManagementReducer from '../reducers/emailDocumentManagementReducer';

export default function configureStore(history) {
  const reducers = {
    auth: authReducer,
    module: moduleReducer,
    userManagement: userManagementReducer,
    notification: notificationReducer,
    feedback: feedbackReducer,
    myDocuments: myDocumentsReducer,
    documentManagement: documentManagementReducer,
    overdueInvoices: overdueInvoiceReducer,
    rslPerformance: rslPerformanceReducer,
    itemCatalogue: itemCatalogueReducer,
    itemPrices: itemPricesReducer,
    bookingForm: bookingFormReducer,
    vas: vasReducer,
    masterFile: masterFileReducer,
    customerRequirements: customerRequirementsReducer,
    customerRequirementsOds: customerRequirementsOdsReducer,
    forecastChangeRequest: forecastChangeRequestReducer,
    navigation: navigationReducer,
    sideBar: sideBarReducer,
    marketing: marketingReducer,
    platformUsage: platformUsageReducer,
    message: messageReducer,
    messageArchive: messageArchiveReducer,
    siteIDApplications: siteIDApplicationsReducer,
    siteIDInactiveApplications: siteIDInactiveApplicationsReducer,
    siteIDApplicationDetail: siteIDApplicationDetailReducer,
    siteIDEquityStores: SiteIDEquitytoreReducer,
    siteIDOpenStores: SiteIDOpenStoreReducer,
    siteIDCompanies: siteIDCompanyReducer,
    siteIDReporting: SiteIDReportingReducer,
    siteIDDtmp: siteIDDtmpFileReducer,
    siteIDDriveThrough: SiteIDDriveThroughReducer,
    siteIDDefaultResponses: SiteIDDefaultResponseRouter,
    orderTracker: OrderTrackerReducer,
    apacOrderTracker: ApacOrderTrackerReducer,
    apacOrderDocument: ApacOrderDocumentReducer,
    kpiDashboard: kpiDashboardReducer,
    connectConfiguration: ConnectConfigurationRouter,
    connectContentManagement: ConnectContentManagementReducer,
    connectAssetManagement: ConnectAssetManagementReducer,
    connectContent: ConnectContentReducer,
    connectLabel: ConnectLabelReducer,
    connectSideBar: ConnectSideBarReducer,
    connectTask: connectTaskReducer,
    connectWeather: ConnectWeatherReducer,
    connectEvents: ConnectEventsReducer,
    connectReporting: ConnectReportingReducer,
    lsPosItemNumber: PosItemNumberRequestReducer,
    lsPosItemCatalogue: PosItemCatalogueReducer,
    lsPosItemNumberAdmin: PosItemNumberRequestAdminReducer,
    lsPosMdio: PosMdioReducer,
    PosItemEmailConfig: PosItemEmailConfigReducer,
    emailDistributionList : emailDistributionListReducer,
    bulkApplication: bulkApplicationReducer,
    chatbot: ChatbotReducer,
    emailDocumentManagement: emailDocumentManagementReducer,
    router: connectRouter(history),
  };

  const middleware = [
    thunk,
    httpMiddleware,
    notificationMiddleware,
    tokenMiddleware,
    navigationMiddleware,
    connectTaskMiddleware,
    signalRInvokeMiddleware,
    routerMiddleware(history),
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const enhancers = [];
  const isDevelopment = process.env.NODE_ENV === 'development';
  if (
    isDevelopment
    && typeof window !== 'undefined'
    && window.devToolsExtension
  ) {
    enhancers.push(
      window.__REDUX_DEVTOOLS_EXTENSION__
      && window.__REDUX_DEVTOOLS_EXTENSION__(),
    );
  }

  return createStore(
    combineReducers(reducers),
    compose(applyMiddleware(...middleware), ...enhancers),
  );
}
