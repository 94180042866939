import React from 'react';

function CsrSummaryMetricsPanel(props){
  return <div className='csr-summary-dashboard'>
    <div className='summary-line'>
      <label>
      Destination Port 
        <div className='dash-item'>
          <h2>{props.currentPort?.name ?? 'None'}</h2>
        </div>
      </label>
      <label>
      Total No of Shipment
        <div className='dash-item'>
          <h2>{props.currentPort?.shipmentCount ?? 0}</h2>
        </div>
      </label>
    </div>
    <div className='summary-line'>
      <label>
      No of Shipments Delayed 
        <div className='dash-item'>
          <h2>{props.currentPort?.shipmentsDelayedCount ?? 0}</h2>
        </div>
      </label>
      <label>
      % of Shipments Delayed 
        <div className='dash-item'>
          <h2>{props.currentPort && props.currentPort.shipmentsDelayedCount && props.currentPort.shipmentCount ? ((props.currentPort.shipmentsDelayedCount/props.currentPort.shipmentCount) * 100).toString().substring(0,4) + '%' : '0%'}</h2>
        </div>
      </label>
    </div>
    <div className='summary-line'>
      <label>
      No of Shipments On Time 
        <div className='dash-item'>
          <h2>{props.currentPort?.shipmentsOnTimeCount ?? 0}</h2>
        </div>
      </label>
      <label>
      % of Shipments On Time 
        <div className='dash-item'>
          <h2>{props.currentPort && props.currentPort.shipmentsOnTimeCount && props.currentPort.shipmentCount ? ((props.currentPort.shipmentsOnTimeCount/props.currentPort.shipmentCount) * 100).toString().substring(0,4) + '%' : '0%' }</h2>
        </div>
      </label>
    </div>
  </div>;
}

export default CsrSummaryMetricsPanel;