import React, {useState, useEffect} from 'react';
import './OrderAudit.scss';
const ReactMarkdown = require('react-markdown');

function OrderAudit(props) {
 const [page, setPage]  = useState(1);
 const [data, setData] = useState([]);


  useEffect(() => {

    if(!props.trackingNumber) return;

    async function loadFormData(){
      const response = await fetch(`/api/OmOrder/v1/getOrderAudit?trackingNumber=${props.trackingNumber}&page=${page}&pageSize=25`, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
      });

      if (response.status == 200){
        const stuff = await response.json();
        setData(stuff.data);
      }
    }
    loadFormData();

  }
  , [props.trackingNumber])


  return (
    <div className='form-container audit-history'>
      <h4 className="header-order">Audit History</h4>
      <div className="form-body">
        {data.map((d) => (<div key={d}>
          <h5 className="section-header"><span>{d.triggeredBy}</span> <span>{new Date(d.auditDate + 'Z').toLocaleString()}</span></h5>
          <ReactMarkdown source={d.description}
                skipHtml={true}
                className="content-preview"
                linkTarget={'_blank'} />
        </div>))}
      </div>
    </div>
  )
}



export default OrderAudit;
