import React, {useState, useEffect} from 'react';
import helpers from '../helpers/applicationHelpers';

function ExtendedFields({applicationId, isDirtyFunc, triggerSave}){
    const [application, setApplication] = useState({});
    const [leases, setLeases] = useState([]);
    const [planningGrantTypes, setPlanningGrantTypes] = useState([]);
    const [isDirty, setIsDirty] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        loadApplicationDetails();
    }, []);

    useEffect(() => {
        if(triggerSave === true && isDirty === true){
            saveApplicationDetails();
        }
    }, [triggerSave]);
    
    useEffect(() => {
        if(isDirty === true){
            isDirtyFunc(applicationId);
        }
    }, [isDirty]);

    const loadApplicationDetails = async () => {
        setIsLoading(true);
        const defaultHeaders = { 'Content-Type': 'application/json' };
        const token = localStorage.getItem('token');

        if (token) {
            defaultHeaders.Authorization = `Bearer ${token}`;
        }

        const fetchOptions = {
            method: 'GET',
            headers: { ...defaultHeaders },
        };

        const response = await fetch(`api/SiteID/ApplicationExtendedDetail/v1/Get/${applicationId}`, fetchOptions);
        const extendedFields = await response.json();
        
        setApplication({
            ...extendedFields.application,
            plannedSchematic: extendedFields.application.plannedSchematic?.split("T")[0],
            latestEstimatedOpeningDate: extendedFields.application.latestEstimatedOpeningDate?.split("T")[0],
            planningSubmitted: extendedFields.application.planningSubmitted?.split("T")[0],
            estimatedOpeningDate: extendedFields.application.estimatedOpeningDate?.split("T")[0]
        });
        setLeases(extendedFields.leases);
        setPlanningGrantTypes(extendedFields.planningGrantTypes);
        setIsDirty(false);
        setIsLoading(false);
    };

    const saveApplicationDetails = async () => {
        setIsLoading(true);
        const defaultHeaders = { 'Content-Type': 'application/json' };
        const token = localStorage.getItem('token');

        if (token) {
            defaultHeaders.Authorization = `Bearer ${token}`;
        }

        const fetchOptions = {
            method: 'POST',
            headers: { ...defaultHeaders },
            body: JSON.stringify(application)
        };

        const response = await fetch(`api/SiteID/ApplicationExtendedDetail/v1/Save`, fetchOptions);
        const updatedApplication = await response.json();
        
        
        setApplication({
            ...updatedApplication,
            plannedSchematic: updatedApplication.plannedSchematic?.split("T")[0],
            latestEstimatedOpeningDate: updatedApplication.latestEstimatedOpeningDate?.split("T")[0],
            planningSubmitted: updatedApplication.planningSubmitted?.split("T")[0],
            estimatedOpeningDate: updatedApplication.estimatedOpeningDate?.split("T")[0]
        });
        setIsDirty(false);
        setIsLoading(false);
    };

    const handleCheckUpdate = async(event, fieldName) =>{
        setApplication({
            ...application,
            [fieldName]: event.target.checked
        });

        setIsDirty(true);
    };

    const handleDateUpdate = async(event, fieldName) =>{

        let newState = {
            ...application,
            [fieldName]: event.target.value
        };
        
        if(fieldName === 'latestEstimatedOpeningDate'){

            var date = new Date(event.target.value);

            if(application.formatId == 1 || application.formatId == 5 || application.formatId == 6)
            {
                date = new Date(date.setDate(date.getDate() - (27.5 * 7))).toISOString();
                newState.plannedSchematic = date.split("T")[0];
            }
            else{
                date = new Date(date.setDate(date.getDate() - (23 * 7))).toISOString();
                newState.plannedSchematic = date.split("T")[0];
            }
        }

        setApplication(newState);
        
        setIsDirty(true);
    };
    
    const handleValueUpdate = async(event, fieldName) =>{
        setApplication({
            ...application,
            [fieldName]: event.target.value
        });

        setIsDirty(true);
    };

    if(isLoading){
        return <td colSpan={8}>Loading...</td>;
    }
        
    const locked = !helpers.canAttemptSave(application.statusId, application.isLicensee, false);
    const existsOnSentrk = application && (application.sentrkLinked || application.statusId > 5);

    if(locked){
        return (
        <>
            <td>{application.estimatedOpeningDate}</td> 
            <td>{application.headsOfTermsAgreed}</td> 
            <td>{leases && leases.filter(l => l.key == application.leaseSignedOrSitePurchasedId).map(l => l.value)}</td> 
            <td>{application.planningRequired}</td> 
            <td>{application.planningSubmitted}</td>
            <td>
               {planningGrantTypes && planningGrantTypes.filter(l => l.key == application.planningGrantedId).map(l => l.value)}
             </td>
            <td>
               {application.latestEstimatedOpeningDate}  
            </td>
            <td>{application.plannedSchematic}</td> 
    </>);
    }

    const isRecApproved = application.statusId === 7;
    let leaseSignedPurchaseClass = '';
    let planningGrantedClass = '';
    let planningSubmittedClass = '';
    let plannedOpeningDateClass = '';

    if(isRecApproved){
        let date = new Date();
        let threeMonthsAgo = date.setMonth(date.getMonth() - 3);

        if(!application.leaseSignedOrSitePurchasedId){
            leaseSignedPurchaseClass = 'red';
        }else if(application.leaseSignedOrSitePurchasedId != 1 && //Not exchanged
                 (!application.leaseSignedOrPurchasedLastEdit ||
                  new Date(application.leaseSignedOrPurchasedLastEdit) < threeMonthsAgo)){
            leaseSignedPurchaseClass = 'yellow';
        }

        if(application.planningRequired){
            if(!application.planningGrantedId){
                planningGrantedClass = 'red';
            }else if(application.planningGrantedId != 1 && //Not Granted
                 (!application.planningGrantedLastEdit ||
                  new Date(application.planningGrantedLastEdit) < threeMonthsAgo)){
                planningGrantedClass = 'yellow';
            }

            if(!application.planningSubmitted){
                planningSubmittedClass = 'red';
            }
        }

        if(application.projectStatus == 'REC Approved' || application.projectStatus == 'On Hold'){
            if(!application.latestEstimatedOpeningDate){
                plannedOpeningDateClass = 'yellow';
            }else if(!application.openingDateLastEdit ||
                    new Date(application.openingDateLastEdit) < threeMonthsAgo){
                plannedOpeningDateClass = 'yellow';
            }
        }
    }

    return (<>
            <td>
                <input value={application.estimatedOpeningDate} type="date" readOnly={existsOnSentrk} 
                onChange={(e) => handleDateUpdate(e, 'estimatedOpeningDate')}/>
            
            </td>
            <td>
            <label className="inline-checkbox">
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  readOnly={application.statusId < 2}
                  onChange={(e) => handleCheckUpdate(e, 'headsOfTermsAgreed')}
                  checked={application.headsOfTermsAgreed}
                />
                <div className="toggle-icon" />
              </div>
              </label>
            
            </td>

            <td className={leaseSignedPurchaseClass} title={leaseSignedPurchaseClass != '' ? "The lease/purchase status needs populating and updating every 3 months." : ""}>
                <select value={application.leaseSignedOrSitePurchasedId} onChange={(e) => handleValueUpdate(e, 'leaseSignedOrSitePurchasedId')}>
                    <option></option>
                    {leases && leases.map(l =>
                        <option key={l.key} value={l.key}>{l.value}</option>
                        )}
                </select>
            
            </td>
            <td>
            
            <label className="inline-checkbox">
              <div className="toggle-wrapper">
                <input
                  type="checkbox"
                  className="toggle"
                  onChange={(e) => handleCheckUpdate(e, 'planningRequired')}
                  checked={application.planningRequired}
                />
                <div className="toggle-icon" />
              </div>
              </label>
            
            </td>
            <td className={planningSubmittedClass} title={planningSubmittedClass != '' ? "The planning submitted date needs populating." : ""}>
                {application.planningRequired ?
                (<input value={application.planningSubmitted} type="date" onChange={(e) => handleDateUpdate(e, 'planningSubmitted')}/>):null}
                </td>
            <td className={planningGrantedClass} title={planningGrantedClass != '' ? "The planning granted information needs populating and updating every 3 months." : ""}>
                <select value={application.planningGrantedId} onChange={(e) => handleValueUpdate(e, 'planningGrantedId')}>
                    <option></option>
                        {planningGrantTypes && planningGrantTypes.map(l =>
                            <option key={l.key} value={l.key}>{l.value}</option>
                        )}
                </select>
             </td>
            <td className={plannedOpeningDateClass} title={plannedOpeningDateClass != '' ? "The planned opening date needs populating and updating every 3 months." : ""}>
                <input value={application.latestEstimatedOpeningDate} readOnly={(application.projectStatus != 'REC Approved' && application.projectStatus != 'On Hold')} type="date" onChange={(e) => handleDateUpdate(e, 'latestEstimatedOpeningDate')}/>
            </td>
            <td>
                <input value={application.plannedSchematic} readOnly={(application.projectStatus != 'REC Approved' && application.projectStatus != 'On Hold')}  type="date" onChange={(e) => handleDateUpdate(e, 'plannedSchematic')}/></td>
    </>);
}

export default ExtendedFields;