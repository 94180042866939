import statuses from './applicationStatusEnum';
import projectTypes from './applicationProjectTypeEnum';

const ApplicationHelpers = {
  initialiseState: (applicationDetail = {},companiesDetail=[], companyUsersDetail=[]) => {
    const newState = {
      applicationId: applicationDetail.applicationId,
      // Draft -> To Be identified
      companyId: applicationDetail.companyId,
      siteName: applicationDetail.siteName,
      siteTypeId: applicationDetail.siteTypeId,
      formatId: applicationDetail.formatId,
      netSquareFeet: applicationDetail.netSquareFeet,
      grossSquareFeet: applicationDetail.grossSquareFeet,
      developer:
        applicationDetail.developer === null
          ? undefined
          : applicationDetail.developer,
      primaryContactId: applicationDetail.primaryContactId,
      secondaryContactid: applicationDetail.secondaryContactid,
      street1: applicationDetail.street1,
      street2: applicationDetail.street2,
      street3: applicationDetail.street3,
      city: applicationDetail.city,
      countyId: applicationDetail.countyId,
      country: applicationDetail.country,
      postCode: applicationDetail.postCode,
      latitude: applicationDetail.latitude,
      longitude: applicationDetail.longitude,
      plannedClosingDate: applicationDetail.plannedClosingDate,
      summarySSPExpected: applicationDetail.summarySSPExpected,
      segment: applicationDetail.segment,
      projectType: applicationDetail.projectType,
      projectTypeId: applicationDetail.projectTypeId || projectTypes.newStore,


      // To Be identified -> Identified
      // expiration date
      // Identified -> To be registered
      finalDeveloper:
        applicationDetail.finalDeveloper === null
          ? undefined
          : applicationDetail.finalDeveloper,
      // To be registered -> Registered
      // expiration date
      // Registered -> To be Approved
      siteReferenceNo: applicationDetail.siteReferenceNo,
      sitePositionId: applicationDetail.sitePositionId,
      primaryBusinessGeneratorId: applicationDetail.primaryBusinessGeneratorId,
      estimatedOpeningDate: applicationDetail.estimatedOpeningDate,
      conditionalApprovalNotes: applicationDetail.conditionalApprovalNotes,
      casework: applicationDetail.casework,
      adt: applicationDetail.adt,
      adt3: applicationDetail.adt3,
      adt5: applicationDetail.adt5,
      at: applicationDetail.at,
      at3: applicationDetail.at3,
      at5: applicationDetail.at5,
      aws: applicationDetail.aws,
      aws3: applicationDetail.aws3,
      aws5: applicationDetail.aws5,
      initialInventory: applicationDetail.initialInventory,
      operatingDays: applicationDetail.operatingDays,
      totalSalesYear: applicationDetail.totalSalesYear,
      totalStoreInvestment: applicationDetail.totalStoreInvestment,
      patioSeats: applicationDetail.patioSeats,
      cafeSeats: applicationDetail.cafeSeats,
      carParkingSpaces: applicationDetail.carParkingSpaces,
      rent: applicationDetail.rent,
      initialLeaseTermYears: applicationDetail.initialLeaseTermYears,
      renewalOrBreakYears: applicationDetail.renewalOrBreakYears,
      breakEven: applicationDetail.breakEven,
      year1Roi: applicationDetail.year1Roi,
      investmentPaybackYears: applicationDetail.investmentPaybackYears,
      storeType: applicationDetail.storeType,
      plannedSchematic: applicationDetail.plannedSchematic,

      // To be approved -> Approved
      latestEstimatedOpeningDate: applicationDetail.latestEstimatedOpeningDate,

      // Open

      // SiteID Admin Team
      expirationDate: applicationDetail.expirationDate,
      dateSentToEmea: applicationDetail.dateSentToEmea,
      statusId: applicationDetail.statusId || statuses.draft,

      // Rejected
      notApprovedForSiteId: applicationDetail.notApprovedForSiteId,

      // In need of ordering
      headsOfTermsAgreed: applicationDetail.headsOfTermsAgreed,
      headsOfTermsComments: applicationDetail.headsOfTermsComments,
      leaseOrPurchaseComments: applicationDetail.leaseOrPurchaseComments,
      leaseSignedOrSitePurchased: applicationDetail.leaseSignedOrSitePurchased,
      leaseSignedOrSitePurchasedId: applicationDetail.leaseSignedOrSitePurchasedId,
      planningComments: applicationDetail.planningComments,
      planningGrantedId: applicationDetail.planningGrantedId,
      planningGranted: applicationDetail.planningGranted,
      planningRequired: applicationDetail.planningRequired,
      planningSubmitted: applicationDetail.planningSubmitted,
      practicalCompletion: applicationDetail.practicalCompletion,
      projectNo: applicationDetail.projectNo,
      equipmentProduction: applicationDetail.equipmentProduction,
      equipmentTechnology: applicationDetail.equipmentTechnology,
      lighting: applicationDetail.lighting,
      furnishings: applicationDetail.furnishings,
      artwork: applicationDetail.artwork,
      signage: applicationDetail.signage,
      otherProFees: applicationDetail.otherProFees,
      approvalStatus: applicationDetail.approvalStatus,
      buildingAndSiteImprovements: applicationDetail.buildingAndSiteImprovements,
      leaseholdImprovements: applicationDetail.leaseholdImprovements,
      nonRefundableKeyMoney: applicationDetail.nonRefundableKeyMoney,
      overhead: applicationDetail.overhead,
      designFeesInitialInventory: applicationDetail.designFeesInitialInventory,
      totalStoreCosts : applicationDetail.totalStoreCosts,
      y1TotalRent: applicationDetail.y1TotalRent,
      y3TotalRent: applicationDetail.y3TotalRent,
      y5TotalRent: applicationDetail.y5TotalRent,
      y1RealEstateTaxes: applicationDetail.y1RealEstateTaxes,
      y3RealEstateTaxes: applicationDetail.y3RealEstateTaxes,
      y5RealEstateTaxes: applicationDetail.y5RealEstateTaxes,
      totalYearsStarbucksControlledLeaseExtOptions: applicationDetail.totalYearsStarbucksControlledLeaseExtOptions,
      totalLeaseTermYrs: applicationDetail.totalLeaseTermYrs,
      initialLeaseExp: applicationDetail.initialLeaseExp,
      longstopDate: applicationDetail.longstopDate,
      recApprovalDate: applicationDetail.recApprovalDate,
      approvedFinalResponse: applicationDetail.approvedFinalResponse,
      submissionStatus: applicationDetail.submissionStatus,
      approvedFinalConditionNotes: applicationDetail.approvedFinalConditionNotes,
      landlord: applicationDetail.landlord,
      sentrkSiteID: applicationDetail.sentrkSiteID,
      shellConstructionStarted: applicationDetail.shellConstructionStarted,
      storeNo: applicationDetail.storeNo,
      tenureTypeId: applicationDetail.tenureTypeId,


      recapprovalDate: applicationDetail.recapprovalDate,
      longStopDate: applicationDetail.longStopDate,
      otherProfessionalFees: applicationDetail.otherProfessionalFees,
      y1totalRent: applicationDetail.y1totalRent,
      y3totalRent1: applicationDetail.y3totalRent1,
      y5totalRent1: applicationDetail.y5totalRent1,
      y1realEstateTaxes: applicationDetail.y1realEstateTaxes,
      y3realEstateTaxes1: applicationDetail.y3realEstateTaxes1,
      y5realEstateTaxes1: applicationDetail.y5realEstateTaxes1,
      totalLeaseTermYears: applicationDetail.totalLeaseTermYears,
      initialLeaseExpiration: applicationDetail.initialLeaseExpiration,
      designConstructionFees: applicationDetail.designConstructionFees,

      sentrkAT: applicationDetail.sentrkAT,
      sentrkADT: applicationDetail.sentrkADT,
      sentrkAWS: applicationDetail.sentrkAWS,


      // other
      expiringSoon: applicationDetail.expiringSoon,

      // Lists
      availableStatusIds: applicationDetail.availableStatusIds || [],

      countyEditable: undefined,
      showValidation: undefined,

      // Modals
      showStatusChangeModal: false,
      showExtensionRequestModal: false,
      showExtensionResponseModal: false,

      // Tab
      currentTab: 'application',
      isDirty: false,
    };

    if (companiesDetail.length === 1 && !newState.companyId) {
      newState.companyId = companiesDetail[0].key;
    }

    if (companyUsersDetail.length === 1 && !newState.primaryContactId) {
      newState.primaryContactId = companyUsersDetail[0].userId;
    }

    return newState;
  },
  canAttemptSave: (statusId, isLicensee, isReadOnly) => {
    return !isReadOnly && (
      (isLicensee
        && [
          statuses.draft,
          statuses.identified,
          statuses.registered,
          statuses.approved,
        ].indexOf(statusId) > -1)
      || !isLicensee
    );
  },
  percCompleteToProgress(currentStatus, fields) {
    const {
      companyId,
      siteName,
      formatId,
      tenureTypeId,
      grossSquareFeet,
      primaryBusinessGeneratorId,
      estimatedOpeningDate,
      primaryContactId,
      street1,
      city,
      countyId,
      postCode,
      latitude,
      longitude,
      projectTypeId,
      storeNo,
      plannedClosingDate,
      siteTypeId,
    } = fields;

    let currentScore = 0;
    let maxScore = 0;

    if(currentStatus  >= statuses.draft){
      maxScore = 15;

      currentScore += siteName ? 1 : 0;
      currentScore += companyId ? 1 : 0;
      currentScore += primaryContactId ? 1 : 0;
      currentScore += street1  ? 1 : 0;
      currentScore += city ? 1 : 0;
      currentScore += countyId ? 1 : 0;
      currentScore += postCode ? 1 : 0;
      currentScore += latitude ? 1 : 0;
      currentScore += longitude ? 1 : 0;
      currentScore += siteTypeId ? 1 : 0;

      currentScore += primaryBusinessGeneratorId ? 1 : 0;
      currentScore += formatId ? 1 : 0;
      currentScore += (grossSquareFeet && parseInt(grossSquareFeet) >= 11) ? 1 : 0;
      currentScore += tenureTypeId ? 1 : 0;

      currentScore += estimatedOpeningDate ? 1 : 0;

      if (projectTypeId) {
        maxScore += 1;
        currentScore += projectTypeId ? 1 : 0;

        if (projectTypeId.toString() === projectTypes.relocation.toString()) {
          maxScore += 2;
          currentScore += plannedClosingDate ? 1 : 0;
          currentScore += storeNo ? 1 : 0;
        }

        if (projectTypeId.toString() === projectTypes.conversion.toString()) {
          maxScore += 1;
          currentScore += storeNo ? 1 : 0;
        }
      }

    }

    return maxScore > 0 ? (currentScore / maxScore) * 100 : 0;
  },
  canProgressApplication: (isDirty, currentStatus, fields, nearApplicationsDetail) => {

    if (isDirty) return false;

    let percComplete = ApplicationHelpers.percCompleteToProgress(currentStatus, fields);

    let validateProximity = ApplicationHelpers.validateProximity(nearApplicationsDetail, currentStatus, fields.projectTypeId);

    return percComplete === 100 && validateProximity;
  },
  validateProximity: (nearApplicationsDetail, statusId, projectTypeId) =>  {

    const close = nearApplicationsDetail.find(
      (a) => a.distanceUnits === 'miles'
        && a.distanceFromSource * 1609.344 <= 50
        && a.statusId !== 10 // Expired
        && a.statusId !== 11 // Aborted
        && a.statusId !== 12, // Closed
    );

    if (close && statusId === 1 && projectTypeId == projectTypes.newStore) { // draft
      return false;
    }

    return true;
  },

};

export default ApplicationHelpers;