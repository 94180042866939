import React from 'react';

const TaskView = props => {
  const taskDetail = props.data;
  return (
    <div
      className={`task-card ${taskDetail.acknowledged ? '' : 'not-acknowledged'
      }`}>
      <div
        className={`icon-holder  ${taskDetail.sentFrom
          .toLowerCase()
          .replace(' ', '-')}`}>
        <div
          className={`icon ${taskDetail.sentFrom === 'Order Management'
            ? 'icon-notification-box'
            : taskDetail.sentFrom === 'Admin'
              ? 'icon-notification-alert'
              : taskDetail.sentFrom === 'Customer Service'
                ? 'icon-notification-messages'
                : taskDetail.sentFrom === 'Item Catalogue'
                  ? 'icon-notification-item'
                  : taskDetail.sentFrom === 'Document Library'
                    ? 'icon-notification-document'
                    : taskDetail.sentFrom === 'Quality Awareness'
                      ? 'icon-notification-qa'
                      : taskDetail.sentFrom === 'Pricing Catalogue'
                        ? 'icon-notification-pricing'
                        : taskDetail.sentFrom === 'Forecasting'
                          ? 'icon-notification-forecasting'
                          : taskDetail.sentFrom === 'Orderly Inventory'
                            ? 'icon-notification-orderly'
                            : taskDetail.sentFrom === 'Reports'
                              ? 'icon-notification-report'
                              : taskDetail.sentFrom === 'Policies'
                                ? 'icon-notification-policy'
                                : 'icon-notification-messages'
          }`}
        />
      </div>
      <div className="task">
        <h5 className="title">{taskDetail.title}</h5>
        <div className="message">{taskDetail.body}</div>
        <div className="date">{props.vagueDate}</div>
        {!taskDetail.completed && taskDetail.dueDate ? 
          <div className="due">Due: {new Date(taskDetail.dueDate).toLocaleDateString()}</div> : null}

        <div className="button-holder">
          {taskDetail.actionLink && (
            <button
              className="edit action"
              type="button"
              onClick={() => props.navigate()}>
              View Content
            </button>
          )}
          {!taskDetail.completed ? (
            <button
              className="edit"
              type="button"
              onClick={() => props.acknowledge()}>
              Complete Task
            </button>
          ) : <div className="completed">
            <div>Completed:</div>
            <div>{new Date(taskDetail.completedOn).toLocaleDateString()}</div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default TaskView;
