import {
  SITEID_APPLICATIONS_REQUEST,
  SITEID_APPLICATIONS_SUCCESS,
  SITEID_APPLICATIONS_FAILURE,
  SITEID_APPLICATIONS_INACTIVE_REQUEST,
  SITEID_APPLICATIONS_INACTIVE_SUCCESS,
  SITEID_APPLICATIONS_INACTIVE_FAILURE,
  SITEID_OPEN_REQUEST,
  SITEID_OPEN_SUCCESS,
  SITEID_OPEN_FAILURE,
  SITEID_EQUITY_REQUEST,
  SITEID_EQUITY_SUCCESS,
  SITEID_EQUITY_FAILURE,
  SITEID_DTMP_REQUEST,
  SITEID_DTMP_SUCCESS,
  SITEID_DTMP_FAILURE,
  SITEID_APPLICATIONDETAIL_REQUEST,
  SITEID_APPLICATIONDETAIL_SUCCESS,
  SITEID_APPLICATIONDETAIL_FAILURE,
  SITEID_SAVEAPPLICATIONDETAIL_REQUEST,
  SITEID_SAVEAPPLICATIONDETAIL_SUCCESS,
  SITEID_SAVEAPPLICATIONDETAIL_FAILURE,
  SITEID_SAVECOMMENT_REQUEST,
  SITEID_SAVECOMMENT_SUCCESS,
  SITEID_SAVECOMMENT_FAILURE,
  SITEID_BULKCOMMENT_START,
  SITEID_BULKSAVECOMMENT_REQUEST,
  SITEID_BULKSAVECOMMENT_SUCCESS,
  SITEID_BULKSAVECOMMENT_FAILURE,
  SITEID_DELETECOMMENT_REQUEST,
  SITEID_DELETECOMMENT_SUCCESS,
  SITEID_DELETECOMMENT_FAILURE,
  SITEID_UPDATEMAP_REQUEST,
  SITEID_UPDATEMAP_SUCCESS,
  SITEID_UPDATEMAP_FAILURE,
  SITEID_DOCUMENTSAVE_REQUEST,
  SITEID_DOCUMENTSAVE_SUCCESS,
  SITEID_DOCUMENTSAVE_FAILURE,
  SITEID_DOCUMENTDELETE_REQUEST,
  SITEID_DOCUMENTDELETE_SUCCESS,
  SITEID_DOCUMENTDELETE_FAILURE,
  SITEID_EXTEND_REQUEST,
  SITEID_EXTEND_SUCCESS,
  SITEID_EXTEND_FAILURE,
  SITEID_PROGRESSSTATUS_REQUEST,
  SITEID_PROGRESSSTATUS_SUCCESS,
  SITEID_PROGRESSSTATUS_FAILURE,
  SITEID_BULKSTATUS_START,
  SITEID_BULKPROGRESSSTATUS_REQUEST,
  SITEID_BULKPROGRESSSTATUS_SUCCESS,
  SITEID_BULKPROGRESSSTATUS_FAILURE,
  SITEID_UPLOADEQUITYSTORES_REQUEST,
  SITEID_UPLOADEQUITYSTORES_SUCCESS,
  SITEID_UPLOADEQUITYSTORES_FAILURE,
  SITEID_BULKSAVERELATE_SUCCESS,
  SITEID_BULKSAVERELATE_FAILURE,
  SITEID_BULKSAVERELATE_REQUEST,
  SITEID_BULKSAVEUNRELATE_SUCCESS,
  SITEID_BULKSAVEUNRELATE_FAILURE,
  SITEID_BULKSAVEUNRELATE_REQUEST,
} from './actionTypes';

export const siteIDActions = {
  getApplications: () => (dispatch, getState) => {
    dispatch({
      type: SITEID_APPLICATIONS_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/siteid/application/v1/Get',
        successAction: SITEID_APPLICATIONS_SUCCESS,
        failureAction: SITEID_APPLICATIONS_FAILURE,
      },
    });
  },
  getInactiveApplications: (daysBack) => (dispatch, getState) => {
    dispatch({
      type: SITEID_APPLICATIONS_INACTIVE_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/siteid/application/v1/GetInactive?daysback=${daysBack}`,
        successAction: SITEID_APPLICATIONS_INACTIVE_SUCCESS,
        failureAction: SITEID_APPLICATIONS_INACTIVE_FAILURE,
      },
    });
  },
  getOpenStores: () => (dispatch, getState) => {
    dispatch({
      type: SITEID_OPEN_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/siteid/application/v1/GetOpen',
        successAction: SITEID_OPEN_SUCCESS,
        failureAction: SITEID_OPEN_FAILURE,
      },
    });
  },
  getDriveThroughPlan: () => (dispatch, getState) => {
    dispatch({
      type: SITEID_DTMP_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/siteid/DriveThrough/v1/GetMarketPlan',
        successAction: SITEID_DTMP_SUCCESS,
        failureAction: SITEID_DTMP_FAILURE,
      },
    });
  },
  getEquityStores: () => (dispatch, getState) => {
    dispatch({
      type: SITEID_EQUITY_REQUEST,
      http: {
        verb: 'GET',
        endpoint: '/api/siteid/equitystore/v1/',
        successAction: SITEID_EQUITY_SUCCESS,
        failureAction: SITEID_EQUITY_FAILURE,
      },
    });
  },
  getApplicationDetail: (applicationId) => (dispatch, getState) => {
    dispatch({
      type: SITEID_APPLICATIONDETAIL_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/siteid/applicationdetail/v1/Get/${applicationId}`,
        successAction: SITEID_APPLICATIONDETAIL_SUCCESS,
        failureAction: SITEID_APPLICATIONDETAIL_FAILURE,
      },
    });
  },
  saveApplicationDetail: (detail) => (dispatch, getState) => {
    dispatch({
      type: SITEID_SAVEAPPLICATIONDETAIL_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/siteid/applicationdetail/v1/Save',
        successAction: SITEID_SAVEAPPLICATIONDETAIL_SUCCESS,
        failureAction: SITEID_SAVEAPPLICATIONDETAIL_FAILURE,
        body: detail,
      },
    });
  },
  saveComment: (comment) => (dispatch, getState) => {
    dispatch({
      type: SITEID_SAVECOMMENT_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/siteid/comment/v1/save',
        successAction: SITEID_SAVECOMMENT_SUCCESS,
        failureAction: SITEID_SAVECOMMENT_FAILURE,
        body: comment,
      },
    });
  },
  startBulkComment: () => (dispatch, getState) => {
    dispatch({
      type: SITEID_BULKCOMMENT_START,
      payload: {},
    });
  },
  saveBulkComment: (comments) => (dispatch, getState) => {
    dispatch({
      type: SITEID_BULKSAVECOMMENT_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/siteid/comment/v1/bulksave',
        successAction: SITEID_BULKSAVECOMMENT_SUCCESS,
        failureAction: SITEID_BULKSAVECOMMENT_FAILURE,
        body: comments,
      },
    });
  },
  deleteComment: (applicationId, commentId) => (dispatch, getState) => {
    dispatch({
      type: SITEID_DELETECOMMENT_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: `/api/siteid/comment/v1/delete/${applicationId}/${commentId}`,
        successAction: SITEID_DELETECOMMENT_SUCCESS,
        failureAction: SITEID_DELETECOMMENT_FAILURE,
      },
    });
  },
  saveBulkRelate: (linkedSites) => (dispatch, getState) => {
    dispatch({
      type: SITEID_BULKSAVERELATE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/siteid/applicationdetail/v1/bulkrelate',
        successAction: SITEID_BULKSAVERELATE_SUCCESS,
        failureAction: SITEID_BULKSAVERELATE_FAILURE,
        body: linkedSites,
      },
    });
  },
  saveBulkUnrelate: (linkedSites) => (dispatch, getState) => {
    dispatch({
      type: SITEID_BULKSAVEUNRELATE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/siteid/applicationdetail/v1/bulkunrelate',
        successAction: SITEID_BULKSAVEUNRELATE_SUCCESS,
        failureAction: SITEID_BULKSAVEUNRELATE_FAILURE,
        body: linkedSites,
      },
    });
  },
  updateMap: (latitude, longitude, applicationId) => (dispatch, getState) => {
    dispatch({
      type: SITEID_UPDATEMAP_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/siteid/map/v1/Get?latitude=${latitude}&longitude=${longitude}&applicationId=${applicationId}`,
        successAction: SITEID_UPDATEMAP_SUCCESS,
        failureAction: SITEID_UPDATEMAP_FAILURE,
      },
    });
  },
  saveDocument: (document) => (dispatch, getState) => {
    dispatch({
      type: SITEID_DOCUMENTSAVE_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/siteid/document/v1/save',
        successAction: SITEID_DOCUMENTSAVE_SUCCESS,
        failureAction: SITEID_DOCUMENTSAVE_FAILURE,
        body: document,
      },
    });
  },
  deleteDocument: (applicationId, documentId) => (dispatch, getState) => {
    dispatch({
      type: SITEID_DOCUMENTDELETE_REQUEST,
      http: {
        verb: 'DELETE',
        endpoint: `/api/siteid/document/v1/delete/${applicationId}/${documentId}`,
        successAction: SITEID_DOCUMENTDELETE_SUCCESS,
        failureAction: SITEID_DOCUMENTDELETE_FAILURE,
      },
    });
  },
  saveExtension: (extension) => (dispatch, getState) => {
    dispatch({
      type: SITEID_EXTEND_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/siteid/extension/v1/save',
        successAction: SITEID_EXTEND_SUCCESS,
        failureAction: SITEID_EXTEND_FAILURE,
        body: extension,
      },
    });
  },
  saveStatusChange: (status) => (dispatch, getState) => {
    dispatch({
      type: SITEID_PROGRESSSTATUS_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/siteid/status/v1/save',
        successAction: SITEID_PROGRESSSTATUS_SUCCESS,
        failureAction: SITEID_PROGRESSSTATUS_FAILURE,
        body: status,
      },
    });
  },
  startBulkStatusChange: () => (dispatch, getState) => {
    dispatch({
      type: SITEID_BULKSTATUS_START,
      payload: {},
    });
  },
  saveBulkStatusChange: (status) => (dispatch, getState) => {
    dispatch({
      type: SITEID_BULKPROGRESSSTATUS_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/siteid/status/v1/bulksave',
        successAction: SITEID_BULKPROGRESSSTATUS_SUCCESS,
        failureAction: SITEID_BULKPROGRESSSTATUS_FAILURE,
        body: status,
      },
    });
  },
  uploadEquityStoreFile: (equityStoreFile) => (dispatch, getState) => {
    dispatch({
      type: SITEID_UPLOADEQUITYSTORES_REQUEST,
      http: {
        verb: 'POST',
        endpoint: '/api/siteid/equitystore/v1/save',
        successAction: SITEID_UPLOADEQUITYSTORES_SUCCESS,
        failureAction: SITEID_UPLOADEQUITYSTORES_FAILURE,
        body: equityStoreFile,
      },
    });
  },
};
