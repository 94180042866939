import React from 'react';
import Pagination from '../../../shared/Pagination';
// import Pagination from "./Pagination";

class TableDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageSize: 8,
      editModalExpanded: false,
      isExpanded: true,
      showLink: false,
    };

    this.handleModalToggle = this.handleModalToggle.bind(this);
  }

  handleModalToggle(modalStatusName, show) {
    this.setState({ [modalStatusName]: show });
  }
  render() {
    const {
      // doSearch,
      // navigateItemDetails,
      // filter,
      // handleFieldUpdate,
      data,
      page,
      doPaging,
      selectedRecords,
      toggleApplication,
      bulkComment,
      bulkStatus,
      bulkRelate,
      bulkUnrelate,
      isLicensee,
      isReadOnly,
      equityStores,
      fullScreenMode,
      applicationDetail,
    } = this.props;

    const {
      editModalExpanded,
      pageSize,
      isExpanded,
      showLink,
    } = this.state;

    const { handleModalToggle, relate, unRelate } = this;

    const rows = data.concat(
      equityStores.map((e) => ({
        applicationId: e.equityStoreId * -1,
        distanceUnits: 'miles',
        distanceFromSource: e.milesFromSource,
        company: 'Equity',
        siteName: e.storeName,
        status: e.lifecycleStatus,
      })),
    );

    const rowCount = rows && rows.length ? rows.length : 0;

    let records = rows.filter((item) => item.linked && selectedRecords.includes(item.applicationId));
    let unlinkedRecords = rows.filter((item) => !item.linked && selectedRecords.includes(item.applicationId));

    return (
      <div className={`table-detail ${fullScreenMode ? 'full-screen' : ''} ${isExpanded ? 'expanded' : 'collapsed'}`}>
        <div className="table-detail-header">
          <div className="edit-buttons">
            {!editModalExpanded ? (
              <button
                className="action bulkedit"
                type="button"
                disabled={!selectedRecords || !selectedRecords.length}
                onClick={() => handleModalToggle('editModalExpanded', true)}
              >
                Bulk Edit
                {selectedRecords && selectedRecords.length > 0
                  ? ` (${selectedRecords.length})`
                  : ''}
              </button>
            ) : (
              <div
                className="bulkedit-menu"
                onClick={() => handleModalToggle('editModalExpanded', false)}
              >
                <div className="background" />
                <div className="bulkedit-button-holder">
                  Bulk Edit
                  {!isReadOnly && <>
                  {!isLicensee ? (
                    <>
                      <button
                        className="action"
                        type="button"
                        disabled={records && records.length && records.length > 0 }
                        onClick={() => bulkRelate( applicationDetail.applicationId, selectedRecords)}
                      >
                      Link/Relate Sites
                      </button>
                      <button
                        className="action"
                        type="button"
                        disabled={unlinkedRecords && unlinkedRecords.length && unlinkedRecords.length > 0 }
                        onClick={() => bulkUnrelate( applicationDetail.applicationId, selectedRecords)}
                      >
                      Unlink/Unrelate Sites
                      </button>
                      <button
                        className="action"
                        type="button"
                        onClick={() => bulkStatus(9)}
                      >
                      Reject Applications
                      </button>
                    </>
                  ) : (
                    <button
                      className="action"
                      type="button"
                      onClick={() => bulkStatus(11)}
                    >
                      Withdraw Applications
                    </button>
                  )}</>}
                  <button
                    className="action"
                    type="button"
                    onClick={() => bulkComment()}
                  >
                    Comment Applications
                  </button>
                </div>
              </div>
            )}
          </div>
          <div>
            {fullScreenMode
            && (
              <button
                type="button"
                className={isExpanded ? 'closed' : 'expanded'}
                onClick={() => this.setState(
                  { isExpanded: !isExpanded },
                )}
              >
              +
              </button>
            )}
            Near Applications
          </div>
          <div>
            <Pagination
              rowCount={rowCount}
              page={page}
              pageSize={pageSize}
              doPaging={doPaging}
            />
          </div>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th />
                <th>Company</th>
                <th>Site Name</th>
                <th>Status</th>
                <th>Proximity</th>
                {/* <th></th>} */}
              </tr>
            </thead>
            <tbody>
              {rows
                && rows
                  .sort(
                    (a, b) => (a.distanceFromSource)
                      - (b.distanceFromSource),
                  )
                  .slice((page - 1) * pageSize, page * pageSize)
                  .map((item) => (
                    <tr
                      key={item.applicationId}
                      className={`${
                        selectedRecords.length > 0
                          && selectedRecords.indexOf(item.applicationId) > -1
                          ? 'active'
                          : ''
                      }`}
                    >
                      <td>
                        {item.applicationId > 0 && (
                          <button
                            type="button"
                            className="multi-select"
                            onClick={() => toggleApplication(item.applicationId)}
                          >
                            +
                          </button>
                        )}
                      </td>
                      <td className={item.linked ? 'flexed-column' : null}>{item.company}
                        {item.linked || showLink ? (
                          <div className="linked-site"
                            title="Linked Site"
                          ></div> 
                        ) : null }
                      </td>
                      <td>{item.siteName}</td>
                      <td>{item.status}</td>
                      <td>
                        {item.distanceFromSource.toFixed(1)}
                        {item.distanceUnits}
                      </td>
                      {/* <td>
                      <button
                        type="button"
                        onClick={() => navigateItemDetails(item.applicationId)}
                      >
                        View
                      </button>
                    </td> */}
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default TableDetail;
