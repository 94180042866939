import {
  ITEMPRICELIST_REQUEST,
  ITEMPRICELIST_SUCCESS,
  ITEMPRICELIST_FAILURE,
} from './actionTypes';

export const itemPriceActions = {
  requestItemPrices: (storeNumber, filter, page, pageSize, priceList) => (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: ITEMPRICELIST_REQUEST,
      http: {
        verb: 'GET',
        endpoint: `/api/Pricing/v1/Get?&storeNumber=${storeNumber}&filter=${filter}&page=${page}&pageSize=${pageSize}&priceList=${priceList}`,
        successAction: ITEMPRICELIST_SUCCESS,
        failureAction: ITEMPRICELIST_FAILURE,
      },
    });
  },
};
