import React from 'react';
import DeleteModal from '../../../shared/DeleteModal';
import SaveCommentModal from '../modals/SaveCommentModal';
import SaveDocumentEditModal from '../modals/SaveDocumentEditModal';
import File from '../../../documents/shared/File';
import statuses from '../helpers/applicationStatusEnum';

class Comments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCommentDeleteModal: false,
      showCommentModal: false,
      showDocumentDeleteModal: false,
      showDocumentEditModal: false,
      commentId: null,
      documentId: null,
    };

    this.generateOrder = this.generateOrder.bind(this);

    this.handleModalToggle = this.handleModalToggle.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { isChanged } = this.props;

    if (!prevProps.isChanged && isChanged) {
      this.setState({
        showCommentDeleteModal: false,
        showCommentModal: false,
        showDocumentDeleteModal: false,
        showDocumentEditModal: false,
        commentId: null,
        documentId: null,
      });
    }
  }

  handleModalToggle(modalStatusName, show) {
    this.setState({ [modalStatusName]: show });
  }

  generateOrder(
    comments,
    auditEntries,
    extensions,
    documents,
    statusChanges,
    isLicensee,
    downloadFile,
    currentUser,
  ) {
    const listItems = [];

    listItems.push(
      ...comments.map((c, i) => ({
        index: i,
        type: 'comment',
        date: new Date(c.created),
      })),
    );

    listItems.push(
      ...auditEntries.map((c, i) => ({
        index: i,
        type: 'auditEntry',
        date: new Date(c.created),
      })),
    );

    listItems.push(
      ...statusChanges.map((c, i) => ({
        index: i,
        type: 'status',
        date: new Date(c.created),
      })),
    );

    listItems.push(
      ...documents.map((c, i) => ({
        index: i,
        type: 'document',
        date: new Date(c.created),
      })),
    );

    listItems.sort((a, b) => a.date - b.date);

    let prevStatusChange = undefined;

    let commentList = listItems.map((c) => {
      switch (c.type) {
      case 'comment':
        return this.renderComment(comments[c.index], isLicensee, currentUser);
      case 'status':
        const statusChange = this.renderStatusChange(statusChanges[c.index], prevStatusChange, extensions, isLicensee);

        prevStatusChange = statusChanges[c.index];

        return statusChange;
      case 'document':
        return this.renderDocument(
          documents[c.index],
          isLicensee,
          downloadFile,
        );
      case 'auditEntry':
        return this.renderAuditEntry(auditEntries[c.index], isLicensee);
      default:
        return null;
      }
    });

    return commentList.reverse();
  }

  renderComment(comment, isLicensee, currentUser) {
    const { handleModalToggle } = this;
    if (isLicensee && !comment.licenseeVisible) {
      return null;
    }

    const author = isLicensee && comment.adminCreated ? 'Site Admin Team' : comment.createdBy;

    return (
      <div className="comments__line"
        key={`comment${comment.commentId}`}>
        <div className="comments__line__controls">
          <div className="comments__line__controls__left">
            <div>Comment</div>
            {comment.isFlag && <div className=" icon icon-siteid-flag" />}
            {!comment.licenseeVisible && (
              <div className="comments__line__controls__badge">Admin Only</div>
            )}
          </div>
          <div className="comments__line__controls__right">
            <div />
            <div>{`By ${author}`}</div>

            {(!isLicensee && comment.createdBy === currentUser) && (
              <div className="comments__line__controls__buttons">
                <button
                  type="button"
                  onClick={() => {
                    this.setState({ commentId: comment.commentId });
                    handleModalToggle('showCommentModal', true);
                  }}
                >
                  <div className="icon icon-siteid-edit" />
                </button>
                <button
                  type="button"
                  className="delete"
                  onClick={() => {
                    this.setState({ commentId: comment.commentId });
                    handleModalToggle('showCommentDeleteModal', true);
                  }}
                >
                    X
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="comments__line__content">
          {comment.notes}
          {comment.licenseeInformationDueBy ? (
            <i>
              {` - Information Due ${new Date(
                comment.licenseeInformationDueBy,
              ).toLocaleDateString()}`}
            </i>
          ) : (
            ''
          )}
        </div>
        <div className="comments__line__footer">
          {` ${new Date(
            comment.created,
          ).toLocaleDateString()} ${new Date(
            comment.created,
          ).toLocaleTimeString()}`}
        </div>
      </div>
    );
  }

  renderAuditEntry(auditEntry, isLicensee) {
    if (!isLicensee) {
      return (
        <div className="comments__line"
          key={`audit${auditEntry.auditEntryId}`}>
          <div className="comments__line__controls">
            <div className="comments__line__controls__left">
              <div>Audit Entry</div>
              <div className="comments__line__controls__badge">Admin Only</div>
            </div>
            <div className="comments__line__controls__right">
              <div />
              <div>{`By ${auditEntry.createdBy}`}</div>
            </div>
          </div>
          <div
            className="comments__line__content"
            dangerouslySetInnerHTML={{ __html: auditEntry.notes }}
          />
          <div className="comments__line__footer">
            {` ${new Date(
              auditEntry.created,
            ).toLocaleDateString()} ${new Date(
              auditEntry.created,
            ).toLocaleTimeString()}`}
          </div>
        </div>
      );
    }

    return null;
  }

  renderStatusChange(statusChange, prevStatusChange, extensions, isLicensee) {
    const relevantExtensions = extensions.filter(
      (e) => e.statusChangeId === statusChange.statusChangeId,
    );

    const author = isLicensee && [9, 3, 5, 7, 1].indexOf(statusChange.toStatusId) > -1 ? 'Site Admin Team' : statusChange.createdBy;

    let fromStatusName = statusChange.fromStatus;
    let toStatusName = statusChange.toStatus;

    if (statusChange.fromStatusId == statuses.registered && statusChange.toStatusId == statuses.registered) {
      if (prevStatusChange.fromStatusId === statuses.toBeRegistered) {
        toStatusName = 'Project Requested';
      } else {
        fromStatusName = 'Project Requested';
        toStatusName = 'Project Created';
      }
    } else if (statusChange.fromStatusId == statuses.registered && statusChange.toStatusId == statuses.approval) {
      fromStatusName = 'Project Created';
    }

    return (
      <div
        className="comments__line"
        key={`status${statusChange.statusChangeId}`}
      >
        <div className="comments__line__controls">
          <div className="comments__line__controls__left">
            <div>Status Change</div>
          </div>
          <div className="comments__line__controls__right">
            <div />
            <div>{`By ${author}`}</div>
          </div>
        </div>
        <div className="comments__line__content">
          <div className="status-chevrons">
            <div className="chevrons">
              <div className={`chevron status-${statusChange.fromStatusId}`}>
                {fromStatusName}
              </div>
              <div className={`chevron status-${statusChange.toStatusId}`}>
                {toStatusName}
              </div>
            </div>
            <div>
              {statusChange.expirationOrLongStop && (
                <div className="expiration">
                  {statusChange.toStatusId === 7
                    ? 'Application Long Stop'
                    : 'Status Expires'}
                  {' - '}
                  {new Date(
                    statusChange.expirationOrLongStop,
                  ).toLocaleDateString()}
                </div>
              )}
              {statusChange.notes && statusChange.notes.split('\r').map((s) => (<p key={s}>{s}</p>))}
            </div>
          </div>

          {relevantExtensions.map((m) => this.renderExtension(m, isLicensee))}
        </div>
        <div className="comments__line__footer">
          {` ${new Date(
            statusChange.created,
          ).toLocaleDateString()} ${new Date(
            statusChange.created,
          ).toLocaleTimeString()}`}
        </div>
      </div>
    );
  }

  renderExtension(extension, isLicensee) {
    const author = isLicensee ? 'Site Admin Team' : extension.reviewedBy;

    return (
      <div
        className="extension__line"
        key={`extension${extension.extensionId}`}
      >
        <div className="extension__line__controls">
          <div className="extension__line__controls__left">
            <div>
              Extension Requested
              {' '}
              {` ${new Date(extension.created).toLocaleDateString()} ${new Date(
                extension.created,
              ).toLocaleTimeString()}`}
            </div>
          </div>
          <div className="extension__line__controls__right">
            <div />
            <div>{`From ${extension.createdBy}`}</div>
          </div>
        </div>
        <div className="extension__line__content">
          <div className="chevrons">{extension.request}</div>
        </div>
        {extension.outcome && (
          <>
            <div className="extension__line__controls indent">
              <div className="extension__line__controls__left">
                <div>
                  {`Extension Reviewed - ${extension.outcome} - ${extension.outcomeId === 1 ? 'Extended Until ' + new Date(extension.extendUntil).toLocaleDateString() : 'Not Extended'}`}
                </div>
              </div>
              <div className="extension__line__controls__right">
                <div />
                <div>{`By ${author}`}</div>
              </div>
            </div>
            <div className="extension__line__content indent">
              <div className="chevrons">{extension.reason}</div>
            </div>
          </>
        )}

        <div className="extension__line__footer" />
      </div>
    );
  }

  renderDocument(document, isLicensee, downloadFile) {
    const { handleModalToggle } = this;

    if (isLicensee && !document.licenseeVisible) {
      return null;
    }

    return (
      <div className="comments__line"
        key={`document${document.documentId}`}>
        <div className="comments__line__controls">
          <div className="comments__line__controls__left">
            <div>
              Document -
              {document.category}
            </div>
            {!document.licenseeVisible && (
              <div className="comments__line__controls__badge">Admin Only</div>
            )}
          </div>
          <div className="comments__line__controls__right">
            <div />
            <div>{`By ${document.createdBy}`}</div>

            {((isLicensee && !document.adminCreated)
              || (!isLicensee && document.adminCreated)) && (
              <div className="comments__line__controls__buttons">
                <button
                  type="button"
                  onClick={() => {
                    this.setState({ documentId: document.documentId });
                    handleModalToggle('showDocumentEditModal', true);
                  }}
                >
                  <div className="icon icon-siteid-edit" />
                </button>
                <button
                  type="button"
                  className="delete"
                  onClick={() => {
                    this.setState({ documentId: document.documentId });
                    handleModalToggle('showDocumentDeleteModal', true);
                  }}
                >
                    X
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="comments__line__content">
          <div className="file-holder">
            <File
              data={{
                iconType: 1,
                iconData: 'icon-file',
                description: document.fileName,
              }}
              onView={() => downloadFile(document.documentId)}
            />
            <div>{document.description}</div>
          </div>
        </div>
        <div className="comments__line__footer">
          {` ${new Date(
            document.created,
          ).toLocaleDateString()} ${new Date(
            document.created,
          ).toLocaleTimeString()}`}
        </div>
      </div>
    );
  }

  render() {
    const { generateOrder, handleModalToggle } = this;
    const {
      comments,
      auditEntries,
      extensions,
      documents,
      statusChanges,
      isLicensee,
      deleteComment,
      saveComment,
      deleteDocument,
      saveDocument,
      documentCategories,
      isChanging,
      downloadFile,
      handleModalToggleParent,
      currentUser,
    } = this.props;

    const {
      showCommentDeleteModal,
      showCommentModal,
      showDocumentDeleteModal,
      showDocumentEditModal,
      commentId,
      documentId,
    } = this.state;

    let comment;
    let document;

    if (commentId) {
      comment = comments.find((c) => c.commentId === commentId);
    }

    if (documentId) {
      document = documents.find((c) => c.documentId === documentId);
    }

    return (
      <div className="floating-panel comments">
        <div className="comments__controls">
          <button
            type="button"
            className="action"
            onClick={() => {
              this.setState({ commentId: undefined });
              handleModalToggle('showCommentModal', true);
            }}
          >
            + New Comment
          </button>
          <button
            type="button"
            className="action"
            onClick={() => {
              this.setState({ commentId: undefined });
              handleModalToggleParent('showDocumentModal', true);
            }}
          >
            + New Document
          </button>
        </div>
        <div>
          {generateOrder(
            comments,
            auditEntries,
            extensions,
            documents,
            statusChanges,
            isLicensee,
            downloadFile,
            currentUser,
          )}
        </div>
        {showCommentDeleteModal && (
          <DeleteModal
            cancel={() => handleModalToggle('showCommentDeleteModal', false)}
            inProgress={isChanging}
            continue={() => {
              deleteComment(commentId);
              this.setState({ commentId: null });
            }}
          />
        )}
        {showDocumentDeleteModal && (
          <DeleteModal
            cancel={() => handleModalToggle('showDocumentDeleteModal', false)}
            inProgress={isChanging}
            continue={() => {
              deleteDocument(documentId);
              this.setState({ documentId: null });
            }}
          />
        )}
        {showCommentModal && (
          <SaveCommentModal
            cancel={() => handleModalToggle('showCommentModal', false)}
            inProgress={isChanging}
            continue={saveComment}
            isLicensee={isLicensee}
            comment={comment}
          />
        )}

        {showDocumentEditModal && (
          <SaveDocumentEditModal
            cancel={() => handleModalToggle('showDocumentEditModal', false)}
            inProgress={isChanging}
            continue={saveDocument}
            isLicensee={isLicensee}
            document={document}
            documentCategories={documentCategories}
          />
        )}
      </div>
    );
  }
}

export default Comments;
