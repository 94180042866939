import React, { useState } from 'react';
import Pagination from '../../shared/Pagination';

function CsrResultsTable(props){
  const [displayMode, setDisplayMode] = useState(2);
    
  return <div className="item-catalogue-container table-container">
    <div className="table-header">
      <div className="display-options">
              Table Size
        <button
          type="button"
          className="icon-display-mode-1"
          disabled={displayMode === 1}
          onClick={() => setDisplayMode(1)}></button>
        <button
          type="button"
          className="icon-display-mode-2"
          disabled={displayMode === 2}
          onClick={() => setDisplayMode(2)}></button>
        <button
          type="button"
          className="icon-display-mode-3"
          disabled={displayMode === 3}
          onClick={() => setDisplayMode(3)}></button>
      </div>
      <h2>
        Results
      </h2>
      <div className="pagination-controls">
        <Pagination
          rowCount={props.orders && props.orders.totalRecords}
          page={props.orders && props.page}
          pageSize={props.orders && props.pageSize}
          doPaging={props.orders && props.doPaging}
        />
      </div>
    </div>
    <div className='table-body'>   
      <table className={`table-display-mode-${displayMode}`}>
        <thead>
          <tr>
            <th>Order Date</th>
            <th>Sources</th>
            <th>Shipment #</th>
            <th>SO #</th>
            <th>PO # / DID #</th>
            <th>Supplier</th>
            <th>Port of Loading</th>
            <th>Region</th>
            <th>Port of Discharge</th>
            <th>Place of Delivery</th>
            <th>Ship Mode</th>
            <th>RTS</th>
            <th>ETD</th>
            <th>COB</th>
            <th>ETA</th>
            <th>Promise Date</th>
            <th>Category</th>
            <th>Promotion</th>
            <th>Remarks</th>
            <th>Form D / E Receive Date</th>
            <th>Event Code</th>
            <th>OMB Container Booked</th>
            <th>Temperature Control</th>              
          </tr>
        </thead>
        <tbody>
          {props.orders && props.orders.data && props.orders.data.length > 0 ?
            props.orders.data.map(o => 
              <tr key={o.id}>
                <td>{o.orderDate ? new Date(o.orderDate).toLocaleDateString() : null}</td>                            
                <td>{o.sources}</td>
                <td>{o.shipmentNo}</td>
                <td>{o.soNo}</td>
                <td>{o.poNo}</td>
                <td>{o.supplier}</td>
                <td>{o.portOfLoading}</td>
                <td>{o.region}</td>
                <td>{o.portOfDischarge}</td>
                <td>{o.placeOfDelivery}</td>
                <td>{o.shipMode}</td>
                <td>{o.rts ? new Date(o.rts).toLocaleDateString() : null}</td>
                <td>{o.etd ? new Date(o.etd).toLocaleDateString(): null}</td>
                <td>{o.cob ? new Date(o.cob).toLocaleDateString(): null}</td>
                <td>{o.eta ? new Date(o.eta).toLocaleDateString(): null}</td>
                <td>{o.promiseDate ? new Date(o.promiseDate).toLocaleDateString() : null}</td>
                <td>{o.category}</td>
                <td>{o.promotion}</td>
                <td>{o.remarks}</td>
                <td>{o.formRecieveDate ? new Date(o.formRecieveDate).toLocaleDateString() : null}</td>
                <td>{o.eventCode}</td>
                <td>{o.ombContainerBooked}</td>
                <td>{o.temperatureControlCondition}</td>
              </tr>,
            ) :      
            <tr><td colSpan={25}
              style={{ textAlign:'center' }}>There are currently no records found.</td></tr> }                
        </tbody>
      </table>
    </div>
  </div>;
}

export default CsrResultsTable;