import {
  ITEMPRICELIST_REQUEST,
  ITEMPRICELIST_SUCCESS,
  ITEMPRICELIST_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const itemPricesReducer = (
  state = initialState.itemPricingState,
  action,
) => {
  switch (action.type) {
  case ITEMPRICELIST_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      maxPages: undefined,
      rowCount: undefined,
      itemList: [],
      errorMessage: '',
    };
  }
  case ITEMPRICELIST_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      maxPages: action.payload.pricingItems.pageCount,
      itemList: action.payload.pricingItems.data,
      rowCount: action.payload.pricingItems.rowCount,
      ambientCount: action.payload.ambientCount,
      storeDevelopmentCount: action.payload.storeDevelopmentCount,
      freshCount: action.payload.freshCount,
      hviCount: action.payload.hviCount,
      directStoreCount: action.payload.directStoreCount,
      hviAutoCount: action.payload.hviAutoCount,
      supplierDirectCount: action.payload.supplierDirectCount,
      promotionalCount: action.payload.promotionalCount,
      directShipCount: action.payload.directShipCount,
      arpBulkCount: action.payload.arpBulkCount,
      freshAutoCount: action.payload.freshAutoCount,
      ambientAutoCount: action.payload.ambientAutoCount,
      storeDevelopmentAutoCount: action.payload.storeDevelopmentAutoCount,
      reeferCount: action.payload.reeferCount,
      reeferDirectShipment: action.payload.reeferDirectShipment,
      reeferAutoShip: action.payload.reeferAutoShip,
      storeDevDirectShip: action.payload.storeDevDirectShip,
      ambient: action.payload.ambient,
      autoshipAmbient: action.payload.autoshipAmbient,
      directShipmentAmbient: action.payload.directShipmentAmbient,
      promotional: action.payload.promotional,
      storeDevelopment: action.payload.storeDevelopment,
      reefer: action.payload.reefer,
      directShipmentReefer: action.payload.directShipmentReefer,
      autoshipReefer: action.payload.autoshipReefer,
      directShipmentStoreDevelopment: action.payload.directShipmentStoreDevelopment,
      retailSupply: action.payload.retailSupply,
      preparedFood: action.payload.preparedFood,
      retailSupplyHazmat: action.payload.retailSupplyHazmat,
      promotion: action.payload.promotion,
      directShip: action.payload.directShip,
      storeDevelopmentDirectShip: action.payload.storeDevelopmentDirectShip,
      retailSupplyAutoship: action.payload.retailSupplyAutoship,
      preparedFoodAutoship: action.payload.preparedFoodAutoship,
      retailSupplyHazmatAutoship: action.payload.retailSupplyHazmatAutoship,
      directShipAutoship: action.payload.directShipAutoship,
      errorMessage: '',
    };
  }
  case ITEMPRICELIST_FAILURE: {
    return {
      ...state,
      isFetching: false,
      isLoaded: false,
      maxPages: undefined,
      rowCount: undefined,
      itemList: [],
      errorMessage: action.payload.message,
    };
  }
  default:
    return state;
  }
};
export default itemPricesReducer;
