import React from 'react';

export class SentrkApplicationPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      iframeFullscreen: false,
      iframeClass: 'iframeNotFullscreen',
      iframeBtnClass: 'iframeNotFullscreenButton',
      iframeBtnText: 'Make Fullscreen',
    };
    this.handleFullscreen = this.handleFullscreen.bind(this);
  }

  handleFullscreen() {
    let { iframeFullscreen } = this.state;
    let iframeClass, iframeBtnClass;

    if(!iframeFullscreen){
      iframeClass = 'iframeFullscreen';
      iframeBtnClass = 'iframeFullscreenButton';
      iframeFullscreen = true;
    }
    else{
      iframeClass = 'iframeNotFullscreen';
      iframeBtnClass = 'iframeNotFullscreenButton';
      iframeFullscreen = false;
    }

    const newState = { iframeClass, iframeFullscreen, iframeBtnClass };
    this.setState(newState);
  }

  render() {
    const {
      sentrkUrl,
      applicationDetail,
    } = this.props;

    const{
      iframeClass,
      iframeBtnClass,
    } = this.state;

    if (sentrkUrl && sentrkUrl.indexOf('http') == 0) {

      return (
        <div>
          <h3>Sentrk</h3>
          <div>
            <button className={iframeBtnClass + ' button square'}
              type="button"
              title="Fullscreen"
              onClick={this.handleFullscreen}
            >
              <div className="icon icon-full-screen"></div>
            </button>
            <iframe
              className={iframeClass}
              src={sentrkUrl}
              name="sentrk_application"
              id="sentrkIframe">
            </iframe>
          </div>
        </div>
      );
    } else {
      return (<div>
        <h3>Sentrk</h3>
        {sentrkUrl ? <p>{sentrkUrl}</p> :
          <p>This application is still being created in Sentrk please check back again shortly.</p>}
      </div>);
    }

  }
}

export default SentrkApplicationPage;