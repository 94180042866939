import React, { useState } from 'react';

function RateCardUpload() {
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isUploading] = useState(false);
  const [doc, setDoc] = useState({
    mediaName: '',
    mediaType: '',
    mediaData: '',
  });

  const handleSave = async() => {
    const response = await fetch('/api/OmOrderRateCard/v1/ratecardupload', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body:JSON.stringify({
        ...doc,
      }),
    });

    if (response.status == 200){
      setIsLoaded(true);
    }
    if (response.status == 400){
      setErrorMessage(response.json().errorMessage);
    }
  };

  const handleFileInputUpdate = async(e) => {
    if (!e.target.files || e.target.files.length === 0) return;

    let doc = {
      mediaName: e.target.files[0].name,
      mediaType: e.target.files[0].type,
    };

    var reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }).then(data => {
      doc.mediaData = data ? data.replace(/^data:(.*,)?/, '') : '';

      setDoc({
        mediaName: doc.mediaName,
        mediaType: doc.mediaType,
        mediaData: doc.mediaData,
      });
    });
  };
  return (
    <div className="master-file-upload-page page">
      <form className="form-container">
        <div className="form-header">
          <div className="header-title">
            <div className="icon icon-reports"></div>
            <h2>Rate Card Upload</h2>
          </div>
        </div>
        <div className="form-explanation">
          <p>Upload a rate card to provide the freight and handling information for new booking request forms.</p>
        </div>
        <div className="form-body">
          <label className="file-upload-wrapper">
            <div className="icon icon-image-upload"></div>
            <p>
              {!doc.mediaName &&
                  'Tap to upload your Rate Card.'}
              {doc.mediaName}
            </p>
            <input
              autoFocus
              name="file"
              id="file"
              className="inputfile"
              type="file"
              onChange={e => handleFileInputUpdate(e)}
            />
          </label>
        </div>
        <div className="form-buttons">
          {errorMessage && <p>{errorMessage}</p>}
          {isLoaded && <p>File Successfully Uploaded</p>}
          <button type="button"
            className="action"
            disabled={isUploading}
            onClick={e => handleSave(e)}>
              Upload
          </button>
        </div>
      </form>
    </div>
  );
}

export default RateCardUpload;