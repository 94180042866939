import React from 'react';
import { connect } from 'react-redux';
import { connectTaskActions } from '../../../../actions/connectTaskActions';
import TaskView from '../TaskView/TaskView';
import { navigationActions } from '../../../../actions/navigationActions';
import { connectSideBarActions } from '../../../../actions/connectSideBarActions';
import { authActions } from '../../../../actions/authActions';
import ConfirmationModal from '../../../shared/ConfirmationModal';

class TaskList extends React.Component {
  
  constructor(props){
    super(props);
    this.state ={
      showModal:false,
    };
  }
  
  componentDidMount() {
    
    this.props.requestTasks();
  }

  handleComplete(platformNotificationType, type, popupId) {
    this.setState({
      platformNotificationType, 
      type, 
      popupId,
      showModal:true });
  }

  complete(){
    const { platformNotificationType,
      type,
      popupId } = this.state;

    this.props.completeTask(
      platformNotificationType,
      type,
      popupId,
    );

    this.setState({
      showModal:false,
    });
  }

  
  handleNavigate(actionLink) {
    if (!actionLink) return;

    if (actionLink.toLowerCase().startsWith('http')) {
      window.open(actionLink);
    } else {
      this.props.push(actionLink);
    }
  }

  getVagueDate(dateString) {
    const date = new Date();
    const now = date.getTime() + date.getTimezoneOffset() * 60 * 1000;
    const then = new Date(dateString).getTime();
    const difference = now - then;
    const minute = 1000 * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const month = day * 30;
    if (difference < minute * 30) {
      //Last 30 minutes
      return 'Just now';
    } else if (difference < minute * 60) {
      return 'Last Hour';
    } else if (difference / hour < 24) {
      return parseInt(difference / hour) + ' hours ago';
    } else if (difference / day < 7) {
      return parseInt(difference / day) + ' days ago';
    } else if (difference / week < 5) {
      return parseInt(difference / week) + ' weeks ago';
    } else {
      return parseInt(difference / month) + ' months ago';
    }
  }

  render() {
    const alertPopups = this.props.allPopups.filter(p => !p.acknowledged);

    return (
      <div className="tasks">
        <div className="tasks-list">
          {(!alertPopups || alertPopups.length === 0) && (
            <p> You don't have any tasks at this time.</p>
          )}
          {alertPopups.slice(0, 5).map(p => (
            <TaskView
              key={p.recipientId + '-' + p.notificationType}
              data={p}
              vagueDate={this.getVagueDate(p.sentOn)}
              navigate={() => this.handleNavigate(p.actionLink)}
              acknowledge={() =>
                this.handleComplete(
                  p.platformNotificationType,
                  p.notificationType,
                  p.recipientId,
                )
              }
            />
          ))}
        </div>

        <button
          className="float"
          type="button"
          onClick={() => {
            this.props.push('/module/978/tasks');
            this.props.hideConnect();
            this.props.toggleSideBar(false);
          }}
        >
          See All
        </button>
        {this.state.showModal &&<ConfirmationModal
          title="Complete Task"
          message="By proceeding you are confirming that you have undertaken and successfully complete the task described. Are you sure you wish to continue?"
          continue={() => this.complete()}
          cancel={() => this.setState({ showModal:false })}
        />}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  allPopups: state.connectTask.allPopups,
  numAlerts: state.connectTask.allPopups.filter(pop => !pop.acknowledged)
    .length,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    requestTasks: () =>
      dispatch(connectTaskActions.requestTasks(25, null)),
    completeTask: (platformNotificationType, type, popupId) =>
      dispatch(
        connectTaskActions.completeTask(
          platformNotificationType,
          type,
          popupId,
        ),
      ),
    
    hideConnect: () => dispatch(authActions.hideConnect()),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),

    toggleSideBar: isOpen => dispatch(connectSideBarActions.toggleSideBar(isOpen)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);
