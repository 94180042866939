import React from "react";
import { connect } from "react-redux";
import { navigationActions } from "../../../actions/navigationActions";
import { connectReportingActions } from "../../../actions/connectReportingActions";

import {
  PieChart,
  Pie,
  BarChart,
  Bar,
  Tooltip,
  YAxis,
  XAxis,
  CartesianGrid,
  Cell, Label
} from "recharts";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "./SliderComponent/SliderComponents"; // example render components - source below

const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const COLORS = [
  "#b6e2b6",
  "#aadaff",
  "#f8f3da",
  "#f4d88b",
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042"
];

class ConnectReporting extends React.Component {
  constructor(props) {
    super(props);

    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDayOfMonth = new Date(y, m, 1);
    var firstDayNextMonth = new Date(firstDayOfMonth);
    firstDayNextMonth.setMonth(m + 1);

    this.state = {
      now: firstDayNextMonth,
      fromDate: firstDayOfMonth,
      toDate: firstDayNextMonth
    };

    this.handleFetchData = this.handleFetchData.bind(this);

    this.getVolumeMetrics = this.getVolumeMetrics.bind(this);
    this.renderSlider = this.renderSlider.bind(this);

    this.onSliderChange = this.onSliderChange.bind(this);
  }

  handleFetchData(data) {
    this.props.getData(data.fromDate, data.toDate);
    this.props.getNotificationData(data.fromDate, data.toDate);
    this.props.getTaskData(data.fromDate, data.toDate);
  }

  componentDidMount() {

    var date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    var firstDayOfMonth = new Date(y, m, 1);
    var firstDayNextMonth = new Date(firstDayOfMonth);
    firstDayNextMonth.setMonth(m + 1);

    let fromDate = firstDayOfMonth;
    let toDate = firstDayNextMonth;

    const newState = {
      fromDate,
      toDate,
    };

    this.setState(newState);

    //Triggered by slider
    this.handleFetchData(newState);
  }

  getBucketAxisLabel(noOfDays) {
    if (noOfDays < 14) {
      return "By Day";
    } else if (noOfDays < 56) {
      return "By Week Commencing";
    } else if (noOfDays < 350) {
      return "By Month";
    } else {
      return "By Year"
    }
  }

  getBucketData(points) {
    const calendarMonths = ['January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    let groupLabel = "day";
    

    if (points.length < 14) {
      groupLabel = "day";
    } else if (points.length < 56) {
      groupLabel = "week";
    } else if (points.length < 350) {
      groupLabel = "month";
    } else {
      groupLabel = "year";
    }

    var partition = null;
    var newPoints = [];

    if(groupLabel === "day"){
      for (var i = 0; i < points.length; i++) {
        if (points[i] !== null) {
          points[i].name = points[i].name.toLocaleDateString();
        }
      }
      return points;
    }
    if (groupLabel === "month") {
      for (var j = 0; j < points.length; j++) {
        if (points[j] !== null) {
          if (partition === null || partition !== calendarMonths[points[j].name.getMonth()]) {
            partition = calendarMonths[points[j].name.getMonth()];
            newPoints.push({ name: partition, value: points[j].value });
          } else {
            newPoints[newPoints.length - 1].value += points[j].value;
          }
        }
      }
    }
    
    if (groupLabel === "week") {
      for (var k = 0; k < points.length; k++) {
        if (points[k] !== null) {
          if (partition === null || k % 7 === 0) {
            partition = points[k].name.toLocaleDateString();
            newPoints.push({ name: partition, value: points[k].value });
          } else {
            newPoints[newPoints.length - 1].value += points[k].value;
          }
        }
      }
    }

    return newPoints;

  }

  getVolumeMetrics() {
    const { data, taskData, notificationData } = this.props;

    let components = [];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      name,
      index
    }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.7;

      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      const x1 = cx + outerRadius * 1.1 * Math.cos(-midAngle * RADIAN);
      const y2 = cy + outerRadius * 1.1 * Math.sin(-midAngle * RADIAN);

      if (percent < 0.05) {
        return null;
      }
      return (
        <React.Fragment>
          <text
            x={x1}
            y={y2}
            fill="#707070"
            textAnchor={x1 > cx ? "start" : "end"}
            dominantBaseline="central"
            className="outer-label"
          >
            {name}
          </text>
          <text
            x={x + (x > cx ? -15 : 15)}
            y={y}
            stroke="#777"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
            className="inner-label"
          >
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        </React.Fragment>
      );
    };

    if (data.connectUsage && data.connectUsage.length > 0) {
      let connectUsageData = data.connectUsage.map(d => {
        if (d.key)
          return {
            name: new Date(d.key.toString()),
            value: d.value || 0
          };
        return null;
      });

      components.push(
        <div className="stat-group" key="connectUsageByDate">
          <div className="stat-header">
            <h3>Connect Usage by Date</h3>
          </div>
          <div className="stat-body  stat-body--x2">
            <BarChart
              width={500}
              height={300}
              data={this.getBucketData(connectUsageData)}
              margin={{
                top: 5,
                right: 30,
                left: 25,
                bottom: 30
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" >
                <Label fill="#555" position={"bottom"}>{this.getBucketAxisLabel(connectUsageData.length)}</Label>
              </XAxis>
              <YAxis allowDecimals={false}>
                <Label fill="#555" position={"left"} angle={270}>Page Loads</Label>
              </YAxis>
              <Tooltip />
              <Bar dataKey="value">
                {connectUsageData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Bar>
            </BarChart>
          </div>
        </div>
      );
    }

    if (data.contentRead && data.contentRead.length > 0) {
      const contentReads = data.contentRead.map(d => {
        if (d.key)
          return {
            name: new Date(d.key.toString()),
            value: d.value || 0
          };
        return null;
      });

      components.push(
        <div className="stat-group" key="contentReadsByDate">
          <div className="stat-header">
            <h3>Content Read by Date</h3>
          </div>
          <div className="stat-body  stat-body--x2">
            <BarChart
              width={500}
              height={300}
              data={this.getBucketData(contentReads)}
              margin={{
                top: 5,
                right: 30,
                left: 25,
                bottom: 30
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" >
                <Label fill="#555" position={"bottom"}>{this.getBucketAxisLabel(contentReads.length)}</Label>
              </XAxis>
              <YAxis allowDecimals={false}>
                <Label fill="#555" position={"left"} angle={270}>Content Expanded</Label>
              </YAxis>
              <Tooltip />
              <Bar dataKey="value">
                {contentReads.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Bar>
            </BarChart>
          </div>
        </div>
      );
    }

    if (notificationData && (notificationData.notificationsRead || notificationData.notificationsWaiting)) {

      let notificationByStatus = [{
        name: "Read",
        statusId: 1,
        value: notificationData.notificationsRead
      }, {
        name: "Waiting",
        statusId: 2,
        value: notificationData.notificationsWaiting
      }];

      components.push(
        <div className="stat-group" key="notificationByStatus">
          <div className="stat-header">
            <h3>Notifications by Status</h3>
          </div>
          <div className="stat-body">
            <PieChart width={500} height={300}>
              <Pie
                dataKey="value"
                nameKey="name"
                data={notificationByStatus}
                outerRadius={100}
                label={renderCustomizedLabel}
                labelLine={false}
                isAnimationActive={false}
              >
                {notificationByStatus.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      );
    }

    if (taskData && (taskData.completedActive || taskData.overdueActive || taskData.pendingActive)) {

      let tasksByStatus = [{
        name: "Completed",
        statusId: 1,
        value: taskData.completedActive
      }, {
        name: "Overdue",
        statusId: 2,
        value: taskData.overdueActive
      }, {
        name: "Pending",
        statusId: 3,
        value: taskData.pendingActive
      }];

      components.push(
        <div className="stat-group" key="currenttasksByStatus">
          <div className="stat-header">
            <h3>Currently Valid Tasks by Status</h3>
          </div>
          <div className="stat-body">
            <PieChart width={500} height={300}>
              <Pie
                dataKey="value"
                nameKey="name"
                data={tasksByStatus}
                outerRadius={100}
                label={renderCustomizedLabel}
                labelLine={false}
                isAnimationActive={false}
              >
                {tasksByStatus.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      );
    }

    if (taskData && (taskData.completed || taskData.overdue || taskData.pending)) {

      let tasksByStatus = [{
        name: "Completed",
        statusId: 1,
        value: taskData.completed
      }, {
        name: "Overdue",
        statusId: 2,
        value: taskData.overdue
      }, {
        name: "Pending",
        statusId: 3,
        value: taskData.pending
      }];

      components.push(
        <div className="stat-group" key="tasksByStatus">
          <div className="stat-header">
            <h3>All Tasks by Status</h3>
          </div>
          <div className="stat-body">
            <PieChart width={500} height={300}>
              <Pie
                dataKey="value"
                nameKey="name"
                data={tasksByStatus}
                outerRadius={100}
                label={renderCustomizedLabel}
                labelLine={false}
                isAnimationActive={false}
              >
                {tasksByStatus.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      );
    }

    if (data.popularContent && data.popularContent.length > 0) {

      const popularContent = data.popularContent.map(d => {
        if (d.value)
          return {
            name: d.key,
            value: d.value
          };
        return null;
      });

      components.push(
        <div className="stat-group" key="popularContent">
          <div className="stat-header">
            <h3>Top 5 Most Read Content</h3>
          </div>
          <div className="stat-body">
            <PieChart width={500} height={300}>
              <Pie
                dataKey="value"
                nameKey="name"
                data={popularContent}
                outerRadius={100}
                label={renderCustomizedLabel}
                labelLine={false}
                isAnimationActive={false}
              >
                {popularContent.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      );
    }

    if (data.menuUsage && data.menuUsage.length > 0) {

      const menuUsage = data.menuUsage.map(d => {
        if (d.value)
          return {
            name: d.key,
            value: d.value
          };
        return null;
      });

      components.push(
        <div className="stat-group" key="menuUsage">
          <div className="stat-header">
            <h3>Functionality Utilisation</h3>
          </div>
          <div className="stat-body">
            <PieChart width={500} height={300}>
              <Pie
                dataKey="value"
                nameKey="name"
                data={menuUsage}
                outerRadius={100}
                label={renderCustomizedLabel}
                labelLine={false}
                isAnimationActive={false}
              >
                {menuUsage.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </div>
        </div>
      );
    }

    //Bar charts
    if (data.videoView && data.videoView.length > 0) {
      const videoViews = data.videoView.map(d => {
        if (d.key)
          return {
            name: new Date(d.key.toString()),
            value: d.value || 0
          };
        return null;
      });

      components.push(
        <div className="stat-group" key="vidViewsBydate">
          <div className="stat-header">
            <h3>Video Views by Date</h3>
          </div>
          <div className="stat-body  stat-body--x2">
            <BarChart
              width={500}
              height={300}
              data={this.getBucketData(videoViews)}
              margin={{
                top: 5,
                right: 30,
                left: 25,
                bottom: 30
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" >
                <Label fill="#555" position={"bottom"}>{this.getBucketAxisLabel(videoViews.length)}</Label>
              </XAxis>
              <YAxis allowDecimals={false}>
                <Label fill="#555" position={"left"} angle={270}>Video Plays</Label>
              </YAxis>
              <Tooltip />
              <Bar dataKey="value">
                {videoViews.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    stroke="#777"
                  />
                ))}
              </Bar>
            </BarChart>
          </div>
        </div>
      );
    }

    return components;
  }

  onSliderChange = data => {
    const { fromDate, toDate, now } = this.state;

    if (fromDate.getTime() !== data[0] || toDate.getTime() !== data[1]) {
      let newFromDate = new Date(now);
      newFromDate.setMonth(newFromDate.getMonth() + data[0]);

      let newToDate = new Date(now);
      newToDate.setMonth(newToDate.getMonth() + data[1]);

      this.setState({
        fromDate: newFromDate,
        toDate: newToDate
      });

      this.handleFetchData({
        ...this.state,
        fromDate: newFromDate,
        toDate: newToDate
      });
    }
  };

  renderSlider() {
    const { fromDate, toDate, now } = this.state;

    let oneYrAgo = -12;

    const nowTime = 0;

    const dateTicks = new Array(12).forEach((itm, idx) => idx - 12);

    const formatTicks = ms => {
      var date = new Date(now);
      date.setMonth(date.getMonth() + ms);

      var month = date.getMonth();
      var year = date.getFullYear();
      if (month === 0) {
        return year.toString();
      } else {
        return Months[month];
      }
    };

    let from =
      fromDate.getFullYear() * 12 +
      fromDate.getMonth() -
      (now.getFullYear() * 12 + now.getMonth());
    let to =
      toDate.getFullYear() * 12 +
      toDate.getMonth() -
      (now.getFullYear() * 12 + now.getMonth());

    return (
      <Slider
        mode={3}
        step={1}
        domain={[oneYrAgo, nowTime]}
        rootStyle={{
          position: "relative",
          width: "100%",
          height: "39px"
        }}
        onChange={this.onSliderChange}
        values={[from, to]}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div>
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={[oneYrAgo, nowTime]}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false} left={false}>
          {({ tracks, getTrackProps }) => (
            <div>
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks values={dateTicks}>
          {({ ticks }) => (
            <div>
              {ticks.map(tick => (
                <Tick
                  key={tick.id}
                  tick={tick}
                  count={ticks.length}
                  format={formatTicks}
                />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    );
  }

  render() {
    const {
      fromDate,
      toDate
    } = this.state;
    const {
      data,
    } = this.props;
    const {
      getVolumeMetrics,
      renderSlider
    } = this;
    return (
      <div className="site-id-page page dashboard">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div className={`icon icon-speedo`}></div>
              <div>
                <h2>Connect Engagement</h2>
                <p>
                  Review and analyse data about how the My Starbucks Connect module is
                  being utilised by customers and which functionality they are
                  using.
                </p>
              </div>
            </div>
            <div className="vertical-controls">
              <div>
                <div className="horizontal-controls">
                  <div>From: {fromDate.toLocaleDateString()}</div>
                  <div>To: {toDate.toLocaleDateString()}</div>
                </div>
              </div>
              <div className="slider">{renderSlider()}</div>
            </div>
          </div>
        </div>
        <div className="stat-container">
          {data && getVolumeMetrics()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  isLoading: state.siteIDReporting.isLoading,
  isLoaded: state.siteIDReporting.isLoaded,

  data: state.connectReporting.data,
  notificationData: state.connectReporting.notificationData,
  taskData: state.connectReporting.taskData,

});

const mapDispatchToProps = dispatch => {
  return {
    getData: (fromDate, toDate) => dispatch(connectReportingActions.getDashboard(fromDate, toDate)),
    getNotificationData: (fromDate, toDate) => dispatch(connectReportingActions.getDashboardNotifications(fromDate, toDate)),
    getTaskData: (fromDate, toDate) => dispatch(connectReportingActions.getDashboardTasks(fromDate, toDate)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectReporting);
