import {
  DISTRIBUTION_DETAIL_REQUEST,
  DISTRIBUTION_DETAIL_SUCCESS,
  DISTRIBUTION_DETAIL_FAILURE,
  DISTRIBUTION_SAVE_REQUEST,
  DISTRIBUTION_SAVE_SUCCESS,
  DISTRIBUTION_SAVE_FAILURE,
} from '../actions/actionTypes';
import initialState from './initialState';

export const emailDistributionListReducer = (
  state = initialState.emailDistributionListState,
  action,
) => {
  switch (action.type) {
  case DISTRIBUTION_DETAIL_REQUEST: {
    return {
      ...state,
      isFetching: true,
      isLoaded: false,
      detail: [],
      emails: [],
      error: '',
    };
  }
  case DISTRIBUTION_DETAIL_SUCCESS: {
    return {
      ...state,
      isFetching: false,
      isLoaded: true,
      detail: action.payload.detail,
      emails: action.payload.emails,
      error: '',
    };
  }
  case DISTRIBUTION_DETAIL_FAILURE: {
    return {
      isFetching: false,
      isLoaded: false,
      detail: [],
      emails: [],
      error: action.payload.message,
    };
  }
  case DISTRIBUTION_SAVE_REQUEST:
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      error: '',
    };
  case DISTRIBUTION_SAVE_SUCCESS:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
    };
  case DISTRIBUTION_SAVE_FAILURE:
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      error: action.payload.message,
    };
  default:
    return state;
  }
};
export default emailDistributionListReducer;
