import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../actions/navigationActions';
import { itemPriceActions } from '../../../actions/itemPriceActions';
import qs from 'query-string';
import StoreSelector from '../../shared/storeSelector';

class ItemPriceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      storeNumber: '',
      filter: '',
      page: 1,
      pageSize: 25,
      displayMode: 1,
      currentTab: 1,
    };

    this.handleStoreChange = this.handleStoreChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.setDisplayMode = this.setDisplayMode.bind(this);

    this.handleChangeTab = this.handleChangeTab.bind(this);

    this.doPaging = this.doPaging.bind(this);
    this.doSearch = this.doSearch.bind(this);
  }

  handleStoreChange(storeNumber) {
    const { pathname } = this.props.location;
    const { filter, page, pageSize, displayMode, currentTab } = this.state;
    this.setState({ storeNumber });

    this.props.getItems(storeNumber, filter, page, pageSize, currentTab);

    this.props.replace(
      `${pathname}?storeNumber=${storeNumber}&filter=${filter}&page=${page}&displayMode=${displayMode}&priceList=${currentTab}`,
    );
  }

  handleChangeTab(tab) {
    const { pathname } = this.props.location;
    const { storeNumber, filter, page, pageSize, displayMode } = this.state;
    this.setState({ currentTab: tab });
    this.props.getItems(storeNumber, filter, page, pageSize, tab);

    this.props.replace(
      `${pathname}?storeNumber=${storeNumber}&filter=${filter}&page=${page}&displayMode=${displayMode}&priceList=${tab}`,
    );
  }

  componentDidMount() {
    const { stores } = this.props;
    const { search } = this.props.location;

    const parsed = qs.parse(search.toLowerCase());

    let storeNumber = parsed.storenumber ? parsed.storenumber : '';

    if (!storeNumber) {
      if (stores && stores.length > 0) {
        storeNumber = stores[0].storeNumber;
      }
    }

    const newState = {
      storeNumber: storeNumber,
      filter: parsed.filter ? parsed.filter : '',
      page: parsed.page ? parseInt(parsed.page) : 1,
      pageSize: 25,
      displayMode: parsed.displaymode ? parseInt(parsed.displaymode) : 1,
      currentTab: parsed.pricelist ? parseInt(parsed.pricelist) : 1,
    };

    this.setState(newState);

    if (storeNumber) {
      this.props.getItems(
        newState.storeNumber,
        newState.filter,
        newState.page,
        newState.pageSize,
        newState.currentTab,
      );
    }
  }

  handleSearch(e) {
    this.setState({ filter: e.target.value, page: 1 });
  }

  doSearch() {
    const { pathname } = this.props.location;
    const {
      filter,
      storeNumber,
      displayMode,
      page,
      pageSize,
      currentTab,
    } = this.state;

    this.props.replace(
      `${pathname}?storeNumber=${storeNumber}&filter=${filter}&page=${page}&displayMode=${displayMode}&priceList=${currentTab}`,
    );

    this.props.getItems(storeNumber, filter, page, pageSize, currentTab);
  }

  doPaging(page) {
    const { pathname } = this.props.location;
    const {
      filter,
      storeNumber,
      displayMode,
      pageSize,
      currentTab,
    } = this.state;

    this.setState({ page });

    this.props.replace(
      `${pathname}?storeNumber=${storeNumber}&filter=${filter}&page=${page}&displayMode=${displayMode}&priceList=${currentTab}`,
    );

    this.props.getItems(storeNumber, filter, page, pageSize, currentTab);
  }

  setDisplayMode(mode) {
    this.setState({ displayMode: mode });
  }

  render() {
    const {
      stores,
      rowCount,
      pageCount,
      itemList,
      ambientCount,
      storeDevelopmentCount,
      freshCount,
      hviCount,
      directStoreCount,
      hviAutoCount,
      supplierDirectCount,
      promotionalCount,
      directShipCount,
      arpBulkCount,
      freshAutoCount,
      ambientAutoCount,
      storeDevelopmentAutoCount,
      reeferCount,
      reeferDirectShipment,
      reeferAutoShip,
      storeDevDirectShip,
      ambient,
      autoshipAmbient,
      directShipmentAmbient,
      promotional,
      storeDevelopment,
      reefer,
      directShipmentReefer,
      autoshipReefer,
      directShipmentStoreDevelopment,
      retailSupply,
      preparedFood,
      retailSupplyHazmat,
      promotion,
      directShip,
      storeDevelopmentDirectShip,
      retailSupplyAutoship,
      preparedFoodAutoship,
      retailSupplyHazmatAutoship,
      directShipAutoship,
      tenantKey,
    } = this.props;
    const {
      storeNumber,
      page,
      pageSize,
      filter,
      displayMode,
      currentTab,
    } = this.state;
    const {
      handleStoreChange,
      setDisplayMode,
      handleSearch,
      doSearch,
      doPaging,
    } = this;

    const isEMEA = tenantKey === 'EMEA';
    const isAPAC = tenantKey === 'APAC';
    const isLAC = tenantKey === 'LAC';

    return (
      <div className="item-pricing-page page">
        <div className="floating-panel">
          <div className="floating-header">
            <div className="header-title">
              <div
                className={`icon icon-pricing ${
                  itemList && itemList.length > 0 && itemList[0].currency
                    ? itemList[0].currency.toLowerCase()
                    : ''
                }`}></div>
              <div>
                <h2>Item Price Lists</h2>
                <p>Price information for currently active items</p>
              </div>
            </div>
            {stores && <StoreSelector
              onStoreSelected={handleStoreChange}
              stores={stores}
              storeNumber={storeNumber ? storeNumber.toString() : ''}
            />}
          </div>
        </div>
        <div className="horizontal-controls">
          <div className="tab-container">
            {isEMEA &&
            <>
              {freshCount > 0 && (
                <div
                  className={`tab-button ${currentTab === 0 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(0)}>
                Fresh
                </div>
              )}
              {ambientCount > 0 && (
                <div
                  className={`tab-button ${currentTab === 1 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(1)}>
                Ambient
                </div>
              )}
              {ambientAutoCount > 0 && (
                <div
                  className={`tab-button ${currentTab === 2 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(2)}>
                Ambient (Auto Ship)
                </div>
              )}
              {hviCount > 0 && (
                <div
                  className={`tab-button ${currentTab === 3 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(3)}>
                HVI
                </div>
              )}
              {freshAutoCount > 0 && (
                <div
                  className={`tab-button ${currentTab === 4 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(4)}>
                Fresh (Auto Ship)
                </div>
              )}
              {arpBulkCount > 0 && (
                <div
                  className={`tab-button ${currentTab === 5 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(5)}>
                ARP Bulk
                </div>
              )}
              {directShipCount > 0 && (
                <div
                  className={`tab-button ${currentTab === 6 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(6)}>
                Direct Shipment
                </div>
              )}
              {promotionalCount > 0 && (
                <div
                  className={`tab-button ${currentTab === 7 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(7)}>
                Promotional
                </div>
              )}
              {supplierDirectCount > 0 && (
                <div
                  className={`tab-button ${currentTab === 8 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(8)}>
                Supplier Direct
                </div>
              )}
              {storeDevelopmentCount > 0 && (
                <div
                  className={`tab-button ${currentTab === 9 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(9)}>
                Store Development
                </div>
              )}
              {hviAutoCount > 0 && (
                <div
                  className={`tab-button ${currentTab === 10 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(10)}>
                HVI (Auto Ship)
                </div>
              )}
              {directStoreCount > 0 && (
                <div
                  className={`tab-button ${currentTab === 12 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(12)}>
                Direct Store
                </div>
              )}

              { /*
      storeDevDirectShip,*/}
            
              {storeDevelopmentAutoCount > 0 && (
                <div
                  className={`tab-button ${currentTab === 11 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(11)}>
                Store Development (Auto Ship)
                </div>
              )}
              {reeferCount > 0 && (
                <div
                  className={`tab-button ${currentTab === 13 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(13)}>
                Reefer
                </div>
              )}
              {reeferDirectShipment > 0 && (
                <div
                  className={`tab-button ${currentTab === 14 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(14)}>
                Reefer Direct
                </div>
              )}
              {reeferAutoShip > 0 && (
                <div
                  className={`tab-button ${currentTab === 15 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(15)}>
                Reefer (Auto Ship)
                </div>
              )}
              {storeDevDirectShip > 0 && (
                <div
                  className={`tab-button ${currentTab === 16 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(16)}>
                 Store Development Direct
                </div>
              )}
            </>
            }
            {isAPAC &&
            <>
              {ambient > 0 && (
                <div
                  className={`tab-button ${currentTab === 1 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(1)}>
                 Ambient
                </div>
              )}
              {autoshipAmbient > 0 && (
                <div
                  className={`tab-button ${currentTab === 2 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(2)}>
                 Autoship Ambient
                </div>
              )}
              {directShipmentAmbient > 0 && (
                <div
                  className={`tab-button ${currentTab === 6 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(6)}>
                Direct Shipment Ambient
                </div>
              )}
              {promotional > 0 && (
                <div
                  className={`tab-button ${currentTab === 7 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(7)}>
                 Promotional
                </div>
              )}
              {storeDevelopment > 0 && (
                <div
                  className={`tab-button ${currentTab === 9 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(9)}>
                 Store Development
                </div>
              )}
              {reefer > 0 && (
                <div
                  className={`tab-button ${currentTab === 13 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(13)}>
                 Reefer
                </div>
              )}
              {directShipmentReefer > 0 && (
                <div
                  className={`tab-button ${currentTab === 14 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(14)}>
                 Direct Reefer
                </div>
              )}
              {autoshipReefer > 0 && (
                <div
                  className={`tab-button ${currentTab === 15 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(15)}>
                 Autoship Reefer
                </div>
              )}
              {directShipmentStoreDevelopment > 0 && (
                <div
                  className={`tab-button ${currentTab === 16 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(16)}>
                 Store Development Direct
                </div>
              )}
            </>
            }
            {isLAC &&
            <>
              {retailSupply > 0 && (
                <div
                  className={`tab-button ${currentTab === 1 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(1)}>
                 Retail Supply
                </div>
              )}
              {preparedFood > 0 && (
                <div
                  className={`tab-button ${currentTab === 17 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(17)}>
                 Prepared Food
                </div>
              )}
              {retailSupplyHazmat > 0 && (
                <div
                  className={`tab-button ${currentTab === 19 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(19)}>
                 Retail Supply Hazmat
                </div>
              )}
              {promotion > 0 && (
                <div
                  className={`tab-button ${currentTab === 7 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(7)}>
                 Promotion
                </div>
              )}
              {directShip > 0 && (
                <div
                  className={`tab-button ${currentTab === 8 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(8)}>
                Direct
                </div>
              )}
              {storeDevelopmentDirectShip > 0 && (
                <div
                  className={`tab-button ${currentTab === 16 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(16)}>
                 Store Development Direct
                </div>
              )}
              {retailSupplyAutoship > 0 && (
                <div
                  className={`tab-button ${currentTab === 2 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(2)}>
                 Retail Supply (Auto Ship)
                </div>
              )}
              {preparedFoodAutoship > 0 && (
                <div
                  className={`tab-button ${currentTab === 18 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(18)}>
                 Prepared Food (Auto Ship)
                </div>
              )}
              {retailSupplyHazmatAutoship > 0 && (
                <div
                  className={`tab-button ${currentTab === 20 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(20)}>
                 Retail Supply Hazmat (Auto Ship)
                </div>
              )}
              {directShipAutoship > 0 && (
                <div
                  className={`tab-button ${currentTab === 23 ? 'selected' : ''}`}
                  onClick={() => this.handleChangeTab(23)}>
                 Direct (Auto Ship)
                </div>
              )}
            </>
            }
          </div>
          <div className="header-buttons">
            <input
              type="text"
              placeholder="..."
              value={filter}
              onChange={e => handleSearch(e)}
            />
            <button type="button"
              className="action"
              onClick={() => doSearch()}>
              Search
            </button>
          </div>
        </div>
        <div className="item-pricing-container table-container">
          <div className="table-header">
            <div className="display-options">
              Table Size
              <button
                type="button"
                className="icon-display-mode-1"
                disabled={displayMode === 1}
                onClick={() => setDisplayMode(1)}></button>
              <button
                type="button"
                className="icon-display-mode-2"
                disabled={displayMode === 2}
                onClick={() => setDisplayMode(2)}></button>
              <button
                type="button"
                className="icon-display-mode-3"
                disabled={displayMode === 3}
                onClick={() => setDisplayMode(3)}></button>
            </div>
            {isEMEA &&    
            <>       
              {currentTab === 0 && <h2>Fresh Price List</h2>}
              {currentTab === 4 && <h2>Fresh (Auto Ship) Price List</h2>}
              {currentTab === 1 && <h2>Ambient Price List</h2>}
              {currentTab === 2 && <h2>Ambient (Auto Ship) Price List</h2>}
              {currentTab === 3 && <h2>HVI Price List</h2>}
              {currentTab === 10 && <h2>HVI (Auto Ship) Price List</h2>}
              {currentTab === 5 && <h2>ARP Bulk Price List</h2>}
              {currentTab === 9 && <h2>Store Development Price List</h2>}
              {currentTab === 6 && <h2>Direct Shipment Price List</h2>}
              {currentTab === 7 && <h2>Promotional Price List</h2>}
              {currentTab === 8 && <h2>Supplier Direct Price List</h2>}
              {currentTab === 12 && <h2>Direct Store Price List</h2>}
              {currentTab === 11 && <h2>Store Development (Auto Ship) Price List</h2>}
              {currentTab === 13 && <h2>Reefer Price List</h2>}
              {currentTab === 14 && <h2>Reefer Direct Price List</h2>}
              {currentTab === 15 && <h2>Reefer (Auto Ship) Price List</h2>}
              {currentTab === 16 && <h2>Store Development Direct Price List</h2>}
            </>
            }
            {isAPAC &&
            <>
              {currentTab === 1 && <h2>Ambient Price List</h2>}
              {currentTab === 2 && <h2>(Auto Ship) Ambient Price List</h2>}
              {currentTab === 6 && <h2>Direct Shipment Ambient Price List</h2>}
              {currentTab === 7 && <h2>Promotional Price List</h2>}
              {currentTab === 9 && <h2>Store Development Price List</h2>}
              {currentTab === 13 && <h2>Reefer Price List</h2>}
              {currentTab === 14 && <h2>Direct Shipment Reefer Price List</h2>}
              {currentTab === 15 && <h2>(Auto Ship) Reefer Price List</h2>}
              {currentTab === 16 && <h2>Direct Shipment Store Development Price List</h2>}
            </>
            }
            {isLAC &&
            <>
              {currentTab === 1 && <h2>Retail Supply Price List</h2>}
              {currentTab === 17 && <h2>Prepared Food Price List</h2>}
              {currentTab === 19 && <h2>Retail Supply Hazmat Price List</h2>}
              {currentTab === 7 && <h2>Promotion Price List</h2>}
              {currentTab === 8 && <h2>Direct Shipment Price List</h2>}
              {currentTab === 16 && <h2>Store Development Direct Price List</h2>}
              {currentTab === 2 && <h2>(Auto Ship) Retail Supply Price List</h2>}
              {currentTab === 17 && <h2>(Auto Ship) Prepared Food Price List</h2>}
              {currentTab === 20 && <h2>(Auto Ship) Retail Supply Hazmat Price List</h2>}
              {currentTab === 23 && <h2>(Auto Ship) Direct Shipment Price List</h2>}
            </>
            }
            <div className="pagination-controls">
              {rowCount === 0 && '0-0 of 0'}
              {rowCount > 0 &&
                `${page * pageSize - pageSize + 1}-${page *
                  pageSize} of ${rowCount}`}
              <button
                type="button"
                disabled={page === 1}
                onClick={() => doPaging(page - 1)}>
                {'<'}
              </button>
              <button
                type="button"
                disabled={page >= pageCount}
                onClick={() => doPaging(page + 1)}>
                {'>'}
              </button>
            </div>
          </div>
          <div className="table-body">
            <table className={`table-display-mode-${displayMode}`}>
              <thead>
                <tr>
                  <th>Item Number</th>
                  <th>Item Description</th>
                  <th>Pricing UOM</th>
                  <th>Current Price</th>
                  <th>Currency</th>
                </tr>
              </thead>
              <tbody>
                {itemList.map(item => (
                  <tr key={item.itemId}>
                    <td>{item.itemNumber}</td>
                    <td>{item.itemDescription}</td>
                    <td>{item.uom}</td>
                    <td>
                      {item.currentPrice ? item.currentPrice.toFixed(2) : '-'}
                    </td>
                    <td>{item.currency}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="table-header">
            <div></div>
            <div className="pagination-controls">
              {rowCount === 0 && '0-0 of 0'}
              {rowCount > 0 &&
                `${page * pageSize - pageSize + 1}-${
                  page * pageSize < rowCount ? page * pageSize : rowCount
                } of ${rowCount}`}
              <button
                type="button"
                disabled={page === 1}
                onClick={() => doPaging(page - 1)}>
                {'<'}
              </button>
              <button
                type="button"
                disabled={page >= pageCount}
                onClick={() => doPaging(page + 1)}>
                {'>'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.itemPrices.isFetching,
  loaded: state.itemPrices.isLoaded,
  stores: state.auth.stores,
  itemList: state.itemPrices.itemList,
  pageCount: state.itemPrices.maxPages,
  rowCount: state.itemPrices.rowCount,
  ambientCount: state.itemPrices.ambientCount,
  storeDevelopmentCount: state.itemPrices.storeDevelopmentCount,
  freshCount: state.itemPrices.freshCount,
  hviCount: state.itemPrices.hviCount,
  directStoreCount: state.itemPrices.directStoreCount,
  hviAutoCount: state.itemPrices.hviAutoCount,
  supplierDirectCount: state.itemPrices.supplierDirectCount,
  promotionalCount: state.itemPrices.promotionalCount,
  directShipCount: state.itemPrices.directShipCount,
  arpBulkCount: state.itemPrices.arpBulkCount,
  freshAutoCount: state.itemPrices.freshAutoCount,
  ambientAutoCount: state.itemPrices.ambientAutoCount,
  storeDevelopmentAutoCount: state.itemPrices.storeDevelopmentAutoCount,
  reeferCount: state.itemPrices.reeferCount,
  reeferDirectShipment: state.itemPrices.reeferDirectShipment,
  reeferAutoShip: state.itemPrices.reeferAutoShip,
  storeDevDirectShip: state.itemPrices.storeDevDirectShip,
  ambient: state.itemPrices.ambient,
  autoshipAmbient: state.itemPrices.autoshipAmbient,
  directShipmentAmbient: state.itemPrices.directShipmentAmbient,
  promotional: state.itemPrices.promotional,
  storeDevelopment: state.itemPrices.storeDevelopment,
  reefer: state.itemPrices.reefer,
  directShipmentReefer: state.itemPrices.directShipmentReefer,
  autoshipReefer: state.itemPrices.autoshipReefer,
  directShipmentStoreDevelopment: state.itemPrices.directShipmentStoreDevelopment,
  retailSupply: state.itemPrices.retailSupply,
  preparedFood:state.itemPrices.preparedFood,
  retailSupplyHazmat: state.itemPrices.retailSupplyHazmat,
  promotion: state.itemPrices.promotion,
  directShip: state.itemPrices.directShip,
  storeDevelopmentDirectShip: state.itemPrices.storeDevelopmentDirectShip,
  retailSupplyAutoship: state.itemPrices.retailSupplyAutoship,
  preparedFoodAutoship: state.itemPrices.preparedFoodAutoship,
  retailSupplyHazmatAutoship: state.itemPrices.retailSupplyHazmatAutoship,
  directShipAutoship: state.itemPrices.directShipAutoship,
  tenantKey: state.auth.tenant,
});

const mapDispatchToProps = dispatch => {
  return {
    getItems: (storeNumber, filter, page, pageSize, currentTab) =>
      dispatch(
        itemPriceActions.requestItemPrices(
          storeNumber,
          filter,
          page,
          pageSize,
          currentTab,
        ),
      ),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemPriceList);
