import React, { useState } from 'react';
import PeriodFilter from '../../../components/ordertracker/components/PeriodFilter/PeriodFilter';


function CsrFilters(props){
  const [filtersExpanded, setFiltersExpanded] = useState(false);
  
  return <div className="floating-panel">
    <div className='csr-filter-panel-header'>
      <h3>Filters</h3>
      <div
        onClick={() => setFiltersExpanded(!filtersExpanded)}
        className={`icon ${filtersExpanded ? 'icon-expand' : 'icon-collapse'
        }`}></div>
    </div>
    {filtersExpanded &&
    <>
      <div className='csr-filters'>
        <PeriodFilter
          onChange={(e) => props.handleFilterInputUpdate(e, 'orderDate')}
          value={props.filterOrders.orderDate} />
        <label>
          MBU
          <select
            value={props.filterOrders.mbu}
            onChange={e => props.handleFilterInputUpdate(e, 'mbu')}
          >
            <option value=''>All</option>
            {props.filters && props.filters.mbus && props.filters.mbus.length && props.filters.mbus.map(o => <option key={o.key}
              value={o.key}>{o.value}</option>,
            )}
          </select>
        </label>
        <label>
          Region
          <select
            value={props.filterOrders.region}
            onChange={e => props.handleFilterInputUpdate(e, 'region')}
          >
            <option value=''>All</option>
            {props.filters && props.filters.regions && props.filters.regions.length && props.filters.regions.map(o => <option key={o.key}
              value={o.key}>{o.value}</option>,
            )}
          </select>
        </label>
        <label>
          Port of Discharge
          <select
            value={props.filterOrders.portOfDischarge}
            onChange={e => props.handleFilterInputUpdate(e, 'portOfDischarge')}
          >
            <option value=''>All</option>
            {props.filters && props.filters.portsOfDischarge && props.filters.portsOfDischarge.length && props.filters.portsOfDischarge.map(o => <option key={o.key}
              value={o.key}>{o.value}</option>,
            )}
          </select>
        </label>
        <label>
          Port of Loading
          <select
            value={props.filterOrders.portOfLoading}
            onChange={e => props.handleFilterInputUpdate(e, 'portOfLoading')}
          >
            <option value=''>All</option>
            {props.filters && props.filters.portsOfLoading && props.filters.portsOfLoading.length && props.filters.portsOfLoading.map(o => <option key={o.key}
              value={o.key}>{o.value}</option>,
            )}
          </select>
        </label>
        <label>
          Place of Delivery
          <select
            value={props.filterOrders.placeOfDelivery}
            onChange={e => props.handleFilterInputUpdate(e, 'placeOfDelivery')}
          >
            <option value=''>All</option>
            {props.filters && props.filters.portsOfDelivery && props.filters.portsOfDelivery.length && props.filters.portsOfDelivery.map(o => <option key={o.key}
              value={o.value}>{o.value}</option>,
            )}
          </select>
        </label>
        <label>
          RTS
          <input
            type="date"
            onChange={e => props.handleFilterInputUpdate(e, 'rts')}
            value={props.filterOrders.rts} />
        </label>
        <label>
          Promise Date
          <input
            type="date"
            onChange={e => props.handleFilterInputUpdate(e, 'promiseDate')}
            value={props.filterOrders.promiseDate} />
        </label>
        <label>
          Form D / E Recieve Date
          <input
            type="date"
            onChange={e => props.handleFilterInputUpdate(e, 'formRecieveDate')}
            value={props.filterOrders.formRecieveDate} />
        </label>
        <label>
          ETD
          <input
            type="date"
            onChange={e => props.handleFilterInputUpdate(e, 'etd')}
            value={props.filterOrders.etd} />
        </label>
        <label>
          COB
          <input
            type="date"
            onChange={e => props.handleFilterInputUpdate(e, 'cob')}
            value={props.filterOrders.cob} />
        </label>
        <label>
          ETA
          <input
            type="date"
            onChange={e => props.handleFilterInputUpdate(e, 'eta')}
            value={props.filterOrders.eta} />
        </label>
        <label>
          Ship Mode
          <select
            value={props.filterOrders.shipMode}
            onChange={e => props.handleFilterInputUpdate(e, 'shipMode')}
          >
            <option value=''>All</option>
            {props.filters && props.filters.shipModes && props.filters.shipModes.length && props.filters.shipModes.map(o => <option key={o.key}
              value={o.key}>{o.value}</option>,
            )}
          </select>
        </label>
        <label>
          Source
          <input
            type="text"
            onChange={e => props.handleFilterInputUpdate(e, 'source')}
            value={props.filterOrders.source} />
        </label>
        <label>
          Promotion
          <select
            value={props.filterOrders.promotion}
            onChange={e => props.handleFilterInputUpdate(e, 'promotion')}
          >
            <option value=''>All</option>
            <option key={1}
              value={1}>{'Yes'}</option>
            <option key={2}
              value={2}>{'No'}</option>,
          </select>
        </label>
        <label>
          SO #
          <input
            type="text"
            onChange={e => props.handleFilterInputUpdate(e, 'soNumber')}
            value={props.filterOrders.soNumber} />
        </label>
        <label>
          PO # / DID #
          <input
            type="text"
            onChange={e => props.handleFilterInputUpdate(e, 'poNumber')}
            value={props.filterOrders.poNumber} />
        </label>
        <label>
          Supplier
          <select
            value={props.filterOrders.supplier}
            onChange={e => props.handleFilterInputUpdate(e, 'supplier')}
          >
            <option value=''>All</option>
            {props.filters && props.filters.suppliers && props.filters.suppliers.length && props.filters.suppliers.map(o => <option key={o.key}
              value={o.value}>{o.value}</option>,
            )}
          </select>
        </label>
        <label>
          Event Code
          <select
            value={props.filterOrders.eventCode}
            onChange={e => props.handleFilterInputUpdate(e, 'eventCode')}
          >
            <option value=''>All</option>
            {props.filters && props.filters.eventCodes && props.filters.eventCodes.length && props.filters.eventCodes.map(o => <option key={o.key}
              value={o.key}>{o.value}</option>,
            )}
          </select>
        </label>
      </div><div className='btn-group'>
        <button
          onClick={() => props.doFilter()}
          type="button"
          className="action default"
        >
            Filter
        </button>
      </div>
    </>
    }
  </div>;
}
export default CsrFilters;