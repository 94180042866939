import React from 'react';
import { connect } from 'react-redux';
import { connectTaskActions } from '../../../../actions/connectTaskActions';
import { navigationActions } from '../../../../actions/navigationActions';
import TaskView2 from '../TaskView2/TaskView2';
import ConfirmationModal from '../../../shared/ConfirmationModal';

class Tasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      showModal:false,
    };
  }
  componentDidMount() {
    this.props.requestTasks();

    //For animation
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 500);
  }

  handleComplete(platformNotificationType, type, popupId) {
    this.setState({
      platformNotificationType, 
      type, 
      popupId,
      showModal:true });
  }

  complete(){
    const { platformNotificationType,
      type,
      popupId } = this.state;

    this.props.completeTask(
      platformNotificationType,
      type,
      popupId,
    );

      
    this.setState({
      showModal:false,
    });
  }

  handleNavigate(actionLink) {
    if (!actionLink) return;

    if (actionLink.toLowerCase().startsWith('http')) {
      window.open(actionLink);
    } else {
      this.props.push(actionLink);
    }
  }

  getFuzzyDate(dateString) {
    const now = new Date();
    const then = new Date(dateString);

    if (now.getFullYear() > then.getFullYear()) {
      if (now.getFullYear() - then.getFullYear() === 1) {
        return 'Last year';
      } else {
        return parseInt(now.getFullYear() - then.getFullYear()) + ' years ago';
      }
    } else if (now.getMonth() > then.getMonth()) {
      if (now.getMonth() - then.getMonth() === 1) {
        return 'Last month';
      } else {
        return parseInt(now.getMonth() - then.getMonth()) + ' months ago';
      }
    } else if (now.getDate() > then.getDate()) {
      if (now.getDate() - then.getDate() === 1) {
        return 'Yesterday';
      } else if (parseInt((now.getDate() - then.getDate()) / 7) === 1) {
        return 'Last week';
      } else if (parseInt((now.getDate() - then.getDate()) / 7) > 1) {
        return parseInt((now.getDate() - then.getDate()) / 7) + ' weeks ago';
      } else {
        return parseInt(now.getDate() - then.getDate()) + ' days ago';
      }
    } else {
      return '';
    }
  }

  getVagueDate(dateString) {
    const date = new Date();
    const now = date.getTime() + date.getTimezoneOffset() * 60 * 1000;
    const then = new Date(dateString).getTime();
    const difference = now - then;
    const minute = 1000 * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const week = day * 7;
    const month = day * 30;
    if (difference < minute * 30) {
      //Last 30 minutes
      return 'Just now';
    } else if (difference < minute * 60) {
      return 'Last Hour';
    } else if (difference / hour < 24) {
      return parseInt(difference / hour) + ' hours ago';
    } else if (difference / day < 7) {
      return parseInt(difference / day) + ' days ago';
    } else if (difference / week < 5) {
      return parseInt(difference / week) + ' weeks ago';
    } else {
      return parseInt(difference / month) + ' months ago';
    }
  }

  handleGenerateMessageList() {
    const { allPopups } = this.props;
    const popups = [];

    let previousFuzzy = '';
    for (let i = 0; i < allPopups.length; i++) {
      let fuzzyDate = this.getFuzzyDate(allPopups[i].sentOn);
      let vagueDate = this.getVagueDate(allPopups[i].sentOn);

      if (i !== 0) {
        popups.push(
          <div className="connector-holder">
            <div className="connector"></div>
          </div>,
        );
      }

      if (previousFuzzy !== fuzzyDate) {
        popups.push(<div className="date-pointer">{fuzzyDate}</div>);
      }

      previousFuzzy = fuzzyDate;

      popups.push(
        <TaskView2
          key={allPopups[i].recipientId + '-' + allPopups[i].notificationType}
          data={allPopups[i]}
          vagueDate={vagueDate}
          navigate={() => this.handleNavigate(allPopups[i].actionLink)}
          acknowledge={() =>
            this.handleComplete(
              allPopups[i].platformNotificationType,
              allPopups[i].notificationType,
              allPopups[i].recipientId,
            )
          }
        />,
      );
    }

    return popups;
  }

  render() {
    return (
      <div
        className={`tasks-page page ${this.state.loaded ? 'loaded' : ''}`}
      >
        <div className="tasks-header">
          <div className="logo-stack">
            <div className="icon icon-messages"></div>
            <h2>Task Archive</h2>
          </div>
        </div>
        <div className="tasks-list">
          {(!this.props.allPopups || this.props.allPopups.length === 0) && (
            <p> You don't have any tasks at this time.</p>
          )}
          {this.handleGenerateMessageList()}
        </div>
        
        {this.state.showModal && <ConfirmationModal
          title="Complete Task"
          message="By proceeding you are confirming that you have undertaken and successfully complete the task described. Are you sure you wish to continue?"
          continue={() => this.complete()}
          cancel={() => this.setState({ showModal:false })}
        />}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  allPopups: state.connectTask.allPopups,
});

const mapDispatchToProps = dispatch => {
  return {
    // dispatching plain actions
    requestTasks: () =>
      dispatch(connectTaskActions.requestTasks(100, null)),

    completeTask: (platformNotificationType, type, popupId) =>
      dispatch(
        connectTaskActions.completeTask(
          platformNotificationType,
          type,
          popupId,
        ),
      ),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
