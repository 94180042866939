import React from 'react';
import { connect } from 'react-redux';
import Tile from '../../shared/tile';
import MessageModal from '../../shared/MessageModal';
import { moduleActions } from '../../../actions/moduleActions';
import { specialActions } from '../../../actions/specialActions';
import { navigationActions } from '../../../actions/navigationActions';
import { authActions } from '../../../actions/authActions';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      showMessage: false,
      message: '',
      title: '',
    };

    this.handleModuleNavigation = this.handleModuleNavigation.bind(this);
    this.handleMessageShow = this.handleMessageShow.bind(this);
    this.handleMessageHide = this.handleMessageHide.bind(this);
  }

  componentDidMount() {
    let { moduleId } = this.props.match.params;

    if (!moduleId) {
      this.props.getModules();
    } else {
      this.props.getModules(moduleId);
    }
  }

  componentDidUpdate(prevProps) {
    let { loading, loaded } = this.props;
    let currentModuleId = this.props.match.params.moduleId;
    let previousModuleId = prevProps.match.params.moduleId;

    if (previousModuleId !== currentModuleId && !loading) {
      //We have changed screen
      //load the data
      //this.setState({ loaded: false });

      if (!currentModuleId) {
        this.props.getModules();
        this.props.reset();
      } else {
        this.props.getModules(currentModuleId);
      }
    }

    if (!prevProps.loaded && loaded) {
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 300);
    }
  }

  handleMessageShow(message, title) {
    this.setState({ showMessage: true, message, title });
  }

  handleMessageHide() {
    this.setState({ showMessage: false, message: '', title: '' });
  }

  handleModuleNavigation(type, link, moduleId) {
    let currentModuleId = this.props.match.params.moduleId;
    switch (type) {
    case 1:
    case 5:
      if (!moduleId) {
        this.props.reset();
      } else {
        this.props.push(`/module/${moduleId}`);
      }
      break;
    case 2: //relative internal links
      this.props.push(`/module/${currentModuleId}/${link.toLowerCase()}`);
      break;
    case 3:
      window.open(link);
      break;
    case 4:
    case 6:
      //this.handleMessageShow("Your download has begun.", "Download");
      this.props.downloadFile(link);
      break;
    case 7: //absolute internal links
      this.props.push(`${link.toLowerCase()}`);
      break;
    case 8: //display mystarbucks connect
      this.props.displayConnect();
      break;
    default:
      if (!moduleId) {
        this.props.reset();
      } else {
        this.props.replace(`/module/${moduleId}`);
      }
      break;
    }
  }

  render() {
    const { moduleContents, currentModule, loading, tenantKey } = this.props;
    const { handleModuleNavigation } = this;
    const { loaded } = this.state;
    const isEMEA = tenantKey === 'EMEA';
    if (!currentModule || currentModule === null) {
      //Root
      return (
        <div className="home-page page">
          <h2>Your Modules</h2>

          <div className="menuContainer">
            {!loading && (!moduleContents || moduleContents.length === 0) && (
              <p>
                You don't currently have access to any modules. If you feel this
                is incorrect, please contact your system administrator.
              </p>
            )}
            {moduleContents.map((moduleTile) => (
              <Tile
                key={moduleTile.moduleId.toString() + moduleTile.data}
                data={moduleTile}
                onMenuFolderClicked={handleModuleNavigation}
              />
            ))}
          </div>
          {isEMEA ? (
            <p>
            Starbucks Coffee Company EMEA (“Starbucks”) has agreed to permit
            access to this portal and share certain content on the basis that
            the user complies with the terms of use and that confidential
            content provided on the portal remains confidential and is not
            shared with any third parties without Starbuck’s prior written
            consent. Whilst every care has been taken in preparing the content
            contained on the portal, Starbucks does not guarantee the accuracy
            or currency of the content, and has no duty to update any such
            information. Starbucks cannot be held responsible for any errors or
            omissions and accepts no liability whatsoever for any loss or damage
            howsoever arising. Starbucks is not liable for any damages arising
            in contract or otherwise from the use of or inability to use any
            content provided or to access the portal, or from any action,
            inaction or decision taken as a result of using the content or
            accessing the portal. Whilst every effort is made to ensure that the
            portal and content therein is free from viruses, Starbucks cannot
            accept any liability for damages resulting from virus infection.
            </p>
          ): null } {//TODO ADD APAC SPECIFIC INFO HERE}
          }
          {this.state.showMessage && (
            <MessageModal
              cancel={this.handleMessageHide}
              title={this.state.title}
              message={this.state.message}
            />
          )}
        </div>
      );
    } else {
      //Sub level
      return (
        <div className="home-page page">
          <div className={`floating-panel ${loaded ? 'loaded' : ''}`}>
            <div className="floating-header">
              <div className="header-title">
                <div
                  className={`icon ${currentModule.iconData.toLowerCase()}`}></div>
                <div>
                  <h2>{currentModule.title}</h2>
                  {currentModule.description && (
                    <p>{currentModule.description}</p>
                  )}
                </div>
              </div>
            </div>
            {currentModule.moduleId === 369 && (
              <div>
                <p>Starbucks Global Retail Standards Library is hosted on Confluence. The Library is a centralized location for Licensee Partners worldwide to access Starbucks Global Standards under the Global Licensing Framework. If you have access to the Starbucks Global Retail Standards Library, this link will take you directly to the login page.</p>
              </div>
            )}
            {currentModule.moduleId === 370 && (
              <div>
                <p>Starbucks Specification System is hosted on NT Assure. The Library is a centralized location for Licensee Partners to access and download productspecifications as well as key documentation on the product. If you have access to NT assure system, this link will take you directly to the login page.</p>
              </div>
            )}
            <div className="menu-container">
              {!loading && (!moduleContents || moduleContents.length === 0) && (
                <p>
                  There is currently no content available within this module.
                </p>
              )}
              {moduleContents.map((moduleTile) => (
                <Tile
                  key={moduleTile.moduleId.toString() + moduleTile.data}
                  data={moduleTile}
                  onMenuFolderClicked={handleModuleNavigation}
                />
              ))}
            </div>
            {currentModule.moduleId === 354 && (
              <div>
                <p>
                  Starbucks has agreed to share these materials for information
                  purposes only, and on the basis that they remain confidential
                  and are not shared with any third parties. Whilst every care
                  has been taken in preparing the information contained herein,
                  Starbucks does not guarantee the accuracy or currency of the
                  content, and has no duty to update any such materials.
                  Starbucks cannot be held responsible for any errors or
                  omissions and accepts no liability whatsoever for any loss or
                  damage howsoever arising. Starbucks is not liable for any
                  damages arising in contract, tort or otherwise from the use of
                  or inability to use any materials provided, or from any action
                  or decision taken as a result of using the materials. The
                  materials do not constitute legal or other professional
                  advice. You should consult your professional adviser for legal
                  or other advice, including but not limited to in relation to
                  local health and safety laws, to ensure compliance.
                </p>
                <p>
                  Where the materials refer to links to other sites, Starbucks
                  is not responsible for, nor does it endorse, the content of
                  any linked site or any link in a linked site. Starbucks takes
                  no responsibility for any loss or damage suffered as a result
                  of using the linked websites or as a result of using the
                  information published on any of the pages of the linked
                  websites. Whilst every effort is made to ensure content is
                  free from viruses, Starbucks cannot accept any liability for
                  damages resulting from virus infection.
                </p>
              </div>
            )}
          </div>
          {this.state.showMessage && (
            <MessageModal
              cancel={this.handleMessageHide}
              title={this.state.title}
              message={this.state.message}
            />
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  loading: state.module.isFetching,
  loaded: state.module.isLoaded,
  currentModule: state.module.currentModule,
  moduleContents: state.module.moduleContents,
  tenantKey: state.auth.tenant,
});

const mapDispatchToProps = dispatch => {
  return {
    getModules: moduleId => dispatch(moduleActions.requestModule(moduleId)),
    downloadFile: url => dispatch(specialActions.downloadFile(url)),
    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    displayConnect: () => dispatch(authActions.displayConnect()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Home);
