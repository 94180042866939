import React from 'react';
import { connect } from 'react-redux';
import ProgressState from '../components/ProgressState/ProgressState';
import './ShippingDetails.scss';
import apacOrderTrackerActions from '../../../actions/apacOrderTrackerActions';
import { navigationActions } from '../../../actions/navigationActions';
import OrderAudit from '../OrderAudit/OrderAudit';

class ShippingDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: {},
      orderType: '',
    };

    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);
    this.saveShipmentDetail = this.saveShipmentDetail.bind(this);
  }

  componentDidMount(){
    const { match } = this.props;
    let id = match.params.trackingNumber;
    this.props.getOrder(id);
  }
   
  componentDidUpdate(prevProps){
    if(this.props.isLoaded && !prevProps.isLoaded){
      this.setState({
        order: this.props.orders,
        orderType: this.props.orderTypes.find(o => o.key == this.props.orders.orderTypeId)?.value,
        shipmentType: this.props.shipmentTypes.find(s => s.key == this.props.orders.shipmentTypeId)?.value,
      });
    }

    if (this.props.isSaved && !prevProps.isSaved) {
      this.props.back();
    }
  }

  saveShipmentDetail() {
    const { order } = this.state;
    let data = {
      TrackingNumber: order.trackingNumber,
      PromiseDate: order.promiseDate,
      PortOfLoading: order.portOfLoading,
      Region: order.region,
      ShipmentMethod: order.shipMethod,
      Carrier: order.carrier,
      PortOfDischarge: order.portOfDischarge,
      PlaceOfDelivery: order.placeOfDelivery,
      FtaFormReceiptDate: order.ftaFormReceiptDate,
      TemperatureControlConditionId: order.temperatureControlConditionId,
      ContainerNumber: order.containerNumbers,
      Masterbill: order.masterBill,
      Etd: order.etd,
      Cob: order.cob,
      Eta: order.eta,
      OrderStatusId: order.orderStatusId,
      Comments: order.comments,
      RtsDate: order.rtsDate,
      ShipmentsSplit: order.shipmentsSplit,
      ShipmentsSplitComments: order.shipmentsSplitComments,
      ShipmentsConsolidated: order.shipmentsConsolidated,
      ShipmentsConsolidatedComments: order.shipmentsConsolidatedComments,
      OrderTypeId: order.orderTypeId,
      ShipmentTypeId: order.shipmentTypeId,
      LtoOrder: order.ltoOrder === '1',
      _3pl: order._3plId,
    };

    this.props.saveOrder(data);
  }

  handleTextInputUpdate(e, fieldName) {
    this.setState({ 
      order: { ...this.state.order, [fieldName]: e.target.value },
    });
  }

  handleCheckInputUpdate(e, fieldName) {
    this.setState({ 
      order: { ...this.state.order, [fieldName]: e.target.checked },
    });
  }

  getMappedFulfillmentCenter(orgCode) {
    switch (orgCode){
    case 'FCS':
      return 'Singapore';
    case 'F22':
      return 'Auburn';
    case 'F26':
      return 'Carson Valley';
    case 'F27':
      return 'York';
    case 'PHM':
      return 'HACO Malaysia';
    case 'FCT':
      return 'Japan Roastery';
    case 'PHJ':
      return 'Hamaya Japan';
    case 'ZDF':
      return 'Japan Local Roasting';
    case 'ZD5':
      return 'Direct Shipment Supplier';
    case 'F81':
      return 'Futian Store Development China';
    }

    return '';
  }

  render() {
    const {
      isSaved,
      isSaving,
      errorMessage,
      tempControls,
      orderStatuses,
      orderTypes,
      shipmentTypes,
      isAdmin,
      canEdit,
      items,
      recievedRequiredDocumentTypes,
      requiredDocumentTypes,
      documentTypes,
      mbu,
    } = this.props;

    const { order } = this.state;

    let isLAC = this.props.tenant == 'LAC';
    let isPromotionalOrder = order.orderTypeId === 7 ? true : false;
    let isDirectShipOrder = order.orderTypeId === 6 || order.orderTypeId === 16 ? true : false;

    return (
      <div className="shipping-details page">
        <div className="form-container">
          <div className="form-header">
            <div className="header-title">
              <div className="icon icon-form"></div>
              <h2>Shipping Details</h2>
            </div>
            <div className="header-buttons">
              
              <button className="action"
                type="button"
                onClick={() => this.saveShipmentDetail()}>
                {isSaving
                  ? 'Saving...'
                  : isSaved
                    ? 'Saved'
                    : 'Save'}
              </button>
              <p>{errorMessage}</p>
            </div>
          </div>
        </div>
        {order &&
          <>
            <div 
              className="form-container">
              <h4 className="header-shipping">Shipping Details</h4>
         
              <div className="form-body">
                <label>
              Shipment Type
                  <select
                    value={order.shipmentTypeId}
                    disabled={!canEdit}
                    onChange={e => this.handleTextInputUpdate(e, 'shipmentTypeId')}
                  >
                    <option value=''>N/A</option>
                    {shipmentTypes && shipmentTypes.length > 0 && shipmentTypes.map(t => 
                      <option key={t.key} 
                        value={t.key}>{t.value}</option>,
                    )}
                  </select>
                </label>
                <label>
              3PL
                  <select
                    value={order._3plId}
                    disabled={isLAC || !isAdmin}
                    onChange={e => this.handleTextInputUpdate(e, '_3plId')}
                  >
                    <option value=''>N/A</option>
                    <option value='1'>Expeditors</option>
                    <option value='2'>DSV</option>
                    <option value='3'>Nissin</option>
                    <option value='4'>YCH</option>
                    <option value='5'>DHL</option>
                    <option value='999'>Other</option>
                  </select>
                </label>
              </div>
              <div className="form-body">          
                <label>
            MBU
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={mbu}
                  />
                </label>
                <label>
            Customer Number
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.customerNumber}
                  />
                </label>
                <label>
            Forecast Week
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.forecastWeek}
                  />
                </label>
                <label>
            Order Date
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.orderDate ? new Date(order.orderDate).toLocaleDateString() : null}
                  />
                </label>
                <label>
            Order Type
                  <select
                    value={order.orderTypeId}
                    disabled={!canEdit || order.shipmentTypeId != 1}
                    onChange={e => this.handleTextInputUpdate(e, 'orderTypeId')}
                  >
                    <option value=''>N/A</option>
                    {orderTypes && orderTypes.length > 0 && orderTypes.map(t => 
                      <option key={t.key} 
                        value={t.key}>{t.value}</option>,
                    )}
                  </select>
                </label>
              </div>
            </div>
            
            <div className="form-container">
              <h4 className="header-shipping">Tracking Details</h4>

              <div className="form-body">
                <label>
            Starbucks Order Number
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.starbucksOrderNumber}
                  />
                </label>
                <label>
           Delivery ID
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.deliveryId}
                  />
                </label>
                <label>
            ORG
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.org}
                  />
                </label>
                {order.org ? <label>
           Fulfilment Centre
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    hidden
                    value={this.getMappedFulfillmentCenter(order.org)}
                  />
                </label> : null}
                <label>
            ISO Destination ORG
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.isoDestinationOrg}
                  />
                </label>
                <label>
            Tracking Number
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.trackingNumber}
                  />
                </label> 
                <label>
           Supplier PO/Release No
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!isDirectShipOrder}
                    value={order.supplierPoNo || order.poReleaseNo}
                  />
                </label>
                <label>
           RTS
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={new Date(order.rtsDate).toLocaleDateString()}
                  />
                </label>
                <label>
           Promise Date
                  <input
                    autoFocus
                    type="date"
                    required
                    disabled={!canEdit}
                    value={order.promiseDate && order.promiseDate.split('T')[0]}
                    onChange={e => this.handleTextInputUpdate(e, 'promiseDate')}
                  />
                </label>
                <label>
            Port Of Loading
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.portOfLoading}
                    onChange={e => this.handleTextInputUpdate(e, 'portOfLoading')}
                  />
                </label>
                <label>
           Region
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.region}
                    onChange={e => this.handleTextInputUpdate(e, 'region')}
                  />
                </label>
                <label>
            Ship Method
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.shipMethod}
                    onChange={e => this.handleTextInputUpdate(e, 'shipMethod')}
                  />
                </label>
                <label>
            Carrier
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.carrier}
                    onChange={e => this.handleTextInputUpdate(e, 'carrier')}
                  />
                </label>
                <label>
            Port Of Discharge
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.portOfDischarge}
                    onChange={e => this.handleTextInputUpdate(e, 'portOfDischarge')}
                  />
                </label>
                <label>
            Place Of Delivery
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.placeOfDelivery}
                    onChange={e => this.handleTextInputUpdate(e, 'placeOfDelivery')}
                  />
                </label>
                <label>
           FTA Form Receipt Date
                  <input
                    autoFocus
                    type="date"
                    required
                    disabled={!canEdit}
                    value={order.ftaFormReceiptDate && order.ftaFormReceiptDate.split('T')[0]}
                    onChange={e => this.handleTextInputUpdate(e, 'ftaFormReceiptDate')}
                  />
                </label>
                <label>
           Temperature Control Condition
                  <select         
                    disabled={!canEdit}
                    value={order.temperatureControlConditionId} 
                    onChange={e => this.handleTextInputUpdate(e, 'temperatureControlConditionId')}>
                    <option value=''>N/A</option>
                    {tempControls && tempControls.length > 0 && tempControls.map(t => 
                      <option key={t.key} 
                        value={t.key}>{t.value}</option>,
                    )}
                  </select>
                </label>
                <label>
            Container Number
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.containerNumbers}
                    onChange={e => this.handleTextInputUpdate(e, 'containerNumbers')}
                  />
                </label>
                <label>
           Masterbill
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled={!canEdit}
                    value={order.masterBill}
                    onChange={e => this.handleTextInputUpdate(e, 'masterBill')}
                  />
                </label>
                <label>
           CBM
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.cbm}
                  />
                </label>
                <label>
            Net Weight
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.netWeight}
                  />
                </label>
                <label>
           Gross Weight
                  <input
                    autoFocus
                    type="text"
                    required
                    disabled
                    value={order.grossWeight}
                  />
                </label>
                <label>
            ETD
                  <input
                    autoFocus
                    type="date"
                    required
                    disabled={!canEdit}
                    value={order.etd && order.etd.split('T')[0]}
                    onChange={e => this.handleTextInputUpdate(e, 'etd')}
                  />
                </label>
                <label>
            COB
                  <input
                    autoFocus
                    type="date"
                    required
                    disabled={!canEdit}
                    value={order.cob && order.cob.split('T')[0]}
                    onChange={e => this.handleTextInputUpdate(e, 'cob')}
                  />
                </label>
                <label>
           ETA
                  <input
                    autoFocus
                    type="date"
                    required                
                    disabled={!canEdit}
                    value={order.eta && order.eta.split('T')[0]}
                    onChange={e => this.handleTextInputUpdate(e, 'eta')}
                  />
                </label>
                <label>
            Days Delayed
                  <input
                    autoFocus
                    type="number"
                    required
                    disabled
                    value={order.daysDelayed ?? 0}
                  />
                </label>
              </div>

              <div className="form-body">
                <label>
          Order Status
                  <select
                    value={order.orderStatusId}
                    disabled={!canEdit}
                    onChange={e => this.handleTextInputUpdate(e, 'orderStatusId')}
                  >
                    <option value=''>N/A</option>
                    {orderStatuses && orderStatuses.length > 0 && orderStatuses.map(t => 
                      <option key={t.key} 
                        value={t.key}>{t.value}</option>,
                    )}
                  </select>
                </label>
                <label>
        Progress
                  <ProgressState
                    progressStage={order.orderStatusId}
                    numberOfBoxes={6}
                  />
                </label>
              </div>
          
            </div>
            <div className="form-container">
              <h4 className="header-shipping">Comments</h4>
              <div className="form-body">
                <label className="comments">
                    Comments
                  <textarea
                    autoComplete="off"
                    disabled={!canEdit}
                    value={order.comments}
                    onChange={e => this.handleTextInputUpdate(e, 'comments')}

                  />
                </label>
                <label className="comments split">
                    Shipment Split
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      checked={order.shipmentsSplit}
                      disabled={!canEdit}
                      onChange={e => this.handleCheckInputUpdate(e, 'shipmentsSplit')}
                    />
                    <div className="toggle-icon"></div>
                  </div>
                  {order.shipmentsSplit &&
                   <textarea
                     autoComplete="off"
                     disabled={!canEdit}
                     value={order.shipmentsSplitComments}
                     onChange={e => this.handleTextInputUpdate(e, 'shipmentsSplitComments')}
                   />
                  }
                </label>
                <label className="comments">
                    Shipment Consolidated
                  <div className="toggle-wrapper">
                    <input
                      type="checkbox"
                      className="toggle"
                      disabled={!canEdit}
                      checked={order.shipmentsConsolidated} 
                      onChange={e => this.handleCheckInputUpdate(e, 'shipmentsConsolidated')}

                    />
                    <div className="toggle-icon"></div>
                  </div>
                  {order.shipmentsConsolidated &&
                   <textarea
                     disabled={!canEdit}
                     value={order.shipmentsConsolidatedComments}
                     onChange={e => this.handleTextInputUpdate(e, 'shipmentsConsolidatedComments')}
                     autoComplete="off"
                   />
                  }
                </label>
              </div>
            </div>
            <div className="form-container">
              <h4 className="header-shipping">Mandatory Documents</h4>
              <div className="form-body">
                {requiredDocumentTypes && requiredDocumentTypes.length > 0 && requiredDocumentTypes.map(r =>
                  <label key={r}>
                    {documentTypes.find(d => d.documentTypeId == r)?.name}
                    <div  
                      className="toggle-wrapper">
                      <input
                        type="checkbox"
                        className="toggle"
                        checked={recievedRequiredDocumentTypes.includes(r)}
                        disabled
                      />
                      <div className="toggle-icon"></div>
                    </div>
                  </label>,
                )}
                <button
                  className="action"
                  type="button"
                  onClick={() => this.props.navigate(`/module/233/AOT-OrderDocuments/${order.trackingNumber}`)}>
                        View Documents
                </button>
              </div>
            </div>
            <div style={{ marginTop:'20px' }}>
              <div className="table-container">
                <div className="table-header">
                    
                  <h4 className="header-shipping">Item Details</h4>
                </div>
                <div className="table-body">
                  <table className={'table-display-mode-2'}>
                    <thead>
                      <tr>
                        <th>Supplier Name</th>
                        <th>Item Number</th>
                        <th>Item Description</th>
                        <th>Order Qty</th>
                        <th>Shipped Qty</th>
                        <th>UOM</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order && order.lines &&  order.lines.length > 0 && order.lines.map(i => 
                        <tr key={i.itemNumber}>
                          <td>{i.supplierName}</td>
                          <td>{i.itemNumber}</td>
                          <td>{i.itemDescription}</td>
                          <td>{i.orderQuantity}</td>
                          <td>{i.shipConfirmedQuantity}</td>
                          <td>{i.unitOfMeasurement}</td>
                        </tr>,
                      )}
              
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <OrderAudit trackingNumber={order.trackingNumber} />
          </>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSaved: state.apacOrderTracker.isSaved,
    isSaving: state.apacOrderTracker.isSaving,
    isAdmin: state.apacOrderTracker.isAdmin,
    canEdit: state.apacOrderTracker.canEdit,
    orders: state.apacOrderTracker.shipmentDetail,
    isLoading: state.apacOrderTracker.isLoading,
    isLoaded: state.apacOrderTracker.isLoaded,
    errorMessage: state.apacOrderTracker.errorMessage,
    mbu: state.apacOrderTracker.mbu,
    orderStatuses: state.apacOrderTracker.orderStatuses,
    orderTypes: state.apacOrderTracker.orderTypes,
    tempControls: state.apacOrderTracker.tempControls,
    shipmentTypes: state.apacOrderTracker.shipmentTypes,
    recievedRequiredDocumentTypes: state.apacOrderTracker.recievedRequiredDocumentTypes,
    requiredDocumentTypes: state.apacOrderTracker.requiredDocumentTypes,
    documentTypes: state.apacOrderTracker.documentTypes,
    tenant : state.auth.tenant,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrder: id => dispatch(apacOrderTrackerActions.getShipmentDetail(id)),
    saveOrder: data => dispatch(apacOrderTrackerActions.saveShipmentDetail(data)),
    back: () => dispatch(navigationActions.backNavigation('')),
    navigate: (path) => dispatch(navigationActions.pushNavigation(path)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShippingDetails);
