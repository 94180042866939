import React from 'react';
import { connect } from 'react-redux';
import { navigationActions } from '../../../../actions/navigationActions';
import { connectTaskActions } from '../../../../actions/connectTaskActions';
import DeleteModal from '../../../shared/DeleteModal';
import { Tooltip } from '@material-ui/core';

class TaskEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      //Task details
      taskDetailId: null,
      title: '',
      body: '',
      actionLink: '',
      taskDetailType: 1,

      sentOn: new Date(),
      sentFrom: 'Admin',
      sentBy: '',

      validFrom: undefined,
      validTo: undefined,

      //Audience details
      audienceMode: 'all',
      storeGroupId: undefined,
      storeNumber: undefined,
      targetGroups: [],
      targetStores: [],
      targetMBUs: [],
      //Reminder details
      dueDate: undefined,
      reminder: false,
      reminderTitle: '',
      reminderBody: '',

      storeGroups: [],
      //mbuUsers: [],
      detailsExpanded: true,
      settingsExpanded: false,

      showDeleteModal: false,
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleTextInputUpdate = this.handleTextInputUpdate.bind(this);
    this.handleCheckInputUpdate = this.handleCheckInputUpdate.bind(this);

    this.handleToggleExpander = this.handleToggleExpander.bind(this);

    this.handleDeleteModalShow = this.handleDeleteModalShow.bind(this);
    this.handleDeleteModalHide = this.handleDeleteModalHide.bind(this);

    this.handleAudienceAddition = this.handleAudienceAddition.bind(this);
    this.handleAudienceRemoval = this.handleAudienceRemoval.bind(this);
  }

  componentDidMount() {
    let taskId = this.props.match.params.taskId;
    this.props.getTask(taskId || 0);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isLoaded && !prevProps.isLoaded) {

      let notification = this.props.taskDetails.notification;
      let taskDetails = this.props.taskDetails;

      let newState = {};

      if (notification) {

        let audienceMode = 'all';
        let targetGroups = [];
        let targetStores = [];
        //let targetMBUs = [];

        if (notification.allCustomers) {
          audienceMode = 'all';
        } else if (notification.licenseeOnly) {
          audienceMode = 'licensee';
        } else if (notification.retailOnly) {
          audienceMode = 'retail';
        } else if (notification.taskDetailTargetStoreGroupIds && notification.taskDetailTargetStoreGroupIds.length > 0) {
          audienceMode = 'groups';
          targetGroups = notification.taskDetailTargetStoreGroupIds;
        } else if (notification.taskDetailTargetStoreIds && notification.taskDetailTargetStoreIds.length > 0) {
          audienceMode = 'stores';
          targetStores = notification.taskDetailTargetStoreIds;
        /*} else if (notification.taskDetailTargetMBUIds && notification.taskDetailTargetMBUIds.length > 0) {
          audienceMode = 'mbuUsers';
          targetMBUs = notification.taskDetailTargetMBUIds;*/
        } else {
          audienceMode = 'none';
        }

        newState = {
          taskDetailId: notification.taskDetailId,
          title: notification.title,
          body: notification.body,
          actionLink: notification.actionLink,
          notificationType: notification.notificationType,
          sentOn: notification.sentOn,
          sentFrom: notification.sentFrom,
          sentBy: notification.sentBy,
          validFrom: notification.validFrom
            ? new Date(notification.validFrom).toLocaleDateString('en-CA')
            : undefined,
          validTo: notification.validTo
            ? new Date(notification.validTo).toLocaleDateString('en-CA')
            : undefined,
          dueDate: notification.dueDate
            ? new Date(notification.dueDate).toLocaleDateString('en-CA')
            : undefined,
          taskType: notification.taskType,
          audienceMode: audienceMode,
          targetGroups: targetGroups,
          targetStores: targetStores,
          // targetMBUs: targetMBUs,
          reminder: notification.reminder,
          reminderBody: notification.reminderBody,
          reminderTitle: notification.reminderTitle,
        };
      }

      this.setState({
        ...newState,
        moduleGroups: taskDetails.moduleGroups,
        storeGroups: taskDetails.storeGroups,
        //mbuUsers: taskDetails.mbuUsers,
      });
    } else if (this.props.isSaved && !prevProps.isSaved) {

      this.props.goBack();

    } else if (
      this.props.isdeleted &&
      !prevProps.isdeleted
    ) {
      this.props.goBack();
    }
  }

  handleSave(e) {
    let { saveTask } = this.props;
    let {
      taskDetailId,
      title,
      body,
      actionLink,
      taskDetailType,
      sentFrom,
      validFrom,
      validTo,

      dueDate,
      reminder,
      reminderTitle,
      reminderBody,

      audienceMode,

      targetStores,
      targetGroups,
      //targetMBUs,
    } = this.state;

    saveTask({
      taskDetailId,
      title,
      body,
      actionLink,
      taskDetailType,
      sentFrom,
      validFrom: validFrom ? new Date(validFrom).toISOString() : null,
      validTo: validTo ? new Date(validTo).toISOString() : null,

      dueDate: dueDate? new Date(dueDate).toISOString(): null,
      reminder,
      reminderTitle,
      reminderBody,

      allCustomers: audienceMode === 'all',
      retailOnly: audienceMode === 'retail',
      licenseeOnly: audienceMode === 'licensee',

      storeGroupIds: audienceMode === 'groups' ? targetGroups : [],
      storeNumbers: audienceMode === 'stores' ? targetStores : [],
      //mbuIds: audienceMode === 'mbuUsers' ? targetMBUs : [],
    });

    e.preventDefault();
  }

  handleDeleteModalShow() {
    this.setState({ showDeleteModal: true });
  }

  handleDeleteModalHide() {
    this.setState({ showDeleteModal: false });
  }

  handleToggleExpander(expanderName) {
    let state = this.state;
    this.setState({ [expanderName]: !state[expanderName] });
  }

  handleDelete() {
    this.props.deleteTask(this.state.taskDetailId);
    this.handleDeleteModalHide();
  }

  handleTextInputUpdate(e, fieldName) {
    let update = {};

    update[fieldName] = e.target.value;

    this.setState(update);
  }

  handleCheckInputUpdate(e, name, id) {
    let ids = this.state[name];

    if (e.target.checked) {
      if (ids.indexOf(id) === -1) {
        ids.push(id);
      }
    } else {
      let pos = ids.indexOf(id);
      if (pos !== -1) {
        ids.splice(pos, 1);
      }
    }

    this.setState({
      [name]: ids,
    });
  }

  handleAudienceAddition(rawVal, audienceType) {
    const { [audienceType]: audienceList } = this.state;

    const value = parseInt(rawVal);

    const index = audienceList.indexOf(value);

    if (index === -1 && !isNaN(value)) {
      audienceList.push(value);

      this.setState({ [audienceType]: [...audienceList] });
    }
  }

  handleAudienceRemoval(rawVal, audienceType) {
    const { [audienceType]: audienceList } = this.state;

    const value = parseInt(rawVal);

    const index = audienceList.indexOf(value);

    if (index > -1 && !isNaN(value)) {
      audienceList.splice(index, 1);

      this.setState({ [audienceType]: [...audienceList] });
    }
  }

  getTaskPanel() {
    let {
      title,
      body,
      actionLink,
      validFrom,
      validTo,
      detailsExpanded,
      sentFrom,
      dueDate,
    } = this.state;


    return (
      <div className="accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('detailsExpanded')}>
          <h3>Task</h3>
          <div
            className={`icon ${detailsExpanded ? 'icon-expand' : 'icon-collapse'
            }`}></div>
        </div>
        <div className={`accordion-body ${detailsExpanded ? '' : 'collapsed'}`}>
          <div className="horizontal-layout">
            <div className="vertical-layout">
              <label>
                Title
                <input
                  placeholder="Title"
                  onChange={e => this.handleTextInputUpdate(e, 'title')}
                  value={title}
                  maxLength={256}
                />
              </label>
              <label>
                Area <Tooltip
                  title={'Sets the label and icon for the task.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <select
                  placeholder="sentFrom"
                  onChange={e => this.handleTextInputUpdate(e, 'sentFrom')}
                  value={sentFrom}>
                  <option value="Admin">Admin</option>
                  <option value="Order Management">Order Management</option>
                  <option value="Customer Service">Customer Service</option>
                  <option value="Item Catalogue">Item Catalogue</option>
                  <option value="Document Library">Document Library</option>
                  <option value="Quality Awareness">Quality Assurance</option>
                  <option value="Pricing Catalogue">Pricing Catalogue</option>
                  <option value="Forecasting">Forecasting</option>
                  <option value="Orderly Inventory">Orderly Inventory</option>
                  <option value="Reports">Reports</option>
                  <option value="Policies">Policies</option>
                </select>
              </label>
              <label>
                Message
                <textarea
                  placeholder="Message"
                  onChange={e => this.handleTextInputUpdate(e, 'body')}
                  maxLength={2000}
                  value={body}
                />
              </label>
            </div>
            {/*<div className="vertical-layout">
              
              <label>
                Who should complete?
                <select
                  placeholder="sentFrom"
                  onChange={e => this.handleTextInputUpdate(e, "taskDetailType")}
                  value={taskDetailType}>
                  <option value="1">Individual Users</option>
                  <option value="2">One User Per Store</option>
                </select>
              </label>
            </div>*/}
            <div className="vertical-layout">
              <label>
                Due Date <Tooltip
                  title={'Sets the date the task should be completed by.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <input
                  placeholder="Valid To"
                  type="date"
                  onChange={e => this.handleTextInputUpdate(e, 'dueDate')}
                  value={dueDate}
                />
              </label>
              <label>
                Action Link <Tooltip
                  title={'Either an internal or external link to additional information. \
                        Internal links should begin with a \'/\' external links should begin with \'https://\'.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <input
                  placeholder="Action Link"
                  onChange={e => this.handleTextInputUpdate(e, 'actionLink')}
                  value={actionLink}
                />
              </label>
              <label>
                Valid From <Tooltip
                  title={'The date the task should become visible from.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <input
                  placeholder="Valid From"
                  type="date"
                  onChange={e => this.handleTextInputUpdate(e, 'validFrom')}
                  value={validFrom}
                />
              </label>
              <label>
                Valid To <Tooltip
                  title={'The date the task should stop being visible.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <input
                  placeholder="Valid To"
                  type="date"
                  onChange={e => this.handleTextInputUpdate(e, 'validTo')}
                  value={validTo}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getAudiencePanel() {
    const {
      audienceMode,
      storeGroupId,
      //key,
      storeGroups,
      //mbuUsers,
      targetStores,
      targetGroups,
      //targetMBUs,
      storeNumber,
      settingsExpanded,
    } = this.state;

    const {
      tenantKey,
    } = this.props;

    const isEMEA = tenantKey === 'EMEA';

    return (
      <div className="accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('settingsExpanded')}>
          <h3>Audience</h3>
          <div
            className={`icon ${settingsExpanded ? 'icon-expand' : 'icon-collapse'
            }`}></div>
        </div>
        <div className={`accordion-body ${settingsExpanded ? '' : 'collapsed'}`}>
          <div className="horizontal-layout">
            <label>
              Target Audience...
              <label><input type="radio"
                name="audienceMode"
                value="all"
                checked={audienceMode === 'all'}
                onChange={(e) => this.setState({ audienceMode: e.target.value })} /> All Connect Users <Tooltip
                title={'Display this task to all connect users.'}>
                <span className="info">&#x1F6C8;</span></Tooltip></label>
              <label><input type="radio"
                name="audienceMode"
                value="retail"
                checked={audienceMode === 'retail'}
                onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Retail Users <Tooltip
                title={'Display this task to only retail users.'}>
                <span className="info">&#x1F6C8;</span></Tooltip></label>
              <label><input type="radio"
                name="audienceMode"
                value="licensee"
                checked={audienceMode === 'licensee'}
                onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Licensee Users <Tooltip
                title={'Display this task to only licensee users.'}>
                <span className="info">&#x1F6C8;</span></Tooltip></label>
              {isEMEA ? (
                <label><input type="radio"
                  name="audienceMode"
                  value="groups"
                  checked={audienceMode === 'groups'}
                  onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Store Groups <Tooltip
                  title={'Display this task to users in any of the selected store groups.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip></label>
              ): 
                <label><input type="radio"
                  name="audienceMode"
                  value="groups"
                  checked={audienceMode === 'groups'}
                  onChange={(e) => this.setState({ audienceMode: e.target.value })} /> MBU Groups <Tooltip
                  title={'Display this task to users in any of the selected MBU groups.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip></label>
              }
              {isEMEA ? (
                <label><input type="radio"
                  name="audienceMode"
                  value="stores"
                  checked={audienceMode === 'stores'}
                  onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Individual Stores <Tooltip
                  title={'Display this task to users with access to any of the selected stores.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip></label>
              ): 
                <label><input type="radio"
                  name="audienceMode"
                  value="stores"
                  checked={audienceMode === 'stores'}
                  onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Individual Customers <Tooltip
                  title={'Display this task to users with access to any of the selected customers.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip></label>
              }
              {/*{!isEMEA &&
                        <label><input type="radio"
                          name="audienceMode"
                          value="mbuUsers"
                          checked={audienceMode === 'mbuUsers'}
                          onChange={(e) => this.setState({ audienceMode: e.target.value })} /> Individual MBU Users <Tooltip
                          title={'Display this content to users with access to any of the selected MBU Users.'}>
                          <span className="info">&#x1F6C8;</span></Tooltip></label>
              }*/}
            </label>
            {audienceMode === 'groups' && isEMEA ?
              <div>

                <label>
                  Store Groups
                  <div className="floating-header">
                    <select
                      placeholder="..."
                      required
                      value={storeGroupId}
                      onChange={e => this.setState({ storeGroupId: e.target.value })}
                    >
                      <option value="">Select one...</option>
                      {storeGroups && storeGroups.map(l =>
                        <option key={l.storeGroupId}
                          value={l.storeGroupId}>{l.storeGroupName}</option>)
                      }
                    </select>
                    <button
                      type="button"
                      className="action"
                      onClick={e => this.handleAudienceAddition(storeGroupId, 'targetGroups')}
                    >
                      Add
                    </button>
                  </div>
                </label>
                <div>

                  {targetGroups && targetGroups.length > 0 && targetGroups.map(g => {

                    let group = storeGroups.find(a => a.storeGroupId.toString() === g.toString());

                    if (group) {
                      return (<label
                        key={group.storeGroupId}
                      >
                        {group.storeGroupName}
                        <button
                          type="button"
                          className="action negative"
                          onClick={e => this.handleAudienceRemoval(group.storeGroupId, 'targetGroups')}
                        >Remove</button>
                      </label>
                      );
                    }

                    return null;
                  },
                  )}
                </div>


              </div> : null}
            {audienceMode === 'groups' && !isEMEA ?
              <div>

                <label>
                  MBU Groups
                  <div className="floating-header">
                    <select
                      placeholder="..."
                      required
                      value={storeGroupId}
                      onChange={e => this.setState({ storeGroupId: e.target.value })}
                    >
                      <option value="">Select one...</option>
                      {storeGroups && storeGroups.map(l =>
                        <option key={l.storeGroupId}
                          value={l.storeGroupId}>{l.storeGroupName}</option>)
                      }
                    </select>
                    <button
                      type="button"
                      className="action"
                      onClick={e => this.handleAudienceAddition(storeGroupId, 'targetGroups')}
                    >
                      Add
                    </button>
                  </div>
                </label>
                <div>

                  {targetGroups && targetGroups.length > 0 && targetGroups.map(g => {

                    let group = storeGroups.find(a => a.storeGroupId.toString() === g.toString());

                    if (group) {
                      return (<label
                        key={group.storeGroupId}
                      >
                        {group.storeGroupName}
                        <button
                          type="button"
                          className="action negative"
                          onClick={e => this.handleAudienceRemoval(group.storeGroupId, 'targetGroups')}
                        >Remove</button>
                      </label>
                      );
                    }

                    return null;
                  },
                  )}
                </div>


              </div> : null}
            {audienceMode === 'stores' && isEMEA ? <div>
              <label>
                Stores
                <div className="floating-header">
                  <input
                    placeholder="..."
                    required
                    type="number"
                    onChange={e => this.setState({ storeNumber: e.target.value })}
                    value={storeNumber}
                  />
                  <button
                    type="button"
                    className="action"
                    onClick={e => this.handleAudienceAddition(storeNumber, 'targetStores')}
                  >
                    Add
                  </button>
                </div>
              </label>

              <div>

                {targetStores && targetStores.length > 0 && targetStores.map(g => {
                  return (<label
                    key={g}
                  >
                    {g}
                    <button
                      type="button"
                      className="action negative"
                      onClick={e => this.handleAudienceRemoval(g, 'targetStores')}
                    >Remove</button>
                  </label>
                  );
                },
                )}
              </div>

            </div> : null}
            {audienceMode === 'stores' && !isEMEA? <div>
              <label>
                Customers
                <div className="floating-header">
                  <input
                    placeholder="..."
                    required
                    type="number"
                    onChange={e => this.setState({ storeNumber: e.target.value })}
                    value={storeNumber}
                  />
                  <button
                    type="button"
                    className="action"
                    onClick={e => this.handleAudienceAddition(storeNumber, 'targetStores')}
                  >
                    Add
                  </button>
                </div>
              </label>

              <div>

                {targetStores && targetStores.length > 0 && targetStores.map(g => {
                  return (<label
                    key={g}
                  >
                    {g}
                    <button
                      type="button"
                      className="action negative"
                      onClick={e => this.handleAudienceRemoval(g, 'targetStores')}
                    >Remove</button>
                  </label>
                  );
                },
                )}
              </div>

            </div> : null}
            {/*{audienceMode === 'mbuUsers' && !isEMEA ?
              <div>

                <label>
                  Individual MBUs
                  <div className="floating-header">
                    <select
                      placeholder="..."
                      required
                      value={key}
                      onChange={e => this.setState({ key: e.target.value })}
                    >
                      <option value="">Select one...</option>
                      {mbuUsers && mbuUsers.map(l =>
                        <option key={l.key}
                          value={l.key}>{l.value}</option>)
                      }
                    </select>
                    <button
                      type="button"
                      className="action"
                      onClick={e => this.handleAudienceAddition(key, 'targetMBUs')}
                    >
                      Add
                    </button>
                  </div>
                </label>
                <div>

                  {targetMBUs && targetMBUs.length > 0 && targetMBUs.map(g => {

                    let group = mbuUsers.find(a => a.key.toString() === g.toString());

                    if (group) {
                      return (<label
                        key={group.key}
                      >
                        {group.value}
                        <button
                          type="button"
                          className="action negative"
                          onClick={e => this.handleAudienceRemoval(group.key, 'targetMBUs')}
                        >Remove</button>
                      </label>
                      );
                    }

                    return null;
                  },
                  )}
                </div>


                </div> : null}*/}
           
          </div>
        </div>
      </div>);
  }

  getReminderPanel() {
    const { settingsExpanded, reminder, reminderBody, reminderTitle } = this.state;

    return (
      <div className="accordion">
        <div
          className="accordion-header"
          onClick={() => this.handleToggleExpander('settingsExpanded')}>
          <h3>Reminders</h3>
          <div
            className={`icon ${settingsExpanded ? 'icon-expand' : 'icon-collapse'
            }`}></div>
        </div>
        <div className={`accordion-body ${settingsExpanded ? '' : 'collapsed'}`}>
          <div className="horizontal-layout">
            <div className="vertical-layout">
              <label>
                Send Reminder <Tooltip
                  title={'Set whether the user should be sent a reminder email if they fail to complete the task by the due date.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <div className="toggle-wrapper">
                  <input
                    type="checkbox"
                    className="toggle"
                    onChange={e =>
                      this.setState({ reminder: !reminder })
                    }
                    checked={reminder}
                  />
                  <div className="toggle-icon"></div>
                </div>
              </label>
              <label>
                Subject <Tooltip
                  title={'The subject displayed for the reminder email.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <input
                  placeholder="Subject..."
                  onChange={e => this.handleTextInputUpdate(e, 'reminderTitle')}
                  value={reminderTitle}
                />
              </label>
              <label>
                Body <Tooltip
                  title={'The text displayed for the reminder email.'}>
                  <span className="info">&#x1F6C8;</span></Tooltip>
                <textarea
                  placeholder="Body..."
                  onChange={e => this.handleTextInputUpdate(e, 'reminderBody')}
                  value={reminderBody}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let { showDeleteModal } = this.state;
    return (
      <div className="edit-task-page page">
        <div className="floating-panel">
          <form noValidate
            onSubmit={e => this.handleSave(e)}>
            <div className="floating-header">
              <div className="header-title">
                <div className="icon icon-alert"></div>
                {this.state.taskDetailId ? (
                  <h2>Edit Task</h2>
                ) : (
                  <h2>Create Task</h2>
                )}
              </div>
            </div>

            {this.getTaskPanel()}

            <div className="horizontal-layout">
              {this.getAudiencePanel()}
              {this.getReminderPanel()}
            </div>

            <div className="button-container">
              <button className="action">Save</button>
              {this.state.taskDetailId && (
                <button
                  type="button"
                  className="action negative"
                  onClick={() => this.handleDeleteModalShow()}>
                  Delete
                </button>
              )}
            </div>
          </form>
          {showDeleteModal && (
            <DeleteModal
              cancel={this.handleDeleteModalHide}
              continue={this.handleDelete}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isLoading: state.connectTask.isFetching,
    isLoaded: state.connectTask.isLoaded,
    taskDetails: state.connectTask.currentAdminAlert,
    // mbuUsers: state.connectTask.mbuUsers,
    isSaving: state.connectTask.isSaving,
    isSaved: state.connectTask.isSaved,
    isdeleting: state.connectTask.isDeleted,
    isdeleted: state.connectTask.isDeleted,
    tenantKey: state.auth.tenant,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getTask: taskDetailId =>
      dispatch(connectTaskActions.requestEditableTask(taskDetailId)),
    saveTask: notificationData =>
      dispatch(connectTaskActions.saveTask(notificationData)),
    deleteTask: taskDetailId =>
      dispatch(connectTaskActions.deleteTask(taskDetailId)),

    push: path => dispatch(navigationActions.pushNavigation(path)),
    replace: path => dispatch(navigationActions.replaceNavigation(path)),
    reset: () => dispatch(navigationActions.resetNavigation()),
    goBack: () => dispatch(navigationActions.backNavigation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskEdit);
